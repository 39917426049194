import { Component, OnInit, Inject, HostListener, ElementRef } from '@angular/core';
import { MINISITE_CONFIGURATION, MinisiteConfiguration } from 'src/app/minisite.configuration';
import { environment } from '@environment';
import { DVMService } from '../dvm.service';
declare var $:any;

@Component({
  selector: 'app-header-dvm',
  templateUrl: './header-dvm.component.html',
  styleUrls: ['./header-dvm.component.scss']
})
export class HeaderDvmComponent implements OnInit {
    constructor(private eRef: ElementRef, private dvm: DVMService, @Inject(MINISITE_CONFIGURATION) private minisite_config: MinisiteConfiguration) { 
    }

    venueName = this.minisite_config.venueName;
    subtitleName = this.minisite_config.subtitleName ? this.minisite_config.subtitleName : "3D seatmap";
    imgUrl = this.minisite_config.minisiteUrl;
    ticketsUrl = this.minisite_config.ticketsUrl;
    logoHasUrl = this.minisite_config.logoUrl;
    buyTickets = this.minisite_config.buyTickets;
    ticketsName = this.minisite_config.ticketsName;
    bgButton = this.minisite_config.bgButton;
    buttonLab = this.minisite_config.buttonLab;
    txtHeader = this.minisite_config.txtHeader;
    logoUrl:any;
    logo = this.minisite_config.logo;
    searchbar = this.minisite_config.searchbar;
    count = 0;
    rightPosition;
    results = [];
    inputValue;
    sectionGroup = false;

    isInfoMenu = this.minisite_config.infoMenuCheck;
    infoMenuTitle = this.minisite_config.infoMenuTitle;
    infoMenuOptions = this.minisite_config.infoMenuOptions;
    translation = this.minisite_config.translation;
    auxMenuOptions;
    is360 = this.dvm.interface.params.v360;

    menuToggle(event) {
        this.dvm.menuToggle(event);
    }

    getMenuOptions(){
        this.auxMenuOptions = this.dvm.getMenuOptions();

        if(this.minisite_config.tabs) {
            for(var i in this.minisite_config.infoMenuOptions) {
                if(this.minisite_config.infoMenuOptions[i].venueid === this.dvm.venue_id && this.minisite_config.infoMenuOptions[i].mapid === this.dvm.map_id) {
                    this.infoMenuOptions = this.auxMenuOptions.list;
                    this.infoMenuTitle = this.auxMenuOptions.title;
                }
            }
        } else {
            this.infoMenuOptions = this.auxMenuOptions;
        }
    }

    get menuoptions(){
        var menu = [];

        for(var i in this.infoMenuOptions){
            menu.push(this.infoMenuOptions[i])
        }

        return menu;
    }

    openInfoMenu(event){
        var element = event.currentTarget;
        let action = $(element).attr("data-action");
        if(action === 'open'){
            this.menuOpen();
        }else{
            this.menuClose();
        }
    }

    menuOpen(){
        $("#menu-info").attr("data-status","opened");
        $("#menu-info-btn").attr("data-action","close");
        $("#menu-info-btn-mobile").attr("data-action","close");
        $("#icon-info-btn").removeClass("icon-info");
        $("#icon-info-btn").addClass("icon-close");
    }

    menuClose(){
        $("#menu-info").attr("data-status","closed");
        $("#menu-info-btn").attr("data-action","open");
        $("#menu-info-btn-mobile").attr("data-action","open");
        $("#icon-info-btn").addClass("icon-info");
        $("#icon-info-btn").removeClass("icon-close");
    }

    onInput(value: string){
        for(var i = 0; i < this.dvm.viewer.getNodesByType("section").length; i++) {
            if(this.dvm.viewer.getNodesByType("section")[i].id.indexOf("G_") !== -1) {
                this.sectionGroup = true;
                break;
            } else if (this.dvm.viewer.getNodesByType("section")[i].id.indexOf("S_") !== -1) {
                this.sectionGroup = false;
                break;
            }else if (this.dvm.viewer.getNodesByType("section")[i].id.indexOf("V_") !== -1) {
                this.sectionGroup = false;
                break;
            } else if (this.dvm.viewer.getNodesByType("areaprice")[i].id.indexOf("G_") !== -1){
                this.sectionGroup = true;
                break;
            }else if (this.dvm.viewer.getNodesByType("areaprice")[i].id.indexOf("S_") !== -1) {
                this.sectionGroup = false;
                break;
            }else if (this.dvm.viewer.getNodesByType("areaprice")[i].id.indexOf("V_") !== -1) {
                this.sectionGroup = false;
                break;
            }
        }
        
        this.inputValue = this.getResults(value);
    }

    getResults(searchVal) {
        this.results = [];
        var allBlocks = this.dvm.allBlocks;
        var aux; 
        var section = [];
        var blockType;

        if(this.dvm.viewer.getNodesByType("area").length > 0){
            blockType = "area";
        } else if(this.dvm.viewer.getNodesByType("section").length > 0){
            blockType = "section";
        } else if(this.dvm.viewer.getNodesByType("areaprice").length > 0) {
            blockType = "areaprice";
        } 

        if (searchVal.toLowerCase() !== "") {
            if(this.dvm.viewer.getNodesByGroups(blockType,"searched").length > 0) {
                for(var x = 0; x < this.dvm.viewer.getNodesByGroups(blockType,"searched").length; x++) {
                    if (this.dvm.viewer.getNodesByGroups(blockType,"searched")[x].id.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1) {
                        if(this.minisite_config.unavailable && this.minisite_config.unavailableOptionsJson[this.dvm.viewer.getMapId()]['sections'].indexOf(this.dvm.viewer.getNodesByGroups(blockType,"searched")[x].id) === -1) {
                            section.push(this.dvm.viewer.getNodesByGroups(blockType,"searched")[x].id);
                            this.results.push(this.dvm.viewer.getNodesByGroups(blockType,"searched")[x].id);
                        } else if(!this.minisite_config.unavailable){
                            section.push(this.dvm.viewer.getNodesByGroups(blockType,"searched")[x].id);
                            this.results.push(this.dvm.viewer.getNodesByGroups(blockType,"searched")[x].id);
                        }
                    }
                }
            } else {
                for(var i = 0; i < allBlocks.length; i++) {    
                    if(this.minisite_config.translation) {
                        if(this.minisite_config.translationOptionsJson[allBlocks[i].id]) {
                            if (this.minisite_config.translationOptionsJson[allBlocks[i].id].toLowerCase().indexOf(searchVal.toLowerCase()) !== -1) {
                                if(this.minisite_config.unavailable && this.minisite_config.unavailableOptionsJson[this.dvm.viewer.getMapId()]['sections'].indexOf(allBlocks[i].id) === -1) {
                                    section.push(this.minisite_config.translationOptionsJson[allBlocks[i].id]);
                                    this.results.push(this.minisite_config.translationOptionsJson[allBlocks[i].id]);
                                } else if(!this.minisite_config.unavailable){
                                    section.push(this.minisite_config.translationOptionsJson[allBlocks[i].id]);
                                    this.results.push(this.minisite_config.translationOptionsJson[allBlocks[i].id]);
                                }
                            }
                        } else {
                            if (allBlocks[i].id.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1) {
                                if(this.minisite_config.unavailable && this.minisite_config.unavailableOptionsJson[this.dvm.viewer.getMapId()]['sections'].indexOf(allBlocks[i].id) === -1) {
                                    section.push(allBlocks[i].id);
                                    this.results.push(allBlocks[i].id);
                                } else if(!this.minisite_config.unavailable){
                                    section.push(allBlocks[i].id);
                                    this.results.push(allBlocks[i].id);
                                }
                            }
                        }
                    } else {
                        if (allBlocks[i].id.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1) {
                            if(this.minisite_config.unavailable && this.minisite_config.unavailableOptionsJson[this.dvm.viewer.getMapId()]['sections'].indexOf(allBlocks[i].id) === -1) {
                                section.push(allBlocks[i].id);
                                this.results.push(allBlocks[i].id);
                            } else if(!this.minisite_config.unavailable){
                                section.push(allBlocks[i].id);
                                this.results.push(allBlocks[i].id);
                            }
                        }
                    }
                }
            }
            
            aux = this.results;
            aux = JSON.parse(JSON.stringify(this.results));
            
            if(this.count === 0){
                this.dvm.filterSectionOnBlockmap(section);
                this.results = [];
            }

        } else {
            this.results = [];
            this.dvm.removeFilteringSectionOnBlockmap();
        }

        this.results = aux;
        if(this.results){
            this.results.sort();
        }

        if(this.minisite_config.translation){
            Object.entries(this.minisite_config.translationOptionsJson).forEach(element => {
                if(this.results){
                    if(this.results.indexOf(element[0]) !== -1){
                        const index = this.results.indexOf(element[0]);
                        if (index > -1) {
                            this.results.splice(index, 1);
                        }
                        this.results.push(element[1])
                    }
                }
            });
        }
        
        return this.results;
    }
    
    locateSeatmap(id){
		this.resetSearch();
		$(".contact-btn").addClass("hidden");
		$(".interior").addClass("hidden");
		$(".interior-mobile").addClass("hidden");

		this.dvm.locateSeatmap(id);
	}

	onClick(element) {
        if(this.minisite_config.translation) {
            for(var i in this.minisite_config.translationOptionsJson) {
                if(element === this.minisite_config.translationOptionsJson[i]) {
                    element = i
                } else {
                    element = element
                }
            }
        } else {
            element = element;
        }

		this.locateSeatmap(element);
	}

	mouseEnter(element) {
        this.count = 1;

        if(this.minisite_config.translation) {
            for(var i in this.minisite_config.translationOptionsJson) {
                if(element === this.minisite_config.translationOptionsJson[i]) {
                    element = i;
                } else {
                    element = element;
                }
            }
        } 

        this.dvm.filterSectionOnBlockmap([element]);
	}

	mouseOut() {
        this.count = 0;
        this.dvm.filterSectionOnBlockmap(JSON.parse(JSON.stringify(this.results)));
	}

	resetSearch(){
        this.count = 0;
        this.results = [];
		$('.form-group input').blur();
		$('.form-group input').val('');
    }

    ngOnInit() {
        if(this.logo){
			if(this.minisite_config.logoType === "png") {
				this.logoUrl = `https://3ddigitalvenue.com/3dmap/${environment.url}/${this.imgUrl}/logo.png`;
			} else if (this.minisite_config.logoType === "svg") {
				this.logoUrl = `https://3ddigitalvenue.com/3dmap/${environment.url}/${this.imgUrl}/logo.svg`;
			} else {
				this.logoUrl = `https://3ddigitalvenue.com/3dmap/${environment.url}/${this.imgUrl}/logo.png`;
			}
        } else {
            $(".title-box").css("margin-left", "20px")
        }

        document.documentElement.style.setProperty('--text-header', this.minisite_config.txtHeader);

        this.getMenuOptions();
    }

}
