import { Ng2StateDeclaration } from '@uirouter/angular';
import { AppComponent } from '../app.component';
import { MinisiteErrorComponent } from '../minisite-error/minisite-error.component';

export const states: Ng2StateDeclaration[] = [
  {
    name: 'common',
    abstract: true,
    component: AppComponent
  },
  {
    name: 'common.error',
    url: '/error',
    component: MinisiteErrorComponent
  }
];
