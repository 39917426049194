import {Injectable, Inject} from '@angular/core';
import {Handler, GeneralState, InteractionMessageProtocol} from 'ngx-tk3d';
import * as global from '../services/global-functions';
import { MinisiteConfiguration, MINISITE_CONFIGURATION } from '../minisite.configuration';
declare var $:any;


@Injectable()
export class PopoverCreate extends Handler {
    contentPopover;
    confPopover;
    target;
    imgview = null;

    constructor(@Inject(MINISITE_CONFIGURATION) private minisite_config: MinisiteConfiguration) {
        super();
        this.handling['blockmap'] = ['mouseenter'];
        this.handling['seatmap'] = ['mouseenter'];
    }

    handle(message: InteractionMessageProtocol, status: GeneralState) {
        if(!global.isMobile()){
            if(message.emmitterSize !== "mini" && message.element.status.includes("available")){
                var id = message.element.id;
                var seating = id.split("-");
    
                if (message.emitter === 'blockmap') {
                    if(this.minisite_config.popupJsonCheck) {
                        if(this.minisite_config.popupImg === "img") {
                            status.blockmap.moduleRef.getThumbnail(id).then(function(img){
                                $(".ticket-selected-view-img").html("");
                                $(".ticket-selected-view-img").css("background-image", "url('"+img.src+"')");
                            });
            
                            var section = seating[0].split("_")[1];
                            this.contentPopover = this.popoverGetContentBlockImgJson(section);
                        } else {
                            var section = seating[0].split("_")[1];
                            this.contentPopover = this.popoverGetContentBlockJson(section);
                        }
                    } else {
                        if(this.minisite_config.popupImg === "img") {
                            status.blockmap.moduleRef.getThumbnail(id).then(function(img){
                                $(".ticket-selected-view-img").html("");
                                $(".ticket-selected-view-img").css("background-image", "url('"+img.src+"')");
                            });
            
                            var section = seating[0].split("_")[1];
                            this.contentPopover = this.getContentBlockImg(section);
                        } else {
                            var section = seating[0].split("_")[1];
                            this.contentPopover = this.getContentBlock(section);
                        }
                    }
                    
                } else {
                    if(this.minisite_config.popupJsonCheck) {
                        if(this.minisite_config.popupImg === "img") {
                            var section = seating[0].split("_")[1];
                            var row = seating[1];
                            var seat = seating[2];
            
                            status.seatmap.moduleRef.getThumbnail(id).then(function(img){
                                $(".ticket-selected-view-img").html("");
                                $(".ticket-selected-view-img").css("background-image", "url('"+img.src+"')");
                            });
            
                            this.contentPopover = this.popoverGetContentSeatImgJson(section, row, seat);
                        } else {
                            var section = seating[0].split("_")[1];
                            var row = seating[1];
                            var seat = seating[2];

                            this.contentPopover = this.popoverGetContentSeatJson(section, row, seat);
                        }
                    } else {
                        if(this.minisite_config.popupImg === "img") {
                            var section = seating[0].split("_")[1];
                            var row = seating[1];
                            var seat = seating[2];
            
                            status.seatmap.moduleRef.getThumbnail(id).then(function(img){
                                $(".ticket-selected-view-img").html("");
                                $(".ticket-selected-view-img").css("background-image", "url('"+img.src+"')");
                            });
            
                            this.contentPopover = this.getContentSeatImg(section, row, seat);
                        } else {
                            var section = seating[0].split("_")[1];
                            var row = seating[1];
                            var seat = seating[2];
                            
                            this.contentPopover = this.getContentSeat(section, row, seat);
                        }
                        
                    }
                }
                
                this.confPopover = {
                    animation: true,
                    container: 'body',
                    trigger: 'manual',
                    html: true,
                    content: this.contentPopover,
                    placement: 'auto',
                    template: '<div class="popover tk3dpopover bg-header" role="tooltip" style="pointer-events: none;">' +
                    '<h3 class="popover-header"></h3>' +
                    '<div class="popover-body"></div>' +
                    '</div>'
                }

                $(message.element.HTMLElement).popover(this.confPopover);
                let that = this;
                new Promise(function(success){
                    $(message.element.HTMLElement).popover('show');
                    success(that.minisite_config);
                }).then(function(minisite_config){
                    $(".popover-ticket-lab").css("color", minisite_config['popoverLab']);
                    $(".popover-ticket-val").css("color", minisite_config['popoverVal']);
                    $(".popover.bg-header").css("background-color", minisite_config['bgPopover']);
                });
            }
        }
    }

    /* SIN JSON */

    public getContentBlock(section) {
        if(this.minisite_config.translation) {
            if(this.minisite_config.translationOptionsJson["S_"+section]) {
                section = this.minisite_config.translationOptionsJson["S_"+section]
            } else {
                section = section;
            }
        }

        var content = 
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li>"+
                                "<span class='popover-ticket-lab'>Section </span> "+
                                "<span class='popover-ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>";
        return content;
    }

    public getContentBlockImg(section) {
        if(this.minisite_config.translation) {
            if(this.minisite_config.translationOptionsJson["S_"+section]) {
                section = this.minisite_config.translationOptionsJson["S_"+section]
            } else {
                section = section;
            }
        }

        var content =   "<div class='ticket-selected-view'>" +
                            "<div class='ticket-selected-view-img'></div>"+
                        "</div>" +
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li>"+
                                "<span class='popover-ticket-lab'>Section </span> "+
                                "<span class='popover-ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>";
        return content;
    }

    public getContentSeat(section, row, seat) {
        if(this.minisite_config.translation) {
            if(this.minisite_config.translationOptionsJson["S_"+section]) {
                section = this.minisite_config.translationOptionsJson["S_"+section]
            } else {
                section = section;
            }
        }

        var content = 
                    "<ul class='list-unstyled ticket-selected'>"+
                        "<li>"+
                            "<span class='popover-ticket-lab'>Section </span> "+
                            "<span class='popover-ticket-val'>"+section+"</span>"+
                        "</li>"+
                        "<li>"+
                            "<span class='popover-ticket-lab'>Row </span> "+
                            "<span class='popover-ticket-val'>"+row+"</span>"+
                        "</li>"+
                        "<li>"+
                            "<span class='popover-ticket-lab'>Seat </span> "+
                            "<span class='popover-ticket-val'>"+seat+"</span>"+
                        "</li>"+
                    "</ul>";
        return content;
    }

    public getContentSeatImg(section, row, seat) {
        if(this.minisite_config.translation) {
            if(this.minisite_config.translationOptionsJson["S_"+section]) {
                section = this.minisite_config.translationOptionsJson["S_"+section]
            } else {
                section = section;
            }
        }

        var content = 
                    "<div class='ticket-selected-view'>" +
                        "<div class='ticket-selected-view-img'></div>"+
                    "</div>" +
                    "<ul class='list-unstyled ticket-selected'>"+
                        "<div>"+
                            "<li>"+
                                "<span class='popover-ticket-lab'>Section </span> "+
                                "<span class='popover-ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</div>"+
                        "<div>"+
                            "<li>"+
                                "<span class='popover-ticket-lab'>Row </span> "+
                                "<span class='popover-ticket-val'>"+row+"</span>"+
                            "</li>"+
                            "<li>"+
                                "<span class='popover-ticket-lab'>Seat </span> "+
                                "<span class='popover-ticket-val'>"+seat+"</span>"+
                            "</li>"+
                        "</div>"+
                    "</ul>";
        return content;
    }

    /* CON JSON */

    //blocks

    public popoverGetContentBlockJson(section){
        var textBody = "";
        var textFooter = "";
        var price = "";
        var content;

        if(this.minisite_config.popupJson.sections["S_"+section]) {
            textBody = this.minisite_config.popupJson.sections["S_"+section].textBody;
            textFooter = this.minisite_config.popupJson.sections["S_"+section].textFooter;
            price = this.minisite_config.popupJson.sections["S_"+section].price;
        } else {
            textBody = "";
            textFooter = "";
            price = "";
        }

        if(this.minisite_config.translation) {
            if(this.minisite_config.translationOptionsJson["S_"+section]) {
                section = this.minisite_config.translationOptionsJson["S_"+section]
            } else {
                section = section;
            }
        }

        if(textBody && (!textFooter && !price)) {
            content = "<div class='regularJson'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<div class='popup-text'>"+
                            textBody+
                        "</div>"+
                    "</div>";
        } else if(!textBody && (textFooter || price)) {
            content = "<div class='regularJson'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<div class='popup-price'>"+
                            "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                        "</div>"+
                    "</div>";
        } else if(!textBody && (!textFooter || !price)){
            content = "<div class='regularJson'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>"+
                    "</div>";
        } else {
            content = "<div class='regularJson'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<div class='popup-text'>"+
                            textBody+
                        "</div>"+
                        "<div class='popup-price'>"+
                            "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                        "</div>"+
                    "</div>";
        }
        
        return content;
    }

    public popoverGetContentBlockImgJson(section){
        var textBody = "";
        var textFooter = "";
        var price = "";
        var content;

        if(this.minisite_config.popupJson.sections["S_"+section]) {
            textBody = this.minisite_config.popupJson.sections["S_"+section].textBody;
            textFooter = this.minisite_config.popupJson.sections["S_"+section].textFooter;
            price = this.minisite_config.popupJson.sections["S_"+section].price;
        } else {
            textBody = "";
            textFooter = "";
            price = "";
        }

        if(this.minisite_config.translation) {
            if(this.minisite_config.translationOptionsJson["S_"+section]) {
                section = this.minisite_config.translationOptionsJson["S_"+section]
            } else {
                section = section;
            }
        }

        if(textBody && (!textFooter && !price)) {
            content = "<div class='regularJson'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<div class='ticket-selected-view'>" +
                            "<div class='ticket-selected-view-img'></div>"+
                        "</div>" +
                        "<div class='popup-text'>"+
                            textBody+
                        "</div>"+
                    "</div>";
        } else if(!textBody && (textFooter || price)) {
            content = "<div class='regularJson'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<div class='ticket-selected-view'>" +
                            "<div class='ticket-selected-view-img'></div>"+
                        "</div>" +
                        "<div class='popup-price'>"+
                            "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                        "</div>"+
                    "</div>";
        } else if(!textBody && (!textFooter || !price)){
            content = "<div class='regularJson'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<div class='ticket-selected-view'>" +
                            "<div class='ticket-selected-view-img'></div>"+
                        "</div>" +
                    "</div>";
        } else {
            content = "<div class='regularJson'>"+
                            "<ul class='list-unstyled ticket-selected'>"+
                                "<li style='border-left: none;'>"+
                                    "<span class='ticket-lab'>Section </span> "+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                            "</ul>"+
                            "<div class='ticket-selected-view'>" +
                                "<div class='ticket-selected-view-img'></div>"+
                            "</div>" +
                            "<div class='popup-text'>"+
                                textBody+
                            "</div>"+
                            "<div class='popup-price'>"+
                                "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                            "</div>"+
                        "</div>";
        }

        return content;
    }

    public popoverGetContentBlockVIPImgJson(section){
        var vipname = "";
        var textBody = "";
        var textFooter = "";
        var price = "";
        var content;

        if(this.minisite_config.popupJson.sections["S_"+section]) {
            vipname = this.minisite_config.popupJson.sections["S_"+section].vipTitle;
            textBody = this.minisite_config.popupJson.sections["S_"+section].textBody;
            textFooter = this.minisite_config.popupJson.sections["S_"+section].textFooter;
            price = this.minisite_config.popupJson.sections["S_"+section].price;
        } else {
            vipname = ""
            textBody = "";
            textFooter = "";
            price = "";
        }

        if(this.minisite_config.translation) {
            if(this.minisite_config.translationOptionsJson["S_"+section]) {
                section = this.minisite_config.translationOptionsJson["S_"+section]
            } else {
                section = section;
            }
        }

        if(vipname != "") {
            if(textBody && (!textFooter && !price)) {
                content = "<div class='vipJson'>"+
                            "<div class='vipname ticket-selected-title gold'>" +
                                "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                            "</div>"+
                            "<ul class='list-unstyled ticket-selected'>"+
                                "<li style='border-left: none;'>"+
                                    "<span class='ticket-lab'>Section </span> "+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                            "</ul>"+
                            "<div class='ticket-selected-view'>" +
                                "<div class='ticket-selected-view-img'></div>"+
                            "</div>" +
                            "<div class='popup-text'>"+
                                textBody+
                            "</div>"+
                        "</div>";
            } else if(!textBody && (textFooter || price)) {
                content = "<div class='vipJson'>"+
                            "<div class='vipname ticket-selected-title gold'>" +
                                "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                            "</div>"+
                            "<ul class='list-unstyled ticket-selected'>"+
                                "<li style='border-left: none;'>"+
                                    "<span class='ticket-lab'>Section </span> "+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                            "</ul>"+
                            "<div class='ticket-selected-view'>" +
                                "<div class='ticket-selected-view-img'></div>"+
                            "</div>" +
                            "<div class='popup-price'>"+
                                "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                            "</div>"+
                        "</div>";
            } else if(!textBody && (!textFooter || !price)){
                content = "<div class='vipJson'>"+
                            "<div class='vipname ticket-selected-title gold'>" +
                                "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                            "</div>"+
                            "<ul class='list-unstyled ticket-selected'>"+
                                "<li style='border-left: none;'>"+
                                    "<span class='ticket-lab'>Section </span> "+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                            "</ul>"+
                            "<div class='ticket-selected-view'>" +
                                "<div class='ticket-selected-view-img'></div>"+
                            "</div>" +
                        "</div>";
            } else {
                content = "<div class='vipJson'>"+
                                "<div class='vipname ticket-selected-title gold'>" +
                                    "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                                "</div>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                    "<li style='border-left: none;'>"+
                                        "<span class='ticket-lab'>Section </span> "+
                                        "<span class='ticket-val'>"+section+"</span>"+
                                    "</li>"+
                                "</ul>"+
                                "<div class='ticket-selected-view'>" +
                                    "<div class='ticket-selected-view-img'></div>"+
                                "</div>" +
                                "<div class='popup-text'>"+
                                    textBody+
                                "</div>"+
                                "<div class='popup-price'>"+
                                    "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                                "</div>"+
                            "</div>";
            }
        } else {
            if(textBody && (!textFooter && !price)) {
                content = "<div class='vipJson'>"+
                                "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                    "<ul class='list-unstyled ticket-selected'>"+
                                        "<li style='border-left: none;'>"+
                                            "<span class='ticket-lab'>Section </span> "+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                    "</ul>"+
                                    "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                                "</div>"+
                                "<div class='ticket-selected-view'>" +
                                    "<div class='ticket-selected-view-img'></div>"+
                                "</div>" +
                                "<div class='popup-text'>"+
                                    textBody+
                                "</div>"+
                            "</div>";
            } else if(!textBody && (textFooter || price)) {
                content = "<div class='vipJson'>"+
                                "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                    "<ul class='list-unstyled ticket-selected'>"+
                                        "<li style='border-left: none;'>"+
                                            "<span class='ticket-lab'>Section </span> "+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                    "</ul>"+
                                    "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                                "</div>"+
                                "<div class='ticket-selected-view'>" +
                                    "<div class='ticket-selected-view-img'></div>"+
                                "</div>" +
                                "<div class='popup-price'>"+
                                    "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                                "</div>"+
                            "</div>";
            } else if(!textBody && (!textFooter || !price)){
                content = "<div class='vipJson'>"+
                                "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                    "<ul class='list-unstyled ticket-selected'>"+
                                        "<li style='border-left: none;'>"+
                                            "<span class='ticket-lab'>Section </span> "+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                    "</ul>"+
                                    "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                                "</div>"+
                                "<div class='ticket-selected-view'>" +
                                    "<div class='ticket-selected-view-img'></div>"+
                                "</div>" +
                            "</div>";            
            } else {
                content = "<div class='vipJson'>"+
                                "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                    "<ul class='list-unstyled ticket-selected'>"+
                                        "<li style='border-left: none;'>"+
                                            "<span class='ticket-lab'>Section </span> "+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                    "</ul>"+
                                    "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                                "</div>"+
                                "<div class='ticket-selected-view'>" +
                                    "<div class='ticket-selected-view-img'></div>"+
                                "</div>" +
                                "<div class='popup-text'>"+
                                    textBody+
                                "</div>"+
                                "<div class='popup-price'>"+
                                    "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                                "</div>"+
                            "</div>";
            }
        }
        
        return content;
    }

    public popoverGetContentBlockVIPJson(section){
        var vipname = "";
        var textBody = "";
        var textFooter = "";
        var price = "";
        var content;

        if(this.minisite_config.popupJson.sections["S_"+section]) {
            vipname = this.minisite_config.popupJson.sections["S_"+section].vipTitle;
            textBody = this.minisite_config.popupJson.sections["S_"+section].textBody;
            textFooter = this.minisite_config.popupJson.sections["S_"+section].textFooter;
            price = this.minisite_config.popupJson.sections["S_"+section].price;
        } else {
            vipname = "";
            textBody = "";
            textFooter = "";
            price = "";
        }

        if(this.minisite_config.translation) {
            if(this.minisite_config.translationOptionsJson["S_"+section]) {
                section = this.minisite_config.translationOptionsJson["S_"+section]
            } else {
                section = section;
            }
        }

        if(vipname !== "") {
            if(textBody && (!textFooter && !price)) {
                content = "<div class='vipJson'>"+
                                "<div class='vipname ticket-selected-title gold'>" +
                                    "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                                "</div>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                    "<li style='border-left: none;'>"+
                                        "<span class='ticket-lab'>Section </span> "+
                                        "<span class='ticket-val'>"+section+"</span>"+
                                    "</li>"+
                                "</ul>"+
                                "<div class='popup-text'>"+
                                    textBody
                                "</div>"+
                            "</div>";
            } else if(!textBody && (textFooter || price)) {
                content = "<div class='vipJson'>"+
                                "<div class='vipname ticket-selected-title gold'>" +
                                    "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                                "</div>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                    "<li style='border-left: none;'>"+
                                        "<span class='ticket-lab'>Section </span> "+
                                        "<span class='ticket-val'>"+section+"</span>"+
                                    "</li>"+
                                "</ul>"+
                                "<div class='popup-price'>"+
                                    "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                                "</div>"+
                            "</div>";            
            } else if(!textBody && (!textFooter || !price)){
                content = "<div class='vipJson'>"+
                                "<div class='vipname ticket-selected-title gold'>" +
                                    "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                                "</div>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                    "<li style='border-left: none;'>"+
                                        "<span class='ticket-lab'>Section </span> "+
                                        "<span class='ticket-val'>"+section+"</span>"+
                                    "</li>"+
                                "</ul>"+
                            "</div>";            
            } else {
                content = "<div class='vipJson'>"+
                                "<div class='vipname ticket-selected-title gold'>" +
                                    "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                                "</div>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                    "<li style='border-left: none;'>"+
                                        "<span class='ticket-lab'>Section </span> "+
                                        "<span class='ticket-val'>"+section+"</span>"+
                                    "</li>"+
                                "</ul>"+
                                "<div class='popup-text'>"+
                                    textBody
                                "</div>"+
                                "<div class='popup-price'>"+
                                    "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                                "</div>"+
                            "</div>";
            }
        } else {
            if(textBody && (!textFooter && !price)) {
                content = "<div class='vipJson'>"+
                                "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                    "<ul class='list-unstyled ticket-selected'>"+
                                        "<li style='border-left: none;'>"+
                                            "<span class='ticket-lab'>Section </span> "+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                    "</ul>"+
                                    "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                                "</div>"+
                                "<div class='popup-text'>"+
                                    textBody
                                "</div>"+
                            "</div>";
            } else if(!textBody && (textFooter || price)) {
                content = "<div class='vipJson'>"+
                                "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                    "<ul class='list-unstyled ticket-selected'>"+
                                        "<li style='border-left: none;'>"+
                                            "<span class='ticket-lab'>Section </span> "+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                    "</ul>"+
                                    "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                                "</div>"+
                                "<div class='popup-price'>"+
                                    "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                                "</div>"+
                            "</div>";            
            } else if(!textBody && (!textFooter || !price)){
                content = "<div class='vipJson'>"+
                                "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                    "<ul class='list-unstyled ticket-selected'>"+
                                        "<li style='border-left: none;'>"+
                                            "<span class='ticket-lab'>Section </span> "+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                    "</ul>"+
                                    "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                                "</div>"+
                            "</div>";            
            } else {
                content = "<div class='vipJson'>"+
                                "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                    "<ul class='list-unstyled ticket-selected'>"+
                                        "<li style='border-left: none;'>"+
                                            "<span class='ticket-lab'>Section </span> "+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                    "</ul>"+
                                    "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                                "</div>"+
                                "<div class='popup-text'>"+
                                    textBody
                                "</div>"+
                                "<div class='popup-price'>"+
                                    "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                                "</div>"+
                            "</div>";
            }
        }

        return content;
    }

    //seats

    public popoverGetContentSeatVIPImgJson(section, row, seat){
        var vipname = "";
        var textBody = "";
        var textFooter = "";
        var price = "";
        var content;

        if(this.minisite_config.popupJson.seats["S_"+section+"-"+row+"-"+seat]) {
            vipname = this.minisite_config.popupJson.seats["S_"+section+"-"+row+"-"+seat].vipTitle;
            textBody = this.minisite_config.popupJson.seats["S_"+section+"-"+row+"-"+seat].textBody;
            textFooter = this.minisite_config.popupJson.seats["S_"+section+"-"+row+"-"+seat].textFooter;
            price = this.minisite_config.popupJson.seats["S_"+section+"-"+row+"-"+seat].price;
        } else if(this.minisite_config.popupJson.rows["S_"+section+"-"+row]) {
            vipname = this.minisite_config.popupJson.rows["S_"+section+"-"+row].vipTitle;
            textBody = this.minisite_config.popupJson.rows["S_"+section+"-"+row].textBody;
            textFooter = this.minisite_config.popupJson.rows["S_"+section+"-"+row].textFooter;
            price = this.minisite_config.popupJson.rows["S_"+section+"-"+row].price;
        } else if(this.minisite_config.popupJson.sections["S_"+section]) {
            vipname = this.minisite_config.popupJson.sections["S_"+section].vipTitle;
            textBody = this.minisite_config.popupJson.sections["S_"+section].textBody;
            textFooter = this.minisite_config.popupJson.sections["S_"+section].textFooter;
            price = this.minisite_config.popupJson.sections["S_"+section].price;
        }else {
            vipname = "";
            textBody = "";
            textFooter = "";
            price = "";
        }

        if(this.minisite_config.translation) {
            if(this.minisite_config.translationOptionsJson["S_"+section]) {
                section = this.minisite_config.translationOptionsJson["S_"+section]
            } else {
                section = section;
            }
        }

        if(vipname !== "") {
            if(textBody && (!textFooter && !price)) {
                content = "<div class='vipJson'>"+
                                "<div class='vipname ticket-selected-title gold'>" +
                                    "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                                "</div>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                    "<li style='border-left: none;'>"+
                                        "<span class='ticket-lab'>Section </span>"+
                                        "<span class='ticket-val'>"+section+"</span>"+
                                    "</li>"+
                                    "<li>"+
                                        "<span class='ticket-lab'>Row </span>"+
                                        "<span class='ticket-val'>"+row+"</span>"+
                                    "</li>"+
                                    "<li>"+
                                        "<span class='ticket-lab'>Seat </span> "+
                                        "<span class='ticket-val'>"+seat+"</span>"+
                                    "</li>"+
                                "</ul>"+
                                "<div class='ticket-selected-view'>" +
                                    "<div class='ticket-selected-view-img'></div>"+
                                "</div>"+
                                "<div class='popup-text'>"+
                                    textBody
                                "</div>"+
                            "</div>";
            } else if(!textBody && (textFooter || price)) {
                content = "<div class='vipJson'>"+
                            "<div class='vipname ticket-selected-title gold'>" +
                                "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                            "</div>"+
                            "<ul class='list-unstyled ticket-selected'>"+
                                "<li style='border-left: none;'>"+
                                    "<span class='ticket-lab'>Section </span>"+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                                "<li>"+
                                    "<span class='ticket-lab'>Row </span>"+
                                    "<span class='ticket-val'>"+row+"</span>"+
                                "</li>"+
                                "<li>"+
                                    "<span class='ticket-lab'>Seat </span> "+
                                    "<span class='ticket-val'>"+seat+"</span>"+
                                "</li>"+
                            "</ul>"+
                            "<div class='ticket-selected-view'>" +
                                "<div class='ticket-selected-view-img'></div>"+
                            "</div>"+
                            "<div class='popup-price'>"+
                                "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                            "</div>"+
                        "</div>";
            } else if(!textBody && (!textFooter || !price)){
                content = "<div class='vipJson'>"+
                                "<div class='vipname ticket-selected-title gold'>" +
                                    "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                                "</div>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                    "<li style='border-left: none;'>"+
                                        "<span class='ticket-lab'>Section </span>"+
                                        "<span class='ticket-val'>"+section+"</span>"+
                                    "</li>"+
                                    "<li>"+
                                        "<span class='ticket-lab'>Row </span>"+
                                        "<span class='ticket-val'>"+row+"</span>"+
                                    "</li>"+
                                    "<li>"+
                                        "<span class='ticket-lab'>Seat </span> "+
                                        "<span class='ticket-val'>"+seat+"</span>"+
                                    "</li>"+
                                "</ul>"+
                                "<div class='ticket-selected-view'>" +
                                    "<div class='ticket-selected-view-img'></div>"+
                                "</div>"+
                              "</div>";            
            } else {
                content = "<div class='vipJson'>"+
                                "<div class='vipname ticket-selected-title gold'>" +
                                    "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                                "</div>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                    "<li style='border-left: none;'>"+
                                        "<span class='ticket-lab'>Section </span>"+
                                        "<span class='ticket-val'>"+section+"</span>"+
                                    "</li>"+
                                    "<li>"+
                                        "<span class='ticket-lab'>Row </span>"+
                                        "<span class='ticket-val'>"+row+"</span>"+
                                    "</li>"+
                                    "<li>"+
                                        "<span class='ticket-lab'>Seat </span> "+
                                        "<span class='ticket-val'>"+seat+"</span>"+
                                    "</li>"+
                                "</ul>"+
                                "<div class='ticket-selected-view'>" +
                                    "<div class='ticket-selected-view-img'></div>"+
                                "</div>"+
                                "<div class='popup-text'>"+
                                    textBody
                                "</div>"+
                                "<div class='popup-price'>"+
                                    "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                                "</div>"+
                              "</div>";
            }
        } else {
            if(textBody && (!textFooter && !price)) {
                content = "<div class='vipJson'>"+
                                "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                    "<ul class='list-unstyled ticket-selected'>"+
                                        "<li style='border-left: none;'>"+
                                            "<span class='ticket-lab'>Section </span>"+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                        "<li>"+
                                            "<span class='ticket-lab'>Row </span>"+
                                            "<span class='ticket-val'>"+row+"</span>"+
                                        "</li>"+
                                        "<li>"+
                                            "<span class='ticket-lab'>Seat </span> "+
                                            "<span class='ticket-val'>"+seat+"</span>"+
                                        "</li>"+
                                    "</ul>"+
                                    "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                                "</div>"+
                                "<div class='ticket-selected-view'>" +
                                    "<div class='ticket-selected-view-img'></div>"+
                                "</div>"+
                                "<div class='popup-text'>"+
                                    textBody
                                "</div>"+
                            "</div>";
            } else if(!textBody && (textFooter || price)) {
                content = "<div class='vipJson'>"+
                    "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span>"+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                            "<li>"+
                                "<span class='ticket-lab'>Row </span>"+
                                "<span class='ticket-val'>"+row+"</span>"+
                            "</li>"+
                            "<li>"+
                                "<span class='ticket-lab'>Seat </span> "+
                                "<span class='ticket-val'>"+seat+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                    "</div>"+
                    "<div class='ticket-selected-view'>" +
                        "<div class='ticket-selected-view-img'></div>"+
                    "</div>"+
                    "<div class='popup-price'>"+
                        "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                    "</div>"+
                "</div>";
            } else if(!textBody && (!textFooter || !price)){
                content = "<div class='vipJson'>"+
                    "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span>"+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                            "<li>"+
                                "<span class='ticket-lab'>Row </span>"+
                                "<span class='ticket-val'>"+row+"</span>"+
                            "</li>"+
                            "<li>"+
                                "<span class='ticket-lab'>Seat </span> "+
                                "<span class='ticket-val'>"+seat+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                    "</div>"+
                    "<div class='ticket-selected-view'>" +
                        "<div class='ticket-selected-view-img'></div>"+
                    "</div>"+
                "</div>";
            } else {
                content = "<div class='vipJson'>"+
                                "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                    "<ul class='list-unstyled ticket-selected'>"+
                                        "<li style='border-left: none;'>"+
                                            "<span class='ticket-lab'>Section </span>"+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                        "<li>"+
                                            "<span class='ticket-lab'>Row </span>"+
                                            "<span class='ticket-val'>"+row+"</span>"+
                                        "</li>"+
                                        "<li>"+
                                            "<span class='ticket-lab'>Seat </span> "+
                                            "<span class='ticket-val'>"+seat+"</span>"+
                                        "</li>"+
                                    "</ul>"+
                                    "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                                "</div>"+
                                "<div class='ticket-selected-view'>" +
                                    "<div class='ticket-selected-view-img'></div>"+
                                "</div>"+
                                "<div class='popup-text'>"+
                                    textBody
                                "</div>"+
                                "<div class='popup-price'>"+
                                    "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                                "</div>"+
                            "</div>";
            }
        }

        return content;
    }

    public popoverGetContentSeatVIPJson(section, row, seat){
        var vipname = "";
        var textBody = "";
        var textFooter = "";
        var price = "";
        var content;

        if(this.minisite_config.popupJson.seats["S_"+section+"-"+row+"-"+seat]) {
            vipname = this.minisite_config.popupJson.seats["S_"+section+"-"+row+"-"+seat].vipTitle;
            textBody = this.minisite_config.popupJson.seats["S_"+section+"-"+row+"-"+seat].textBody;
            textFooter = this.minisite_config.popupJson.seats["S_"+section+"-"+row+"-"+seat].textFooter;
            price = this.minisite_config.popupJson.seats["S_"+section+"-"+row+"-"+seat].price;
        } else if(this.minisite_config.popupJson.rows["S_"+section+"-"+row]) {
            vipname = this.minisite_config.popupJson.rows["S_"+section+"-"+row].vipTitle;
            textBody = this.minisite_config.popupJson.rows["S_"+section+"-"+row].textBody;
            textFooter = this.minisite_config.popupJson.rows["S_"+section+"-"+row].textFooter;
            price = this.minisite_config.popupJson.rows["S_"+section+"-"+row].price;
        } else if(this.minisite_config.popupJson.sections["S_"+section]) {
            vipname = this.minisite_config.popupJson.sections["S_"+section].vipTitle;
            textBody = this.minisite_config.popupJson.sections["S_"+section].textBody;
            textFooter = this.minisite_config.popupJson.sections["S_"+section].textFooter;
            price = this.minisite_config.popupJson.sections["S_"+section].price;
        }else {
            vipname = "";
            textBody = "";
            textFooter = "";
            price = "";
        }

        if(this.minisite_config.translation) {
            if(this.minisite_config.translationOptionsJson["S_"+section]) {
                section = this.minisite_config.translationOptionsJson["S_"+section]
            } else {
                section = section;
            }
        }

        if(vipname !== "") {
            if(textBody && (!textFooter && !price)) {
                content = "<div class='vipJson'>"+
                                "<div class='vipname ticket-selected-title gold'>" +
                                    "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                                "</div>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                    "<li style='border-left: none;'>"+
                                        "<span class='ticket-lab'>Section </span>"+
                                        "<span class='ticket-val'>"+section+"</span>"+
                                    "</li>"+
                                    "<li>"+
                                        "<span class='ticket-lab'>Row </span>"+
                                        "<span class='ticket-val'>"+row+"</span>"+
                                    "</li>"+
                                    "<li>"+
                                        "<span class='ticket-lab'>Seat </span> "+
                                        "<span class='ticket-val'>"+seat+"</span>"+
                                    "</li>"+
                                "</ul>"+
                                "<div class='popup-text'>"+
                                    textBody
                                "</div>"+
                            "</div>";
            } else if(!textBody && (textFooter || price)) {
                content = "<div class='vipJson'>"+
                "<div class='vipname ticket-selected-title gold'>" +
                    "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                "</div>"+
                "<ul class='list-unstyled ticket-selected'>"+
                    "<li style='border-left: none;'>"+
                        "<span class='ticket-lab'>Section </span>"+
                        "<span class='ticket-val'>"+section+"</span>"+
                    "</li>"+
                    "<li>"+
                        "<span class='ticket-lab'>Row </span>"+
                        "<span class='ticket-val'>"+row+"</span>"+
                    "</li>"+
                    "<li>"+
                        "<span class='ticket-lab'>Seat </span> "+
                        "<span class='ticket-val'>"+seat+"</span>"+
                    "</li>"+
                "</ul>"+
                "<div class='popup-price'>"+
                    "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                "</div>"+
            "</div>";
            } else if(!textBody && (!textFooter || !price)){
                content = "<div class='vipJson'>"+
                "<div class='vipname ticket-selected-title gold'>" +
                    "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                "</div>"+
                "<ul class='list-unstyled ticket-selected'>"+
                    "<li style='border-left: none;'>"+
                        "<span class='ticket-lab'>Section </span>"+
                        "<span class='ticket-val'>"+section+"</span>"+
                    "</li>"+
                    "<li>"+
                        "<span class='ticket-lab'>Row </span>"+
                        "<span class='ticket-val'>"+row+"</span>"+
                    "</li>"+
                    "<li>"+
                        "<span class='ticket-lab'>Seat </span> "+
                        "<span class='ticket-val'>"+seat+"</span>"+
                    "</li>"+
                "</ul>"+
            "</div>";
            } else {
                content = "<div class='vipJson'>"+
                                "<div class='vipname ticket-selected-title gold'>" +
                                    "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                                "</div>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                    "<li style='border-left: none;'>"+
                                        "<span class='ticket-lab'>Section </span>"+
                                        "<span class='ticket-val'>"+section+"</span>"+
                                    "</li>"+
                                    "<li>"+
                                        "<span class='ticket-lab'>Row </span>"+
                                        "<span class='ticket-val'>"+row+"</span>"+
                                    "</li>"+
                                    "<li>"+
                                        "<span class='ticket-lab'>Seat </span> "+
                                        "<span class='ticket-val'>"+seat+"</span>"+
                                    "</li>"+
                                "</ul>"+
                                "<div class='popup-text'>"+
                                    textBody
                                "</div>"+
                                "<div class='popup-price'>"+
                                    "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                                "</div>"+
                            "</div>";
            }
        } else {
            if(textBody && (!textFooter && !price)) {
                content = "<div class='vipJson'>"+
                                "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                    "<ul class='list-unstyled ticket-selected'>"+
                                        "<li style='border-left: none;'>"+
                                            "<span class='ticket-lab'>Section </span>"+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                        "<li>"+
                                            "<span class='ticket-lab'>Row </span>"+
                                            "<span class='ticket-val'>"+row+"</span>"+
                                        "</li>"+
                                        "<li>"+
                                            "<span class='ticket-lab'>Seat </span> "+
                                            "<span class='ticket-val'>"+seat+"</span>"+
                                        "</li>"+
                                    "</ul>"+
                                    "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                                "</div>"+
                                "<div class='popup-text'>"+
                                    textBody
                                "</div>"+
                            "</div>";
            } else if(!textBody && (textFooter || price)) {
                content = "<div class='vipJson'>"+
                            "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                    "<li style='border-left: none;'>"+
                                        "<span class='ticket-lab'>Section </span>"+
                                        "<span class='ticket-val'>"+section+"</span>"+
                                    "</li>"+
                                    "<li>"+
                                        "<span class='ticket-lab'>Row </span>"+
                                        "<span class='ticket-val'>"+row+"</span>"+
                                    "</li>"+
                                    "<li>"+
                                        "<span class='ticket-lab'>Seat </span> "+
                                        "<span class='ticket-val'>"+seat+"</span>"+
                                    "</li>"+
                                "</ul>"+
                                "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                            "</div>"+
                            "<div class='popup-price'>"+
                                "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                            "</div>"+
                        "</div>";
            } else if(!textBody && (!textFooter || !price)){
                content = "<div class='vipJson'>"+
                            "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                    "<li style='border-left: none;'>"+
                                        "<span class='ticket-lab'>Section </span>"+
                                        "<span class='ticket-val'>"+section+"</span>"+
                                    "</li>"+
                                    "<li>"+
                                        "<span class='ticket-lab'>Row </span>"+
                                        "<span class='ticket-val'>"+row+"</span>"+
                                    "</li>"+
                                    "<li>"+
                                        "<span class='ticket-lab'>Seat </span> "+
                                        "<span class='ticket-val'>"+seat+"</span>"+
                                    "</li>"+
                                "</ul>"+
                                "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                            "</div>"+
                        "</div>";      
            } else {
                content = "<div class='vipJson'>"+
                                "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                    "<ul class='list-unstyled ticket-selected'>"+
                                        "<li style='border-left: none;'>"+
                                            "<span class='ticket-lab'>Section </span>"+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                        "<li>"+
                                            "<span class='ticket-lab'>Row </span>"+
                                            "<span class='ticket-val'>"+row+"</span>"+
                                        "</li>"+
                                        "<li>"+
                                            "<span class='ticket-lab'>Seat </span> "+
                                            "<span class='ticket-val'>"+seat+"</span>"+
                                        "</li>"+
                                    "</ul>"+
                                    "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                                "</div>"+
                                "<div class='popup-text'>"+
                                    textBody
                                "</div>"+
                                "<div class='popup-price'>"+
                                    "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                                "</div>"+
                            "</div>";
                
            }
        }

        return content;
    }

    public popoverGetContentSeatImgJson(section, row, seat){
        var textBody = "";
        var textFooter = "";
        var price = "";
        var content;

        if(this.minisite_config.popupJson.seats["S_"+section+"-"+row+"-"+seat]) {
            textBody = this.minisite_config.popupJson.seats["S_"+section+"-"+row+"-"+seat].textBody;
            textFooter = this.minisite_config.popupJson.seats["S_"+section+"-"+row+"-"+seat].textFooter;
            price = this.minisite_config.popupJson.seats["S_"+section+"-"+row+"-"+seat].price;
        } else if(this.minisite_config.popupJson.rows["S_"+section+"-"+row]) {
            textBody = this.minisite_config.popupJson.rows["S_"+section+"-"+row].textBody;
            textFooter = this.minisite_config.popupJson.rows["S_"+section+"-"+row].textFooter;
            price = this.minisite_config.popupJson.rows["S_"+section+"-"+row].price;
        } else if(this.minisite_config.popupJson.sections["S_"+section]) {
            textBody = this.minisite_config.popupJson.sections["S_"+section].textBody;
            textFooter = this.minisite_config.popupJson.sections["S_"+section].textFooter;
            price = this.minisite_config.popupJson.sections["S_"+section].price;
        }else {
            textBody = "";
            textFooter = "";
            price = "";
        }

        if(this.minisite_config.translation) {
            if(this.minisite_config.translationOptionsJson["S_"+section]) {
                section = this.minisite_config.translationOptionsJson["S_"+section]
            } else {
                section = section;
            }
        }

        if(textBody && (!textFooter && !price)) {
            content = "<div class='regularJson'>"+
                            "<ul class='list-unstyled ticket-selected'>"+
                                "<li style='border-left: none;'>"+
                                    "<span class='ticket-lab'>Section </span>"+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                                "<li>"+
                                    "<span class='ticket-lab'>Row </span>"+
                                    "<span class='ticket-val'>"+row+"</span>"+
                                "</li>"+
                                "<li>"+
                                    "<span class='ticket-lab'>Seat </span> "+
                                    "<span class='ticket-val'>"+seat+"</span>"+
                                "</li>"+
                            "</ul>"+
                            "<div class='ticket-selected-view'>" +
                                "<div class='ticket-selected-view-img'></div>"+
                            "</div>" +
                            "<div class='popup-text'>"+
                                textBody+
                            "</div>"+
                        "</div>";
        } else if(!textBody && (textFooter || price)) {
            content = "<div class='regularJson'>"+
                            "<ul class='list-unstyled ticket-selected'>"+
                                "<li style='border-left: none;'>"+
                                    "<span class='ticket-lab'>Section </span>"+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                                "<li>"+
                                    "<span class='ticket-lab'>Row </span>"+
                                    "<span class='ticket-val'>"+row+"</span>"+
                                "</li>"+
                                "<li>"+
                                    "<span class='ticket-lab'>Seat </span> "+
                                    "<span class='ticket-val'>"+seat+"</span>"+
                                "</li>"+
                            "</ul>"+
                            "<div class='ticket-selected-view'>" +
                                "<div class='ticket-selected-view-img'></div>"+
                            "</div>" +
                            "<div class='popup-price'>"+
                                "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                            "</div>"+
                        "</div>";
        } else if(!textBody && (!textFooter || !price)){
            content = "<div class='regularJson'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span>"+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                            "<li>"+
                                "<span class='ticket-lab'>Row </span>"+
                                "<span class='ticket-val'>"+row+"</span>"+
                            "</li>"+
                            "<li>"+
                                "<span class='ticket-lab'>Seat </span> "+
                                "<span class='ticket-val'>"+seat+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<div class='ticket-selected-view'>" +
                            "<div class='ticket-selected-view-img'></div>"+
                        "</div>" +
                    "</div>";
        } else {
            content = "<div class='regularJson'>"+
                            "<ul class='list-unstyled ticket-selected'>"+
                                "<li style='border-left: none;'>"+
                                    "<span class='ticket-lab'>Section </span>"+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                                "<li>"+
                                    "<span class='ticket-lab'>Row </span>"+
                                    "<span class='ticket-val'>"+row+"</span>"+
                                "</li>"+
                                "<li>"+
                                    "<span class='ticket-lab'>Seat </span> "+
                                    "<span class='ticket-val'>"+seat+"</span>"+
                                "</li>"+
                            "</ul>"+
                            "<div class='ticket-selected-view'>" +
                                "<div class='ticket-selected-view-img'></div>"+
                            "</div>" +
                            "<div class='popup-text'>"+
                                textBody+
                            "</div>"+
                            "<div class='popup-price'>"+
                                "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                            "</div>"+
                        "</div>";
        }

        return content;
    }

    public popoverGetContentSeatJson(section, row, seat){
        var textBody = "";
        var textFooter = "";
        var price = "";
        var content;

        if(this.minisite_config.popupJson.seats[section+"-"+row+"-"+seat]) {
            textBody = this.minisite_config.popupJson.seats[section+"-"+row+"-"+seat].textBody;
            textFooter = this.minisite_config.popupJson.seats[section+"-"+row+"-"+seat].textFooter;
            price = this.minisite_config.popupJson.seats[section+"-"+row+"-"+seat].price;
        } else if(this.minisite_config.popupJson.rows[section+"-"+row]) {
            textBody = this.minisite_config.popupJson.rows[section+"-"+row].textBody;
            textFooter = this.minisite_config.popupJson.rows[section+"-"+row].textFooter;
            price = this.minisite_config.popupJson.rows[section+"-"+row].price;
        } else if(this.minisite_config.popupJson.sections[section]) {
            textBody = this.minisite_config.popupJson.sections[section].textBody;
            textFooter = this.minisite_config.popupJson.sections[section].textFooter;
            price = this.minisite_config.popupJson.sections[section].price;
        }else {
            textBody = "";
            textFooter = "";
            price = "";
        }

        if(this.minisite_config.translation) {
            if(this.minisite_config.translationOptionsJson["S_"+section]) {
                section = this.minisite_config.translationOptionsJson["S_"+section]
            } else {
                section = section;
            }
        }

        if(textBody && (!textFooter && !price)) {
            content = "<div class='regularJson'>"+
                            "<ul class='list-unstyled ticket-selected'>"+
                                "<li style='border-left: none;'>"+
                                    "<span class='ticket-lab'>Section </span>"+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                                "<li>"+
                                    "<span class='ticket-lab'>Row </span>"+
                                    "<span class='ticket-val'>"+row+"</span>"+
                                "</li>"+
                                "<li>"+
                                    "<span class='ticket-lab'>Seat </span> "+
                                    "<span class='ticket-val'>"+seat+"</span>"+
                                "</li>"+
                            "</ul>"+
                            "<div class='popup-text'>"+
                                textBody+
                            "</div>"+
                        "</div>";
        } else if(!textBody && (textFooter || price)) {
            content = "<div class='regularJson'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span>"+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                            "<li>"+
                                "<span class='ticket-lab'>Row </span>"+
                                "<span class='ticket-val'>"+row+"</span>"+
                            "</li>"+
                            "<li>"+
                                "<span class='ticket-lab'>Seat </span> "+
                                "<span class='ticket-val'>"+seat+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<div class='popup-price'>"+
                            "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                        "</div>"+
                    "</div>";
        } else if(!textBody && (!textFooter || !price)){
            content = "<div class='regularJson'>"+
                            "<ul class='list-unstyled ticket-selected'>"+
                                "<li style='border-left: none;'>"+
                                    "<span class='ticket-lab'>Section </span>"+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                                "<li>"+
                                    "<span class='ticket-lab'>Row </span>"+
                                    "<span class='ticket-val'>"+row+"</span>"+
                                "</li>"+
                                "<li>"+
                                    "<span class='ticket-lab'>Seat </span> "+
                                    "<span class='ticket-val'>"+seat+"</span>"+
                                "</li>"+
                            "</ul>"+
                        "</div>";        
        } else {
            content = "<div class='regularJson'>"+
                            "<ul class='list-unstyled ticket-selected'>"+
                                "<li style='border-left: none;'>"+
                                    "<span class='ticket-lab'>Section </span>"+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                                "<li>"+
                                    "<span class='ticket-lab'>Row </span>"+
                                    "<span class='ticket-val'>"+row+"</span>"+
                                "</li>"+
                                "<li>"+
                                    "<span class='ticket-lab'>Seat </span> "+
                                    "<span class='ticket-val'>"+seat+"</span>"+
                                "</li>"+
                            "</ul>"+
                            "<div class='popup-text'>"+
                                textBody+
                            "</div>"+
                            "<div class='popup-price'>"+
                                "<span>"+ textFooter +"</span> <span>"+ price +"</span>"+
                            "</div>"+
                        "</div>";
        }

        return content;
    }
}
