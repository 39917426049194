import { Component, OnInit, Inject } from '@angular/core';
import { DVMService } from '../dvm.service';
import { MINISITE_CONFIGURATION, MinisiteConfiguration } from 'src/app/minisite.configuration';
declare var $:any;

@Component({
  selector: 'app-footer-dvm',
  templateUrl: './footer-dvm.component.html',
  styleUrls: ['./footer-dvm.component.scss']
})
export class FooterDvmComponent implements OnInit {

  constructor(private dvm: DVMService, @Inject(MINISITE_CONFIGURATION) private config: MinisiteConfiguration) { }

  ticketsUrl: string = this.config.ticketsUrl;
  ticketsBtn: boolean = this.config.buyTickets;
  filtersBtn: boolean = this.config.filters;
  isInfoMenu: boolean = this.config.infoMenuCheck;

  get menuoptions(){
    var menu = [];

    for(var i in this.config.infoMenuOptions){
    menu.push(this.config.infoMenuOptions[i])
    }

    return menu;
  }

  openInfoMenu(event){
    var element = event.currentTarget;
    let action = $(element).attr("data-action");

    if(action === 'open'){
      this.menuOpen();
    }else{
      this.menuClose();
    }
  }

  menuOpen(){
    $("#menu-info-mobile").attr("data-status","open");
    $("#menu-info-btn-mobile").attr("data-action","closed");
    $("#icon-info-btn-mobile").removeClass("icon-info");
    $("#icon-info-btn-mobile").addClass("icon-close");
  }

  menuClose(){
    $("#menu-info-mobile").attr("data-status","closed");
    $("#menu-info-btn-mobile").attr("data-action","open");
    $("#icon-info-btn-mobile").addClass("icon-info");
    $("#icon-info-btn-mobile").removeClass("icon-close");
  }

  menuToggle(event){
    this.dvm.menuToggle(event);
  }

  interfaceGoBack(){
    this.dvm.interfaceGoBack()
  }

  dvmToggleFS(){
    this.dvm.dvmToggleFS()
  }

  interfaceGoGallery() {
    this.dvm.interfaceGoGallery()
  }

  interfaceGoViewer(){
    this.dvm.interfaceGoViewer();
  }

  ngOnInit() {
  }

}
