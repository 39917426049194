import { Component, OnInit, Inject } from '@angular/core';
import { DVMService } from '../dvm.service';
import { MinisiteConfiguration, MINISITE_CONFIGURATION } from 'src/app/minisite.configuration';
declare var $:any;

@Component({
  selector: 'app-topbar-dvm',
  templateUrl: './topbar-dvm.component.html',
  styleUrls: ['./topbar-dvm.component.scss']
})
export class TopbarDvmComponent implements OnInit {
  constructor(private dvm: DVMService, @Inject(MINISITE_CONFIGURATION) private config: MinisiteConfiguration) { }

  isFilter = this.config.filters;
  ticketVal = this.config.ticketVal;
  ticketLab = this.config.ticketLab;
  bgTopbar = this.config.bgTopbar;
  tabs = this.config.tabs;
  labelColor = this.config.ticketLab;
  isInfoMenu = this.config.infoMenuCheck;
  neighbours = this.config.neighbours;
  infoMenuTitle;
  infoMenuOptions;
  auxMenuOptions;
  leftRight = this.config.leftRight;

  leftsection;
  rightsection;

  menuToggle(event) {
    this.dvm.menuToggle(event);
  }

  getMenuOptions(){
    if(this.config.tabs) {
        for(var i in this.config.infoMenuOptions) {
            if(this.config.infoMenuOptions[i].venue_id === this.dvm.venue_id && this.config.infoMenuOptions[i].map_id === this.dvm.map_id) {
                this.infoMenuOptions = this.config.infoMenuOptions[i];
                this.infoMenuTitle = this.config.infoMenuOptions[i].infomenu.title;
            }
        }
    } else {
      for(var i in this.config.infoMenuOptions) {
        this.infoMenuOptions = this.config.infoMenuOptions[i];
        this.infoMenuTitle = this.config.infoMenuOptions[i].infomenu.title;
      }
    }
  }

  get menuoptions(){
      if(this.config.infoMenuCheck && this.infoMenuOptions.infomenu) {
          var menu = [];
      
          for(var i = 0; i < (this.infoMenuOptions.infomenu.info).length; i++){
            menu.push(this.infoMenuOptions.infomenu.info[i])
          }
      
          return menu;
      }
  }

  get submenuoptions(){
    if(this.config.infoMenuCheck && this.infoMenuOptions.infomenu) {
        var menu = [];
    
        for(var i = 0; i < (this.infoMenuOptions.infomenu.info).length; i++){
          if(this.infoMenuOptions.infomenu.info[i].childrens){
            for(var j = 0; j < (this.infoMenuOptions.infomenu.info[i].childrens).length; j++){
              menu.push(this.infoMenuOptions.infomenu.info[i].childrens[j])
            }
          }
        }
    
        return menu;
    }
  }

  get menutitles(){
    if(this.config.infoMenuCheck && this.infoMenuOptions.infomenu) {
        var sub = [];
    
        for(var i = 0; i < (this.infoMenuOptions.infomenu.subtitles).length; i++){
          sub.push(this.infoMenuOptions.infomenu.subtitles[i])
        }
    
        return sub;
    }
  }

  get submenutitles(){
    if(this.config.infoMenuCheck && this.infoMenuOptions.infomenu) {
        var sub = [];
    
        for(var i = 0; i < (this.infoMenuOptions.infomenu.subtitles).length; i++){
          if(this.infoMenuOptions.infomenu.subtitles[i].childrens){
            for(var j = 0; j < (this.infoMenuOptions.infomenu.subtitles[i].childrens).length; j++){
              sub.push(this.infoMenuOptions.infomenu.subtitles[i].childrens[j])
            }
          }
        }
    
        return sub;
    }
  }

  // openInfoMenu(event){
  //   var element = event.currentTarget;
  //   let action = $(element).attr("data-action");
  //   if(action === 'open'){
  //       this.menuOpen();
  //   }else{
  //       this.menuClose();
  //   }
  // }

  menuOpen(){
    $("#menu-info").attr("data-status","opened");
    $("#menu-info-btn").attr("data-action","close");
    $("#menu-info-btn-mobile").attr("data-action","close");
    $("#icon-info-btn").removeClass("icon-info");
    $("#icon-info-btn").addClass("icon-close");
  }

  menuClose(){
    $("#menu-info").attr("data-status","closed");
    $("#menu-info-btn").attr("data-action","open");
    $("#menu-info-btn-mobile").attr("data-action","open");
    $("#icon-info-btn").addClass(".icon-info");
    $("#icon-info-btn").removeClass(".icon-close");
  }

  onClickPrev(){
    var idPrev = this.config.neighboursOptionsJson[this.dvm.actualMap].l;
    this.dvm.changeSeatmap(idPrev, 'prev');
  }

  onClickNext(){
    var idNext = this.config.neighboursOptionsJson[this.dvm.actualMap].r;
    this.dvm.changeSeatmap(idNext, 'next');
  }

  get options() {
    var option = [];

    for(var i in this.config.tabOptionsJson) {
      option.push(this.config.tabOptionsJson[i]);
    }

    return option;
  }

  public openOption(option, index) {
    if(!$("#option-button-"+index).hasClass("active")){
        $(".mapstyle-button").removeClass("unactive");
        $(".mapstyle-button").removeClass("active");
        $("#option-button-"+index).addClass("active");
    }
    $(".ticket-selected").addClass("hidden");
    this.config.venueID = option.venueid;
    this.config.mapID = option.mapid;
    $("#loadingmap").removeClass("d-none");
    this.dvm.changeVenue(option.venueid, option.mapid, option.minimapid);
    this.getMenuOptions();
    this.dvm.loadMap();
  } 

  ngOnInit() {
    if(this.config.infoMenuCheck) {
        this.getMenuOptions();
    }

    if(this.config.ticketVal) {
      document.documentElement.style.setProperty('--text-color', this.config.ticketVal);
    } else {
      document.documentElement.style.setProperty('--text-color', "#fff");
    }

    if(this.config.ticketLab) {
      document.documentElement.style.setProperty('--label-color', this.config.ticketLab);
    } else {
      document.documentElement.style.setProperty('--label-color', "#fff");
    }

    // if(!this.config.filters){
    //   $("#top-guide").css("padding-left", "20px")
    // }
  }

}
