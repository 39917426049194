import { Component, Inject } from '@angular/core';
import { GeneralState, Tk3dService } from 'ngx-tk3d';
import { MinisiteConfiguration, MINISITE_CONFIGURATION } from './minisite.configuration';
import { Title } from '@angular/platform-browser';
import * as global from './services/global-functions';
import { environment } from '@environment';
import { StateService } from '@uirouter/angular';
import { DOCUMENT } from '@angular/common';

declare var $:any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
    subtitleName = this.minisiteConfig.subtitleName ? this.minisiteConfig.subtitleName : "3D seatmap"
    title = this.minisiteConfig.venueName + ' | ' + this.subtitleName;
    tk3dMinisite:boolean;
    
    get status(): GeneralState {
        return this.tk3d.tk3dStatus;
    }
    
    faviconInitFactory () {
        var imgUrl = this.minisiteConfig.minisiteUrl;
        if(this.minisiteConfig.favicon){
          if(this.minisiteConfig.faviconType === "png"){
            var favicon = `https://3ddigitalvenue.com/3dmap/${environment.url}/${imgUrl}/favicon.png`;
          } else if(this.minisiteConfig.faviconType === "svg") {
            var favicon = `https://3ddigitalvenue.com/3dmap/${environment.url}/${imgUrl}/favicon.svg`;
          } else if(this.minisiteConfig.faviconType === "ico") {
            var favicon = `https://3ddigitalvenue.com/3dmap/${environment.url}/${imgUrl}/favicon.ico`;
          } else {
            var favicon = `https://3ddigitalvenue.com/3dmap/${environment.url}/${imgUrl}/favicon.png`;
          }
        }
        const link: any = document.querySelector(`link[rel*='icon']`) || document.createElement('link')
        link.type = 'image/x-icon'
        link.rel = 'shortcut icon'

        link.href = favicon;

        document.getElementsByTagName('head')[0].appendChild(link);  
    }

    setTitle() {
        this.titleService.setTitle(this.title);
    }

    openCookiesCenter(){
        document.getElementById("cookiescenter-modal").style.display = "block";
    }

    savecookies(permit){
        var value;
        var name = window.location.pathname.split("/")[1] + "_cookiescenter";
        document.getElementById("cookiescenter-modal").style.display = "none";
        document.getElementById("cookies-banner").style.display = "none";
        if(permit || $("#analyticsCookies-input").prop('checked') === true){
            value = "allow";
        } else {
            value = "deny";
            this.deleteCookie("_ga");
            this.deleteCookie("_gid");
            this.deleteCookie("_gat");
            this.deleteCookie("collect");
        }
        this.setCookie(name, value);
    }

    isCookie: boolean = false;
    isAllowed: boolean = true;

    getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }
    
    setCookie(cname, cvalue) {
        var d = new Date();
        d.setTime(d.getTime() + (3600 * 24 * 365 * 2 * 1000));
        var expires = ";expires="+d.toUTCString();

        document.cookie = cname + "=" + cvalue + expires;
    }
      
    checkCookie(name) {
        var token = this.getCookie(name);
        if (token != "") {
          return true;
        } else {
          return false;
        }
    }
    
    deleteCookie(name) {
        document.cookie = name+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    constructor(private titleService: Title, private tk3d: Tk3dService, 
      @Inject(MINISITE_CONFIGURATION) private minisiteConfig: MinisiteConfiguration,private state: StateService) { }

    ngOnInit() {
        if(this.checkCookie(window.location.pathname.split("/")[1] + "_cookiescenter")){
            this.isCookie = true;
        } else {
            this.isCookie = false;
        }

        if(this.getCookie(window.location.pathname.split("/")[1] + "_cookiescenter") === "allow"){
            window['ga-disable-G-RNYXFJ7BHH'] = false;
        } else {
            window['ga-disable-G-RNYXFJ7BHH'] = true;
        }
        
        this.setTitle();
        this.faviconInitFactory();
        if(this.minisiteConfig.flag === "TK3D") {
            this.tk3dMinisite = true;
        } else if(this.minisiteConfig.flag === "DVM"){
            this.tk3dMinisite = false;
        } else {
            this.state.go('common.error')
        }
    }
}
