import { Component, OnInit, Inject } from '@angular/core';
import { MinisiteConfiguration, MINISITE_CONFIGURATION } from '../minisite.configuration';
import { DVMService } from './dvm.service';
import { environment } from '@environment';

declare var $: any;


@Component({
  selector: 'app-dvm',
  templateUrl: './dvm.component.html',
  styleUrls: ['./dvm.component.scss']
})
export class DVMComponent implements OnInit {

  constructor( private dvm: DVMService, 
    @Inject(MINISITE_CONFIGURATION) private config: MinisiteConfiguration) { }

  bgHeader = this.config.bgHeader;
  bg = this.config.bg;
  iframe = false;

  ngOnInit() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const iframe = urlParams.get("iframe");

    if(iframe === "true"){
      this.iframe = true;
    } else {
      this.iframe = false;
    }

    if(this.config.backgroundColor) {
      $("body").css("background-color", this.config.backgroundColor);
    } else {
      $("body").css("background-image", `url(https://3ddigitalvenue.com/3dmap/${environment.url}/` + this.config.minisiteUrl + "/bg.jpg)");
    }
    this.dvm.loadMap();
  }
}
