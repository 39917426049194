import { Component, OnInit, Input, Inject } from '@angular/core';
import { GeneralState, InteractionMessageProtocol, Tk3dService } from 'ngx-tk3d';
import { Subject } from 'rxjs';
import * as global from '../../services/global-functions';
import { MINISITE_CONFIGURATION, MinisiteConfiguration } from 'src/app/minisite.configuration';
declare var $:any;

@Component({
  selector: 'app-searchbar-interface',
  templateUrl: './searchbar-interface.component.html',
  styleUrls: ['./searchbar-interface.component.scss']
})
export class SearchbarInterfaceComponent implements OnInit {

  @Input() state: GeneralState;

	get message(): InteractionMessageProtocol{
		return this.message;
	}
	
	constructor(@Inject(MINISITE_CONFIGURATION) private minisite_config: MinisiteConfiguration, private tk3d: Tk3dService) { 
	}
	
	searchTerm$ = new Subject<string>();
	count = 0;
	txtHeader:string = this.minisite_config.txtHeader;
	buyTickets = this.minisite_config.buyTickets;
	rightPosition;

	get results() {
		var selection = [];
		var searchVal = $("#search").val().toString().toLowerCase();

		if (searchVal !== "") {
			var result = [];
			for (var blockID in global.blockhash) {
				if (blockID.toLowerCase().indexOf(searchVal) !== -1) {
					result.push(blockID);
				}
			}

			for (var i = 0; i < result.length; ++i) {
				var sectionID = result[i];
				var areaID = global.blockhash[sectionID];
				selection.push(areaID);
			}

			selection.sort();

			if(this.count === 0){
				this.filterSectionOnBlockmap(selection);
			}

		} else if (this.state.blockmap.moduleRef) {
			try{
				this.filterSectionOnBlockmap([]);
			}catch {}
		}

		return selection;
	}

	locateSeatmap(id){
		this.resetSearch();
		$(".contact-btn").addClass("hidden");
		$(".interior").addClass("hidden");
		$(".interior-mobile").addClass("hidden");
		this.state.view3d.moduleRef.close();

		if(this.minisite_config.viewType === "seat") {
			if(this.exceptions && this.exceptions.indexOf(id) > -1){
				this.state.blockmap.selected = id;
				this.state.blockmap['size'] = 'mini';
				this.state.seatmap['size'] = 'mini';
				this.state.view3d['size'] = 'large';
				global.selectedBlockWrite(id);
				this.state.view3d.moduleRef['setContainer']('view3d');
				this.state.view3d.moduleRef['load'](id);
				this.state.view3d.visible = true;
				this.state.blockmap.moduleRef.setAvailability(true);
				this.state.blockmap.moduleRef.setElementAvailable(this.state.blockmap.selected);
				this.state.blockmap.moduleRef['disableZoom'](true);
			} else {
				this.state.blockmap.selected = id;
				global.selectedBlockWrite(id);

				this.state.seatmap.moduleRef.loadMap(id, () => {
                    const forcedMessage: InteractionMessageProtocol = {
                        event: 'loaded',
                        emmitterSize: 'large',
                        emitter: 'seatmap',
                        //element: {id:message.element.id, HTMLElement:null, status:null}
                    };
                    this.tk3d.handle(forcedMessage);
				});
				
				this.state.blockmap['size'] = 'mini';
                this.state.seatmap.visible = true;
                this.state.view3d['size'] = 'mini';
                $('#seatmap').removeClass("d-none");
			}
		} else {
			if(this.exceptions && this.exceptions.indexOf(id) > -1){
				this.state.blockmap.selected = id;
				global.selectedBlockWrite(id);

				this.state.seatmap.moduleRef.loadMap(id, () => {
                    const forcedMessage: InteractionMessageProtocol = {
                        event: 'loaded',
                        emmitterSize: 'large',
                        emitter: 'seatmap',
                        //element: {id:message.element.id, HTMLElement:null, status:null}
                    };
                    this.tk3d.handle(forcedMessage);
				});
				
				this.state.blockmap['size'] = 'mini';
                this.state.seatmap.visible = true;
                this.state.view3d['size'] = 'mini';
                $('#seatmap').removeClass("d-none");
			} else {
				this.state.blockmap.selected = id;
				this.state.blockmap['size'] = 'mini';
				this.state.seatmap['size'] = 'mini';
				this.state.view3d['size'] = 'large';
				global.selectedBlockWrite(id);
				this.state.view3d.moduleRef['setContainer']('view3d');
				this.state.view3d.moduleRef['load'](id);
				this.state.view3d.visible = true;
				this.state.blockmap.moduleRef.setAvailability(true);
				this.state.blockmap.moduleRef.setElementAvailable(this.state.blockmap.selected);
				this.state.blockmap.moduleRef['disableZoom'](true);
			}
		}
	}

	onClick(element) {
		this.locateSeatmap(element);
	}

	mouseEnter(element) {
		this.count = 1;
		this.filterSectionOnBlockmap(element);
	}

	mouseOut() {
		this.count = 0;
	}

	resetSearch(){
		$('.form-group input').blur();
		$('.form-group input').val('');
	}

	filterSectionOnBlockmap(section) {
		this.state.blockmap.moduleRef.removeStatus(this.state.blockmap.moduleRef.getElementsByStatus("filtered"), "filtered");
		this.state.blockmap.moduleRef.addStatus(section, "filtered");
	}

	exceptions;

	ngOnInit() {
		if(this.minisite_config.exceptionsJsonCheck) {
            this.exceptions = this.minisite_config.exceptionsJson['sections'];
        } else {
            this.exceptions = this.minisite_config.exceptions;
        }
		// if(this.buyTickets){
		// 	if(global.isMobile()){
		// 		this.rightPosition = "20px";
		// 	} else {
		// 		this.rightPosition = "223px";
		// 	}
		// } else {
		// 	this.rightPosition = "20px";
		// }
	}

}
