import { Component, OnInit, Inject } from '@angular/core';
import { DVMService } from '../dvm.service';
import { MinisiteConfiguration, MINISITE_CONFIGURATION } from 'src/app/minisite.configuration';
import { DomSanitizer } from '@angular/platform-browser';
import { forEach } from '@uirouter/angular';
declare var $:any;


@Component({
  selector: 'app-interface-dvm',
  templateUrl: './interface-dvm.component.html',
  styleUrls: ['./interface-dvm.component.scss']
})
export class InterfaceDvmComponent implements OnInit {

  constructor(private sanitization:DomSanitizer, private dvm: DVMService, @Inject(MINISITE_CONFIGURATION) private config: MinisiteConfiguration) { }

  tabsManual = this.config.tabsManual;
  copyLink = this.config.copyLinkCheck;
  currentView = this.dvm.current_view;
  multiNav = this.config.multiIndoorCheck;

//style="background-image: url({{nav.image}});"
  get indoors(){
    if(this.dvm.current_view === 'view3d'){
      return this.dvm.multIndoors;
    }
  }

  interfaceGoHome() {
    this.dvm.interfaceGoHome();
  }

  interfaceGoBack() {
    this.dvm.interfaceGoBack();
  }

  dvmToggleFS() {
    this.dvm.dvmToggleFS();
  }

  interfaceGoGallery() {
    this.dvm.interfaceGoGallery();
  }

  interfaceGoViewer() {
    this.dvm.interfaceGoViewer();
  }

  filterBy(event, child) {
    var element = event.currentTarget;
    this.dvm.filterBy(element, child);
  }

  multiNavigation(item, index){
    this.dvm.multiNavigation(item, index);
  }

  dvmNavigationGo() {
    this.dvm.dvmNavigationGo();
  }

  showChildrens(event, item?){
    let accordion_btn = event.currentTarget;
    var i = accordion_btn.querySelector("i");

    if(this.dvm.filtering_referenced[item].venue || this.dvm.filtering_referenced[item].map) {
      if(this.dvm.venue_id !== this.dvm.filtering_referenced[item].venue || this.dvm.map_id !== this.dvm.filtering_referenced[item].map){
        if(!i.classList.contains("icon-arrow-down")){
          this.dvm.dvmStartFiltering(this.dvm.filtering_referenced[item].venue, this.dvm.filtering_referenced[item].map, this.dvm.filtering_referenced[item].minimap_id, this.dvm.filtering_referenced[item].children)
        } 
      }
    }

    if(i.classList.contains("icon-arrow-right")){
        i.classList.remove("icon-arrow-right");
        i.classList.add("icon-arrow-down");
    }else{
        i.classList.remove("icon-arrow-down");
        i.classList.add("icon-arrow-right");
    }

    var dropItem = $(accordion_btn).next(".accordion");
    $(accordion_btn).toggleClass("active");
    
    if ($(accordion_btn).hasClass("active")) {
        $(dropItem).attr("style","height: auto");
    } else {
        $(dropItem).attr("style","height: 0");
    };
  }

  interfaceShare() {
    $(".custom-modal").toggleClass("d-none");
    $(".share-btn").toggleClass("active-btn");
  
    if (!$(".custom-modal").hasClass("active-btn")) {
      $(".confirmcopy").addClass("d-none");
      $(".linktoshare-input").removeClass("d-none");
      $(".copybtn").removeClass("d-none");
    } else {
      $(".confirmcopy").removeClass("d-none");
      $(".linktoshare-input").addClass("d-none");
      $(".copybtn").addClass("d-none");
    }
  }

  copyShareLink() {
    /* Get the text field */
    var copyText = null;
  
    if (!this.dvm.interfaceIsMobile()) {
      copyText = document.getElementById("linktoshare");
    } else {
      copyText = document.getElementById("linktoshare-mbl");
    } // console.log(copyText);
  
    /* Select the text field */

    copyText.select();
    copyText.setSelectionRange(0, 99999);
    /* For mobile devices */
  
    /* Copy the text inside the text field */
  
    document.execCommand("copy");
    /* Alert the copied text */
  
    $(".confirmcopy").removeClass("d-none");
    $(".linktoshare-input").addClass("d-none");
    $(".copybtn").addClass("d-none");
  }
  
  item = [];
  child = [];

  get items() {
    return this.item;
  }

  get childrens() {
    return this.child;
  }

  filteringItemConstruct(itemRef) {
    this.dvm.addItemRef(itemRef, null);
  }

  option = [];

  get options(){
    return this.option;
  }

  interfaceChangeView(option, index){
    this.dvm.interfaceChangeView(option, index);
  }

  // changePano(clase) {
  //   this.dvm.changePano(clase);
  // }

  ngOnInit() {
    var mapHeight = $("#viewer").innerHeight();
    
    if(this.config.tabs || this.config.tabsManual) {
      for(var k in this.config.tabOptionsJson) {
        this.config.tabOptionsJson[k].imgActive = this.sanitization.bypassSecurityTrustUrl(this.config.tabOptionsJson[k].imgActive)
        this.option.push(this.config.tabOptionsJson[k]);
      }
    }

    if(this.config && this.config.filteringJson) {
        for(var i = 0; i < this.config.filteringJson.filtering.length; i++) {
            var itemRef = this.config.filteringJson.filtering[i];
            this.filteringItemConstruct(itemRef);
            this.item.push(itemRef);
        }
    }
  }
}
