import { Component, OnInit, Input, Inject } from '@angular/core';
import { GeneralState, Tk3dConfig, Tk3dService, TK3D_CONFIG } from 'ngx-tk3d';
import { MINISITE_CONFIGURATION, MinisiteConfiguration } from 'src/app/minisite.configuration';
declare var $:any;
import * as global from '../../services/global-functions';

@Component({
  selector: 'app-filters-interface',
  templateUrl: './filters-interface.component.html',
  styleUrls: ['./filters-interface.component.scss']
})
export class FiltersInterfaceComponent implements OnInit {

    @Input() state: GeneralState;

    get status(): GeneralState {
        return this.tk3d.tk3dStatus;
    }

    constructor( private tk3d: Tk3dService,
        @Inject(MINISITE_CONFIGURATION) private minisite_config: MinisiteConfiguration, @Inject(TK3D_CONFIG)  private tk3d_config: Tk3dConfig) {
    }

    subfilterList = false;

    public showChildrens(event) {
        let accordion_btn = event.currentTarget;
        $(accordion_btn).toggleClass("active");
        var i = accordion_btn.querySelector("i");

        if(i.classList.contains("icon-arrow-right")){
            i.classList.remove("icon-arrow-right");
            i.classList.add("icon-arrow-down");
        }else{
            i.classList.remove("icon-arrow-down");
            i.classList.add("icon-arrow-right");
        }

        var dropItem = $(accordion_btn).next(".accordion");
        
        if ($(accordion_btn).hasClass("active")) {
            $(dropItem).attr("style","height: auto");
        } else {
            $(dropItem).removeAttr("style");
        };
    }

    interfaceResetFiltering(){
        global.interfaces.filtering = {
            ref : null,
            nodes : {
                sections: [],
                rows: [],
                seats: []
            }
        }
    }

    removeOldFiltering(){
        //remove old filtering
        $(".filter").removeClass("active");
        this.status.blockmap.moduleRef.setAvailability(false);
        $("#top-guide").removeClass("filtered");
        $(".section-lab").html("Section");
        if(this.minisite_config.unavailable) {
            this.state.blockmap.moduleRef.setElementUnavailable(this.minisite_config.unavailableOptionsJson[this.state.blockmap.moduleRef.getMapId()]['sections'])
        }
        
        return null;
    }

    filtering_referenced = {};
    filtering_typereferenced = {};

    filterBy(event){    
        var element = event.currentTarget;
        this.interfaceResetFiltering();
        if(!$(element).hasClass("active")){
            this.removeOldFiltering();
            var ref = $(element).attr("id");
            var parent = document.getElementById(ref).parentNode.parentNode;
            var btn = parent.firstElementChild;
            var id = $(btn).attr("id");

            if(document.getElementById("accordionContainer")) {
                for(var q = 0; q < document.getElementById("accordionContainer").querySelectorAll('button').length; q++) {
                    document.getElementById("accordionContainer").querySelectorAll('button')[q].classList.remove("selected");
                }
            }

            if(document.getElementsByClassName("accordion").length > 0) {
                for(var h = 0; h < document.getElementsByClassName("accordion").length; h++) {
                    for(var w = 0; w < document.getElementsByClassName("accordion")[h].querySelectorAll('div').length; w++) {
                        document.getElementsByClassName("accordion")[h].querySelectorAll('div')[w].classList.remove("selected");
                    }
                }
            }

            document.getElementById(ref).classList.remove("selected");
            $(element).removeClass("active");
            $(element).addClass("active");
            document.getElementById(ref).classList.add("active");

            if(ref === "regularseating") {
                $(".filter").find("#regularseating").add("active");
                $(".filter").find("#generaloverview").removeClass("active");
            } else if(ref === "generaloverview") {
                $(".filter").find("#generaloverview").add("active");
                $(".filter").find("#regularseating").removeClass("active");
            } else {
                $(".filter").find("#regularseating").removeClass("active");
                $(".filter").find("#generaloverview").removeClass("active");
            }

            $(element).addClass("active");

            for(var i = 0; i < this.minisite_config.filteringJson.filtering.length; i++) {
                if (this.minisite_config.filteringJson.filtering[i].ref === ref) {
                    if(this.minisite_config.filteringJson.filtering[i].venue && this.minisite_config.filteringJson.filtering[i].venue !== this.minisite_config.venueID) {
                        this.tk3d_config.venue = this.minisite_config.filteringJson.filtering[i].venue;
                        this.tk3d.changeVenue(this.minisite_config.filteringJson.filtering[i].venue);
                    }

                    if(this.minisite_config.filteringJson.filtering[i].map && this.minisite_config.filteringJson.filtering[i].map !== this.minisite_config.mapID) {
                        this.tk3d_config.mapId = this.minisite_config.filteringJson.filtering[i].map;
                    }

                }
            }

            document.getElementById(ref).classList.add("selected");

            var nodes = this.filtering_referenced[ref].nodes;
            this.state.blockmap.moduleRef.addStatus(nodes.sections, "filtered");
            this.state.blockmap.moduleRef.addStatus(nodes.sections, "searched");
            this.state.blockmap.moduleRef.setAvailability(nodes.sections, false);

            if(this.minisite_config.unavailable) {
                this.state.blockmap.moduleRef.setElementUnavailable(this.minisite_config.unavailableOptionsJson[this.state.blockmap.moduleRef.getMapId()]['sections'])
            }

            //global.interfaces.topview.nodes_selected = nodes.sections;
            global.interfaces.filtering.nodes = nodes;
            global.interfaces.filtering.ref = ref;

        }else{
            var ref = $(element).attr("id");
            document.getElementById(ref).classList.remove("selected");
            if(ref === "regularseating") {
                $(".filter").find("#regularseating").removeClass("active");
            }else if(ref === "generaloverview") {
                $(".filter").find("#generaloverview").removeClass("active");
            }
            //global.interfaces.topview.nodes_selected = [];
            this.state.blockmap.moduleRef.removeStatus(this.state.blockmap.moduleRef.getElementsByStatus("filtered"), "filtered");
            this.state.blockmap.moduleRef.removeStatus(this.state.blockmap.moduleRef.getElementsByStatus("searched"), "searched");
            this.removeOldFiltering();
        }
        this.setBreadcrumbs();

        return null;
    }

    setBreadcrumbs(){
        var ref = global.interfaces.filtering.ref;
        var bc_val = "";

        this.interfaceBCResetColours();
        if(ref){
            var current_fil = this.filtering_referenced[ref];
            bc_val = "<span class='breadcrumbs-atitle' style='width:auto; max-width:111px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;'>"+this.filtering_referenced[ref].title+"</span>";
            if(current_fil.parentref){
                bc_val = "<span style='width:auto; max-width:111px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;'>"+
                this.filtering_referenced[current_fil.parentref].title+"</span>"+
                "<div class='separator' style=' width: 1px; background: var(--label-color); margin-left: 12px; margin-right: 12px;opacity: 0.6;'></div>"+
                bc_val;
            }
            var type = current_fil.type;
            if(!type){
                type = current_fil.parenttype;
            }
            $(".bread-crumbs").find(".breadcrumbs-val").addClass(type);
            $(".section-lab").addClass(type);
            $(".row-lab").addClass(type);
            $(".seat-lab").addClass(type);
        }

        $(".breadcrumbs-val").html(bc_val);
    
    }

    interfaceBCResetColours(){
        for(var type in this.filtering_typereferenced){
            $(".bread-crumbs").find(".breadcrumbs-val").removeClass(type);
            $("#top-guide").find(".section-lab").removeClass(type);
            $("#top-guide").find(".row-lab").removeClass(type);
            $("#top-guide").find(".seat-lab").removeClass(type);
        }
        return null;
    }

    selected = [];


    removeString(elements,chars){
        var resp = [];
        for(var i=0;i<elements.length;i++){
            resp.push(elements[i].replace(chars, ""));
        }
        return resp;
    }

    filteringToggleSubList(element){
        var that = this;
        if($(element).attr("data-status") == 'closed'){
            $(".filter-item[data-status='visible']").each(function(e){
                var opened_element = $(this);
                that.filteringCloseSubList(opened_element);
            });
            this.filteringOpenSubList(element);
        }else{
            this.filteringCloseSubList(element);
        }
        return null;
    }

    filteringOpenSubList(element){
        $(".filter-item[data-status='closed']").removeClass("active");

        $(".filter-item[data-status='closed']").each(function(e){
            var b = $(this);
            var s = $(b).siblings();
            $(s).each(function(){
                var sl = $(this);
                var a = $(sl).find(".filter-subitem.active");
                if (a.length>0){
                    $(b).addClass("active");
                }
            })
        });
        
        $(element).addClass("active");

        var i = $(element).find(".fa");
        $(i).removeClass("fa-angle-right").addClass("fa-angle-down");
        $(i).css("color", "#fff");

        var sublist = $(element).siblings(".filter-subitem-list");
        $(sublist).removeClass("hidden");
        $(element).attr("data-status",'visible');

        var not_scroll = ["ps1_sf",'lx_sf','ps2_sf'];
        if ($.inArray($(sublist).attr("id"),not_scroll)==-1){
            $(".filtering-list").animate({ scrollTop: $(sublist).prop("scrollHeight")*2 }, 1000);
        }
        return null;
    }

    filteringCloseSubList(element){
        var i = $(element).find(".fa");
                $(i).removeClass("fa-angle-down").addClass("fa-angle-right");
                $(i).css("color", "#a5a5a5");

        var sublist = $(element).siblings(".filter-subitem-list");

        $(sublist).addClass("hidden");
        $(element).attr("data-status",'closed');
        return null;
    }

    filteringItemConstruct(itemRef) {
        this.addItemRef(itemRef, null);
    }

    addItemRef(item,parentItem){
        item.ref = item.title.replace(/[^a-zA-Z0-9]/g, "").replace(/ /g,'').toLowerCase();
        if(parentItem){
            if (parentItem.ref){
                item.parentref = parentItem.ref;
            }
            if (parentItem.type){
                item.parenttype = parentItem.type;
            }
        }
        this.filtering_referenced[item.ref]=item;
        if (item.type && !this.filtering_typereferenced[item.type]){
            this.filtering_typereferenced[item.type] = [];
        }
        if (item.parenttype && !this.filtering_typereferenced[item.parenttype]){
            this.filtering_typereferenced[item.parenttype] = [];
        }

        if(item.children){
            for(var i =0;i<item.children.length;i++){
                item.children[i] = this.addItemRef(item.children[i],item);
            }
        }else if(item.nodes){
            var nodes_toadd = [];
            nodes_toadd = nodes_toadd.concat(item.nodes.sections);
            nodes_toadd = nodes_toadd.concat(item.nodes.rows);
            nodes_toadd = nodes_toadd.concat(item.nodes.seats);
            if(item.type){
                this.filtering_typereferenced[item.type] = this.filtering_typereferenced[item.type].concat(nodes_toadd);
            }else{
                this.filtering_typereferenced[item.parenttype] = this.filtering_typereferenced[item.parenttype].concat(nodes_toadd);
            }
        }
        
        return item;
    }

    get items() {
        return this.item;
    }

    get childrens() {
        return this.child;
    }

    item = [];
    child = [];

    ngOnInit() {
        if(this.minisite_config.filteringJson) {
            for(var i = 0; i < this.minisite_config.filteringJson.filtering.length; i++) {
                var itemRef = this.minisite_config.filteringJson.filtering[i];
                this.filteringItemConstruct(itemRef);
                this.item.push(itemRef);
                if(itemRef.children) {
                    for(var j = 0; j < itemRef.children.length; j++) {
                        this.child.push(itemRef.children[j])
                    }
                }
            }

            $(".menu-tk3d").css("max-height", document.getElementById("main-interface").style.height)
        }
    }

}
