import { Component, OnInit, Input, Inject } from '@angular/core';
import { Tk3dService } from 'ngx-tk3d';
import { MINISITE_CONFIGURATION, MinisiteConfiguration } from '../minisite.configuration';
import * as global from '../services/global-functions';
declare var $:any;

@Component({
  selector: 'app-bottom-interface',
  templateUrl: './bottom-interface.component.html',
  styleUrls: ['./bottom-interface.component.scss']
})
export class BottomInterfaceComponent implements OnInit {

    @Input() state;
    buyTickets = this.minisite_config.buyTickets;
    txtHeader = this.minisite_config.txtHeader;
    bgButton = this.minisite_config.bgButton;
    buttonLab = this.minisite_config.buttonLab;
    ticketsUrl: string = this.minisite_config.ticketsUrl;
    filters: boolean;
    isInfoMenu: boolean = this.minisite_config.infoMenuCheck;

    constructor(private tk3d: Tk3dService, @Inject(MINISITE_CONFIGURATION) private minisite_config: MinisiteConfiguration) { 
        this.filters = this.minisite_config.filters;
    }

    get menuoptions(){
        var menu = [];
    
        for(var i in this.minisite_config.infoMenuOptions){
        menu.push(this.minisite_config.infoMenuOptions[i])
        }
    
        return menu;
      }
    
      openInfoMenu(event){
        var element = event.currentTarget;
        let action = $(element).attr("data-action");
        if(action === 'open'){
          this.menuInfoOpen();
        }else{
          this.menuInfoClose();
        }
      }
    
      menuInfoOpen(){
        $("#menu-info").attr("data-status","open");
        $("#menu-info-btn-mobile").attr("data-action","closed");
      }
    
      menuInfoClose(){
        $("#menu-info").attr("data-status","closed");
        $("#menu-info-btn-mobile").attr("data-action","open");
      }

    isStep1() {
        return this.state.blockmap.visible && this.state.blockmap.size === 'large';
    }

    isStep2() {
        return this.state.seatmap.visible && this.state.seatmap.size === 'large';
    }

    isStep3 () {
        return this.state.view3d.visible;
    }

    public tk3dBack() {
        if (this.state.view3d.visible) {
            if(this.minisite_config.viewType === "section") {
                if(this.exceptions && this.exceptions.indexOf(this.state.blockmap.selected) > -1) {
                    this.tk3d.onSeatMapMiniClick();
                } else {
                    this.tk3d.onBlockMapMiniClick();
                }
            } else {
                if(this.exceptions && this.exceptions.indexOf(this.state.blockmap.selected) > -1) {
                    this.tk3d.onBlockMapMiniClick();
                } else {
                    this.tk3d.onSeatMapMiniClick();
                }
            } 
        } else if(this.state.seatmap.visible){
            //$('#seatmap').height(0);
            this.tk3d.onBlockMapMiniClick();
        }
    }

    public filteringDisplay(event) {
        var element = event.currentTarget;
        console.log(element)
        let action = $(element).attr("data-action");
        console.log(action)
        if(action === 'open'){
            this.menuOpen();
        }else{
            this.menuClose();
        }
        return null;
    }

    menuOpen(){
        $("#menu").attr("data-status","opened");
        $(".filt-btn").attr("data-action","close");
        if(global.isMobile()){
            $(".filt-btn").find("i").removeClass("icon-menu").addClass("icon-arrow-down");
        }

        return null; 
    }

    menuClose(){
        $("#menu").attr("data-status","closed");
        $(".filt-btn").attr("data-action","open");
        $(".filt-btn").find("i").removeClass("icon-arrow-down").addClass("icon-menu");
        return null;
    }

    toggleFullscreen() {
        $("#ticketing3dwidget-fullscreen-button0").addClass("d-none");
        var fsBtn = document.getElementById("bottom-tk3d");        
        
        if(!document.getElementById("ticketing3dwidget-fullscreen-button0").classList.contains("onfullscreen-tk3d")) {
            fsBtn.classList.remove("d-none");
            document.getElementById("ticketing3dwidget0").appendChild(fsBtn);
        } else {
            fsBtn.classList.add("d-none");
        }
        
        this.state.view3d.moduleRef.toggleFullscreen();
        // if(!$("#ticketing3dwidget-fullscreen-button0").hasClass("onfullscreen-tk3d")) {
        //     $("#ticketing3dwidget0").append($(".fsclose-btn-tk3d").removeClass("d-none"))
        // } else {
        //     $(".fsclose-btn-tk3d").addClass("d-none");
        // }
    }

    exceptions;

    ngOnInit() {
        if(this.minisite_config.exceptionsJsonCheck) {
            this.exceptions = this.minisite_config.exceptionsJson['sections'];
        } else {
            this.exceptions = this.minisite_config.exceptions;
        }
    }

    ngAfterViewChecked() {
        if(this.state.blockmap.size !== 'large') {
            this.state.blockmap.moduleRef['updateSize']();
        }
    }

}
