import { Injectable, Inject } from '@angular/core';
import { ResizeService, GeneralState } from 'ngx-tk3d';
import * as global from './global-functions';
declare var $:any;
import * as $ from 'jquery';


@Injectable({
    providedIn: 'root'
})
export class ResizeNormalService implements ResizeService {
    public minimapModule:any;
    hm: number;

    headerH: number;
    topbarH: number;
    footerH: number;
    bottomH: number;
    buttonH: number;

    blockmapH: number;
    blockmapW: number;

    container;
    bg;
    svg_ar;
    con_ar;
    h;w;

    moduleRef: any;

    constructor() { }

    handleResize(status: GeneralState) {
        if(!global.isMobile()){
            var ar = global.getSize('blockmap');
            var ar2 = global.getSize("seatmap");
            var nh = window.innerHeight;
            var wd;
            $(".container").css("min-width","700px");

            // wd = (nh - $("#header-wrap").innerHeight() 
            //          - $("#topbar").innerHeight()) / (ar2);

            if(ar === 0.625) {
                wd = (nh - $("#header-wrap").innerHeight() - $("#topbar").innerHeight())*(960/600);
            } else {
                wd = (nh - $("#header-wrap").innerHeight() - $("#topbar").innerHeight())*(1200/680);
            }

            $("#interface-wrap").css("max-width", wd);
            $("#header-wrap").css("max-width", wd);

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const iframe = urlParams.get("iframe");

            if(iframe === "true"){
                var iframeSize;
                if(ar === 0.625) {
                    iframeSize = (nh - $("#topbar").innerHeight())*(960/600);
                } else {
                    iframeSize = (nh - $("#topbar").innerHeight())*(1200/680);
                }
                $(".filt-btn").css("display", "block");
                $(".interface-wrap-iframe").css("max-width", iframeSize);
                $(".interface-wrap-iframe").css("margin", "auto");
            }

            if(status && status.blockmap && status.blockmap.moduleRef) {
                status.blockmap.moduleRef.setAspectRatio(ar);
                status.seatmap.moduleRef.setAspectRatio(ar2);
    
                $("#view3d").css("height",$("#main-interface").innerHeight());
                status.view3d.moduleRef.fixedAspectRatio(false);
                status.view3d.moduleRef.responsive(true);
    
                if(status.blockmap.size === 'mini'){
                    const h = document.getElementById('minimap').offsetHeight;
                    const w = document.getElementById('minimap').offsetWidth;
                    status.blockmap.moduleRef['setAspectRatio'](h/w);
                }
            }
        }    

        if (global.isMobile() && window.innerWidth < 992) {
            $('#zoom_buttons_container').addClass('hidden');
            try {
                this.getHeight(status);
            } catch (e) {

            }
        }

        if(global.isMobile()){
            $('.interface').height(0);
            $("#search").attr("placeholder", "Enter section nº");
        } else {
            $("#search").attr("placeholder", "Enter section number");
        }
    }

    public getHeight(status) {
        if (!status.blockmap.moduleRef['getMapId']()) {
            return; 
        }

        var hh = 0;
        $("#zoom_buttons_container").addClass("hidden");
        $('.interface').height(0);
        hh = window.innerHeight - ($("#topbar").height() + $("#header-wrap").height() + $("#interface-btns-mobile").height());
        $('.interface').height(0);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const iframe = urlParams.get("iframe");

        if(iframe === "true"){
            hh = window.innerHeight - $("#topbar").height();
            $(".filt-btn").css("display", "block");
        }

        if(status.view3d.visible === true) {
            $("#main-interface").height(hh);
            $('#seatmap').height(0);
            $('#blockmap').height(0);
            $('#view3d').height($('#main-interface').height());
            status.view3d.moduleRef['fixedAspectRatio'](false);
            status.view3d.moduleRef['responsive'](true);
        } else if(status.blockmap.visible === true && status.blockmap.size === 'large'){
            $('#seatmap').height(0);
            $("#main-interface").height(hh);
            $('#blockmap').height($('#main-interface').height());
            var min_zoom = global.getMinZoom("blockmap");
            if(min_zoom){
                status.blockmap.moduleRef.setAspectRatio($('#blockmap').height()/$('#blockmap').width());
                status.blockmap.moduleRef.setMinZoom(min_zoom);
            }
        } else if(status.seatmap.visible === true && status.seatmap.size === 'large'){
            $('#blockmap').height(0);
            $("#main-interface").height(hh);
            $('#seatmap').height($('#main-interface').height());
            var min_zoom = global.getMinZoom("seatmap");
            if(min_zoom){
                status.seatmap.moduleRef.setAspectRatio($('#seatmap').height()/$('#seatmap').width());
                status.seatmap.moduleRef.setMinZoom(min_zoom);
            }
        } 
    }
}
