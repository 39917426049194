import { Component, OnInit, Input, Inject } from '@angular/core';
import { GeneralState, Tk3dService, InteractionMessageProtocol } from 'ngx-tk3d';
import { MINISITE_CONFIGURATION, MinisiteConfiguration } from '../minisite.configuration';
import { environment } from '@environment';
import * as global from '../services/global-functions';
import { Subject } from 'rxjs';
declare var $:any;

@Component({
  selector: 'app-header-interface',
  templateUrl: './header-interface.component.html',
  styleUrls: ['./header-interface.component.scss']
})
export class HeaderInterfaceComponent implements OnInit {

    @Input() state: GeneralState;

    search = this.minisite_config.searchbar;
    venueName = this.minisite_config.venueName;
    subtitleName = this.minisite_config.subtitleName ? this.minisite_config.subtitleName : "3D seatmap";
    imgUrl = this.minisite_config.minisiteUrl;
	environ = environment.url;
	logoHasUrl = this.minisite_config.logoUrl;
    txtHeader = this.minisite_config.txtHeader;
    bgButton = this.minisite_config.bgButton;
    buttonLab = this.minisite_config.buttonLab;
    buyTickets = this.minisite_config.buyTickets;
    ticketsUrl = this.minisite_config.ticketsUrl;
	logo = this.minisite_config.logo;
	logoUrl:any;
    buttonText = this.minisite_config.ticketsName;
    
    isInfoMenu = this.minisite_config.infoMenuCheck;
	infoMenuTitle = this.minisite_config.infoMenuTitle;
	sectionGroup = false;

    get menuoptions(){
        var menu = [];
    
        for(var i in this.minisite_config.infoMenuOptions){
            menu.push(this.minisite_config.infoMenuOptions[i])
        }
    
        return menu;
    }
    
    openInfoMenu(event){
        var element = event.currentTarget;
        let action = $(element).attr("data-action");
        if(action === 'open'){
            this.menuInfoOpen();
        }else{
            this.menuInfoClose();
        }
    }
    
    menuInfoOpen(){
        $("#menu-info").attr("data-status","opened");
        $("#menu-info-btn").attr("data-action","close");
    }
    
    menuInfoClose(){
        $("#menu-info").attr("data-status","closed");
        $("#menu-info-btn").attr("data-action","open");
    }

    searchTerm$ = new Subject<string>();
    count = 0;
	selection = [];

	get results() {
		this.selection = [];
		var searchVal = $("#search").val().toString().toLowerCase() ? $("#search").val().toString().toLowerCase() : $("#search-mobile").val().toString().toLowerCase();

		for(var k in global.blockhash) {
            if(global.blockhash[k].indexOf("G_") !== -1) {
                this.sectionGroup = true;
                break;
            } else if (global.blockhash[k].indexOf("S_") !== -1) {
                this.sectionGroup = false;
                break;
            } else if (global.blockhash[k].indexOf("V_") !== -1) {
                this.sectionGroup = false;
                break;
            }
		}

		if (searchVal !== "") {
			var result = [];
			// if(this.state.blockmap.moduleRef.getElementsByStatus("searched").length > 0) {
            //     for(var x = 0; x < this.state.blockmap.moduleRef.getElementsByStatus("searched").length; x++) {
            //         if (this.state.blockmap.moduleRef.getElementsByStatus("searched")[x].id.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1) {
            //             if(this.minisite_config.unavailable && this.minisite_config.unavailableOptionsJson[this.state.blockmap.moduleRef.getMapId()]['sections'].indexOf(this.state.blockmap.moduleRef.getElementsByStatus("searched")[x].id) === -1) {
            //                 this.selection.push(this.state.blockmap.moduleRef.getElementsByStatus("searched")[x].id);
            //             } else if(!this.minisite_config.unavailable){
            //                 this.selection.push(this.state.blockmap.moduleRef.getElementsByStatus("searched")[x].id);
            //             }
            //         }
            //     }
            // } else {
				for (var blockID in global.blockhash) {
					if (blockID.toLowerCase().indexOf(searchVal) !== -1) {
						result.push(blockID);
					}
				}
	
				for (var i = 0; i < result.length; ++i) {
					var sectionID = result[i];
					var areaID = global.blockhash[sectionID];
					if(this.minisite_config.unavailable && this.minisite_config.unavailableOptionsJson[this.state.blockmap.moduleRef.getMapId()]['sections'].indexOf(areaID) === -1) {
						this.selection.push(areaID);
					} else if(!this.minisite_config.unavailable){
						this.selection.push(areaID);
					}
				}
			//}

			this.selection.sort();

			if(this.count === 0){
				this.filterSectionOnBlockmap(this.selection);
			}

		} else {
			// try{
			// 	this.filterSectionOnBlockmap(null);
			// }catch {}
		}

		return this.selection;
	}

	searchElement(){
		var searchVal = $("#search").val().toString().toLowerCase() ? $("#search").val().toString().toLowerCase() : $("#search-mobile").val().toString().toLowerCase();
		if(searchVal === "") {
			this.state.blockmap.moduleRef.removeStatus(this.state.blockmap.moduleRef.getElementsByStatus("filtered"), "filtered");
			this.state.blockmap.moduleRef.setAvailability(this.state.blockmap.moduleRef.getAllElements());
		} 
	}

	locateSeatmap(id){
		this.resetSearch();
		$(".contact-btn").addClass("hidden");
		$(".interior").addClass("hidden");
		$(".interior-mobile").addClass("hidden");
		this.state.view3d.moduleRef.close();

		if(this.minisite_config.viewType === "seat") {
			if(this.exceptions && this.exceptions.indexOf(id) > -1){
				this.state.blockmap.selected = id;
				this.state.blockmap['size'] = 'mini';
				this.state.seatmap['size'] = 'mini';
				this.state.view3d['size'] = 'large';
				global.selectedBlockWrite(id);
				this.state.view3d.moduleRef['setContainer']('view3d');
				this.state.view3d.moduleRef['load'](id);
				this.state.view3d.visible = true;
				this.state.blockmap.moduleRef.setAvailability(true);
				this.state.blockmap.moduleRef.setElementAvailable(this.state.blockmap.selected);
				this.state.blockmap.moduleRef['disableZoom'](true);
			} else {
				this.state.blockmap.selected = id;
				global.selectedBlockWrite(id);
				this.state.seatmap.moduleRef.loadMap(id, () => {
                    const forcedMessage: InteractionMessageProtocol = {
                        event: 'loaded',
                        emmitterSize: 'large',
                        emitter: 'seatmap',
                        //element: {id:message.element.id, HTMLElement:null, status:null}
                    };
                    this.tk3d.handle(forcedMessage);
				});
				
				this.state.blockmap['size'] = 'mini';
                this.state.seatmap.visible = true;
                this.state.view3d['size'] = 'mini';
                $('#seatmap').removeClass("d-none");
			}
		} else {
			if(this.exceptions && this.exceptions.indexOf(id) > -1){
				this.state.blockmap.selected = id;
				global.selectedBlockWrite(id);
				this.state.seatmap.moduleRef.loadMap(id, () => {
                    const forcedMessage: InteractionMessageProtocol = {
                        event: 'loaded',
                        emmitterSize: 'large',
                        emitter: 'seatmap',
                        //element: {id:message.element.id, HTMLElement:null, status:null}
                    };
                    this.tk3d.handle(forcedMessage);
				});
				
				this.state.blockmap['size'] = 'mini';
                this.state.seatmap.visible = true;
                this.state.view3d['size'] = 'mini';
                $('#seatmap').removeClass("d-none");
			} else {
				this.state.blockmap.selected = id;
				this.state.blockmap['size'] = 'mini';
				this.state.seatmap['size'] = 'mini';
				this.state.view3d['size'] = 'large';
				global.selectedBlockWrite(id);
				this.state.view3d.moduleRef['setContainer']('view3d');
				this.state.view3d.moduleRef['load'](id);
				this.state.view3d.visible = true;
				this.state.blockmap.moduleRef.setAvailability(true);
				this.state.blockmap.moduleRef.setElementAvailable(this.state.blockmap.selected);
				this.state.blockmap.moduleRef['disableZoom'](true);
			}
		}
	}

	onClick(element) {
		this.locateSeatmap(element);
	}

	mouseEnter(element) {
		this.count = 1;
		this.filterSectionOnBlockmap(element);
	}

	mouseOut() {
		this.count = 0;
	}

	resetSearch(){
		this.selection = [];
		this.count = 0;
		$('.form-group input').blur();
		$('.form-group input').val('');
		this.filterSectionOnBlockmap(this.selection);
	}

	filterSectionOnBlockmap(section) {
		if(section) {
			this.state.blockmap.moduleRef.removeStatus(this.state.blockmap.moduleRef.getElementsByStatus("filtered"), "filtered");
			this.state.blockmap.moduleRef.addStatus(this.state.blockmap.moduleRef.getAllElements(), "filtered");
			this.state.blockmap.moduleRef.addStatus(this.state.blockmap.moduleRef.getAllElements(), "searched");
			this.state.blockmap.moduleRef.setAvailability(section);
		} else {
			this.state.blockmap.moduleRef.removeStatus(this.state.blockmap.moduleRef.getElementsByStatus("filtered"), "filtered");
			this.state.blockmap.moduleRef.removeStatus(this.state.blockmap.moduleRef.getElementsByStatus("searched"), "searched");
			this.state.blockmap.moduleRef.setAvailability(this.state.blockmap.moduleRef.getAllElements());
		}
	}

    constructor(private tk3d: Tk3dService, @Inject(MINISITE_CONFIGURATION) private minisite_config: MinisiteConfiguration) { 
    }

	exceptions;

    ngOnInit() {
		if(!this.logo && window.innerWidth > 780){
            $(".title-box").css("margin-left", "20px")
		}
		
		if(this.logo){
			if(this.minisite_config.logoType === "png") {
				this.logoUrl = `https://3ddigitalvenue.com/3dmap/${environment.url}/${this.imgUrl}/logo.png`;
			} else if (this.minisite_config.logoType === "svg") {
				this.logoUrl = `https://3ddigitalvenue.com/3dmap/${environment.url}/${this.imgUrl}/logo.svg`;
			} else {
				this.logoUrl = `https://3ddigitalvenue.com/3dmap/${environment.url}/${this.imgUrl}/logo.png`;
			}
        } else {
            $(".title-box").css("margin-left", "20px")
		}

		if(this.minisite_config.exceptionsJsonCheck) {
            this.exceptions = this.minisite_config.exceptionsJson['sections'];
        } else {
            this.exceptions = this.minisite_config.exceptions;
        }

		document.documentElement.style.setProperty('--text-header', this.minisite_config.txtHeader);

    }

}
