import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { config } from './app-routing.config';


@NgModule({
  imports: [
    UIRouterModule.forRoot(config),
  ],
  exports: [UIRouterModule]
})
export class AppRoutingModule { }
