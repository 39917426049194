import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, FactoryProvider } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing';
import { Tk3dService, TK3D_HANDLERS, Tk3dModule, ResizeService } from 'ngx-tk3d';
import { Tk3dConfiguration } from './app.configuration';
import { BottomInterfaceComponent } from './bottom-interface/bottom-interface.component';
import { HeaderInterfaceComponent } from './header-interface/header-interface.component';
import { SearchbarInterfaceComponent } from './header-interface/searchbar-interface/searchbar-interface.component';
import { MainInterfaceComponent } from './main-interface/main-interface.component';
import { FiltersInterfaceComponent } from './main-interface/filters-interface/filters-interface.component';
import { MinimapInterfaceComponent } from './main-interface/minimap-interface/minimap-interface.component';
import { TopbarInterfaceComponent } from './topbar-interface/topbar-interface.component';
import { MinisiteFileConfigurationService, MINISITE_CONFIGURATION, minisiteConfig } from './minisite.configuration';
import { OnLoadMapHandler } from './handlers/onloadmap.handler';
import { BlockClickAppHandler } from './handlers/block-click.handler';
import { SeatClickAppHandler } from './handlers/seat-click.handler';
import { NeighbourClickAppHandler } from './handlers/neighbour-click.handler';
import { BlockmapMiniClickAppHandler } from './handlers/blockmap-mini-click.handler';
import { SeatmapMiniClickAppHandler } from './handlers/seatmap-mini-click.handler';
import { PopoverCreate } from './handlers/popovers-create.handler';
import { PopoverDestroy } from './handlers/popovers-destroy.handler';
import { ResizeNormalService } from './services/resize-normal.service';
import { HttpClientModule } from '@angular/common/http';
import { TK3DComponent } from './tk3d/tk3d.component';
import { DVMComponent } from './dvm/dvm.component';
import { HeaderDvmComponent } from './dvm/header-dvm/header-dvm.component';
import { InterfaceDvmComponent } from './dvm/interface-dvm/interface-dvm.component';
import { TopbarDvmComponent } from './dvm/topbar-dvm/topbar-dvm.component';
import { DVMService } from './dvm/dvm.service';
import { PopoverService } from './dvm/popover.service';
import { FooterDvmComponent } from './dvm/footer-dvm/footer-dvm.component';
import { MinisiteErrorComponent } from './minisite-error/minisite-error.component';


@NgModule({
  declarations: [
    AppComponent,
    BottomInterfaceComponent,
    HeaderInterfaceComponent,
    SearchbarInterfaceComponent,
    MainInterfaceComponent,
    FiltersInterfaceComponent,
    MinimapInterfaceComponent,
    TopbarInterfaceComponent,
    TK3DComponent,
    DVMComponent,
    HeaderDvmComponent,
    InterfaceDvmComponent,
    TopbarDvmComponent,
    FooterDvmComponent,
    MinisiteErrorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    Tk3dModule.forRoot(Tk3dConfiguration),
  ],
  providers: [
    Title,
    Tk3dService,
    DVMService,
    PopoverService,
    MinimapInterfaceComponent,
    {provide: TK3D_HANDLERS, multi: true, useClass: OnLoadMapHandler},
    {provide: TK3D_HANDLERS, multi: true, useClass: BlockClickAppHandler},
    {provide: TK3D_HANDLERS, multi: true, useClass: SeatClickAppHandler},
    {provide: TK3D_HANDLERS, multi: true, useClass: BlockmapMiniClickAppHandler},
    {provide: TK3D_HANDLERS, multi: true, useClass: SeatmapMiniClickAppHandler},
    {provide: TK3D_HANDLERS, multi: true, useClass: PopoverCreate},
    {provide: TK3D_HANDLERS, multi: true, useClass: PopoverDestroy},
    {provide: TK3D_HANDLERS, multi: true, useClass: NeighbourClickAppHandler},
    {provide: ResizeService, useClass: ResizeNormalService},
    {provide: MINISITE_CONFIGURATION, useValue: minisiteConfig},
    MinisiteFileConfigurationService,
    { provide: APP_INITIALIZER, useFactory: (configService: MinisiteFileConfigurationService)=>
      () => configService.configureFromFile(), deps: [MinisiteFileConfigurationService], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
