import { Component, OnInit, Input, Inject, Injector } from '@angular/core';
import { Tk3dService, TK3D_CONFIG, Tk3dConfig, InteractionMessageProtocol } from 'ngx-tk3d';
import { MINISITE_CONFIGURATION, MinisiteConfiguration } from '../minisite.configuration';
import * as global from '../services/global-functions';
import { MinimapInterfaceComponent } from '../main-interface/minimap-interface/minimap-interface.component';
import { MinimapService } from '../main-interface/minimap-interface/minimap-interface.service';
declare var $:any;

@Component({
  selector: 'app-topbar-interface',
  templateUrl: './topbar-interface.component.html',
  styleUrls: ['./topbar-interface.component.scss']
})
export class TopbarInterfaceComponent implements OnInit {

    @Input() status;
    filters: boolean = this.minisite_config.filters;
    tabs: boolean = this.minisite_config.tabs;
    bgTopbar = this.minisite_config.bgTopbar;
    ticketVal = this.minisite_config.ticketVal;
    ticketLab = this.minisite_config.ticketLab;
    minimapComponent;
    neighbours = this.minisite_config.neighbours;
    leftRight = this.minisite_config.leftRight;

    onClickPrev(){
        $(".rows").addClass("d-none");
        $(".seat").addClass("d-none");
        var idPrev = this.status.seatmap.moduleRef.getNeighbors().l;
        if(idPrev !== "none") {
            if(this.minisite_config.unavailable && this.minisite_config.unavailableOptionsJson[this.status.blockmap.moduleRef.getMapId()]['sections'].indexOf(idPrev) === -1){
                const forcedMessage: InteractionMessageProtocol = {
                    event: 'clickNeighbor',
                    emmitterSize: 'large',
                    emitter: 'seatmap',
                    element: idPrev
                };
                this.tk3d.handle(forcedMessage);
            } else if(!this.minisite_config.unavailable){
                const forcedMessage: InteractionMessageProtocol = {
                    event: 'clickNeighbor',
                    emmitterSize: 'large',
                    emitter: 'seatmap',
                    element: idPrev
                };
                this.tk3d.handle(forcedMessage);
            } else {
                $("#modal-message-prev").modal('show');
            }
        } else {
            $("#modal-message-prev").modal('show');
        }
    }

    onClickNext(){
        $(".rows").addClass("d-none");
        $(".seat").addClass("d-none");
        var idNext = this.status.seatmap.moduleRef.getNeighbors().r;
        if(idNext !== "none") {
            if(this.minisite_config.unavailable && this.minisite_config.unavailableOptionsJson[this.status.blockmap.moduleRef.getMapId()]['sections'].indexOf(idNext) === -1){
                const forcedMessage: InteractionMessageProtocol = {
                    event: 'clickNeighbor',
                    emmitterSize: 'large',
                    emitter: 'seatmap',
                    element: idNext
                };
                this.tk3d.handle(forcedMessage);
            } else if(!this.minisite_config.unavailable) {
                const forcedMessage: InteractionMessageProtocol = {
                    event: 'clickNeighbor',
                    emmitterSize: 'large',
                    emitter: 'seatmap',
                    element: idNext
                };
                this.tk3d.handle(forcedMessage);
            } else {
                $("#modal-message-next").modal('show');
            }
        } else {
            $("#modal-message-next").modal('show');
        }

    }
    
    public filteringDisplay(event) {
        var element = event.currentTarget;
        let action = $(element).attr("data-action");
        if(action === 'open'){
            this.menuOpen();
        }else{
            this.menuClose();
        }
        return null;
    }

    menuOpen(){
        $("#menu").attr("data-status","opened");
        $(".filt-btn").attr("data-action","close");
        $(".filt-btn").find("i").removeClass("icon-menu").addClass("icon-close");
        
        return null;
        
    }

    menuClose(){
        $("#menu").attr("data-status","closed");
        $(".filt-btn").attr("data-action","open");
        $(".filt-btn").find("i").removeClass("icon-close").addClass("icon-menu");
        return null;
    }

    public isSectionHidden() {
        var sectionH;

        if(this.status.blockmap.visible === true && this.status.blockmap.size === 'large') {
            if(global.interfaces.topview.nodes_selected.length > 0){
                sectionH = false;
            } else {
                sectionH = true;
            }
        } else if (this.status.seatmap.visible === true || this.status.view3d.visible === true) {
            sectionH = false;
        }

        return sectionH;
    }

    public isRowSeatHidden() {
        var rowSeatH = true;

        if(this.status.blockmap.visible === true && this.status.blockmap.size === 'large') {
            rowSeatH = true;
        } else if (this.status.seatmap.visible === true && this.status.seatmap.size === 'large') {
            if(global.interfaces.seatmap.nodes_selected.length > 0){
                rowSeatH = false;
            } else {
                rowSeatH = true;
            }
        } else if (this.status.view3d.visible === true) {
            if(this.minisite_config.viewType === "seat") {
                if(this.exceptions && this.exceptions.indexOf(this.status.blockmap.selected) !== -1){
                    rowSeatH = true;
                } else {
                    rowSeatH = false;
                }
            } else {
                if(this.exceptions && this.exceptions.indexOf(this.status.blockmap.selected) !== -1){
                    rowSeatH = false;
                } else {
                    rowSeatH = true;
                }
            }
            
        }

        return rowSeatH;
    }

    get options(){
        var option = [];

        for(var i in this.minisite_config.tabOptionsJson) {
            option.push(this.minisite_config.tabOptionsJson[i]);
        }

        return option;
    }

    public openOption(option, index) {
        if(!$("#option-button-"+index).hasClass("active")){
            $(".mapstyle-button").removeClass("unactive");
            $(".mapstyle-button").removeClass("active");
            $("#option-button-"+index).addClass("active");
        }
        $(".ticket-selected").addClass("hidden");
        $("#topbar").css("background",option.topbarColor);
        $(".ticket-lab").css("color", option.textColor);
        $(".ticket-val").css("color", option.idColor);
        this.tk3d_config.venue = option.venueid;
        this.tk3d_config.mapId = option.mapid;
        this.tk3d.changeVenue(option.venueid);
        this.minimapService.changeVenue(option.venueid, option.minimapid);
    }

    constructor(private tk3d: Tk3dService, @Inject(TK3D_CONFIG) private tk3d_config: Tk3dConfig, 
    @Inject(MINISITE_CONFIGURATION) private minisite_config: MinisiteConfiguration, private minimapService: MinimapService) { 
        this.filters = this.minisite_config.filters;
        this.tabs = this.minisite_config.tabs;
    }

    exceptions;

    ngOnInit() {
		if(this.minisite_config.exceptionsJsonCheck) {
            this.exceptions = this.minisite_config.exceptionsJson['sections'];
        } else {
            this.exceptions = this.minisite_config.exceptions;
        }

        if(this.minisite_config.filteringJson){
            var bc_val;
            for(var i = 0; i < this.minisite_config.filteringJson.filtering.length; i++) {
                if(this.minisite_config.filteringJson.filtering[i].title === "General Overview"){
                    bc_val = "<span class='breadcrumbs-atitle' style='width:auto; max-width:111px; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;'>General Overview</span>";
                }
            }
            $(".breadcrumbs-val").html(bc_val);
        }

        if(this.minisite_config.ticketVal) {
            document.documentElement.style.setProperty('--text-color', this.minisite_config.ticketVal);
        } else {
            document.documentElement.style.setProperty('--text-color', "#fff");
        }
      
        if(this.minisite_config.ticketLab) {
            document.documentElement.style.setProperty('--label-color', this.minisite_config.ticketLab);
        } else {
            document.documentElement.style.setProperty('--label-color', "#fff");
        }
    }
}
