import { Component, OnInit, Input, Inject } from '@angular/core';
import { Tk3dService, GeneralState, TK3D_CONFIG, Tk3dConfig, InteractionMessageProtocol } from 'ngx-tk3d';
import { MINISITE_CONFIGURATION, MinisiteConfiguration } from '../minisite.configuration';
declare var $:any;
import * as global from '../services/global-functions'
import { MinimapService } from './minimap-interface/minimap-interface.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-main-interface',
  templateUrl: './main-interface.component.html',
  styleUrls: ['./main-interface.component.scss']
})
export class MainInterfaceComponent implements OnInit {

    @Input() state;
    @Input() selectedSeats: Object[];
    
    filtersTrue = this.minisite_config.filters;
    hasMinimap = this.minisite_config.hasMinimap;
    tabsManual = this.minisite_config.tabsManual;
    copyLink = this.minisite_config.copyLinkCheck;

    isStep1() {
        return this.status.blockmap.visible && this.status.blockmap.size === 'large';
    }

    isStep2() {
        return this.status.seatmap.visible && this.status.seatmap.size === 'large';
    }

    isStep3 () {
        return this.status.view3d.visible;
    }

    public tk3dBack() {
        if (this.status.view3d.visible) {
            if(this.minisite_config.viewType === "section") {
                if(this.exceptions && this.exceptions.indexOf(this.state.blockmap.selected) > -1) {
                    this.tk3d.onSeatMapMiniClick();
                } else {
                    this.tk3d.onBlockMapMiniClick();
                }
            } else {
                if(this.exceptions && this.exceptions.indexOf(this.state.blockmap.selected) > -1) {
                    this.tk3d.onBlockMapMiniClick();
                } else {
                    this.tk3d.onSeatMapMiniClick();
                }
            } 
        } else if(this.status.seatmap.visible){
            //$('#seatmap').height(0);
            this.tk3d.onBlockMapMiniClick();
        }
    }

    public goHome() {
        this.tk3d.onBlockMapMiniClick();
    }

    get status(): GeneralState {
        return this.tk3d.tk3dStatus;
    }

    get sector() {
        return (<string>this.state.blockmap.selected).split('_')[1];
    }

    get seat() {
        if (this.state.seatmap.selected.length === 0) { return undefined; }
        return this.state.seatmap.selected[0];
    }

    public isTicketSelectedVisible() {
        return this.state.blockmap.selected || this.state.seatmap.selected.length > 0;
    }

    public isMobile(){
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        return true;
        }
        else{
        return false;
        }
    }

    toggleFullscreen() {
        this.state.view3d.moduleRef.toggleFullscreen();

        $("#ticketing3dwidget-fullscreen-button0").addClass("d-none");
        
        if(!$("#ticketing3dwidget-fullscreen-button0").hasClass("onfullscreen-tk3d")) {
            $("#ticketing3dwidget0").append($("#main-tk3d").removeClass("d-none"))
        } else {
            $("#main-tk3d").addClass("d-none");
        }
    }

    dvmNavigationGo() {
        var view3d = this.state.view3d.moduleRef;
        var blockmap = this.state.blockmap.moduleRef;
        global.dvmNavigationGo(view3d,blockmap);
    }

    interfaceShare() {
        $(".custom-modal").toggleClass("d-none");
        $(".share-btn").toggleClass("active-btn");
    
        if (!$(".custom-modal").hasClass("active-btn")) {
            $(".confirmcopy").addClass("d-none");
            $(".linktoshare-input").removeClass("d-none");
            $(".copybtn").removeClass("d-none");
        } else {
            $(".confirmcopy").removeClass("d-none");
            $(".linktoshare-input").addClass("d-none");
            $(".copybtn").addClass("d-none");
        }
    }

    copyShareLink() {
        /* Get the text field */
        var copyText = null;
      
        if (!this.isMobile()) {
          copyText = document.getElementById("linktoshare");
        } else {
          copyText = document.getElementById("linktoshare-mbl");
        } // console.log(copyText);
      
        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        /* For mobile devices */
      
        /* Copy the text inside the text field */
      
        document.execCommand("copy");
        /* Alert the copied text */
      
        $(".confirmcopy").removeClass("d-none");
        $(".linktoshare-input").addClass("d-none");
        $(".copybtn").addClass("d-none");
    }

    // changeMinisiteTopview(option, index) {
    //     if(!$(".option-tab-"+index).hasClass("active")){
    //         $(".option-tab").removeClass("active");
    //         $(".option-tab-"+index).addClass("active");
    //         $(".option-img").removeClass("active");
    //         $(".option-img-"+index).addClass("active");
    //     }
        
    //     $(".ticket-selected").addClass("hidden");
    //     $("#topbar").css("background",option.topbarColor);
    //     $(".ticket-lab").css("color", option.textColor);
    //     $(".ticket-val").css("color", option.idColor);
    //     $(".main-logo").attr("src",option.imgLogoactive);
    //     $(".subtitle").text(option.name);

    //     if(this.state.blockmap && this.state.blockmap.visible === true && this.state.blockmap.size === 'large'){
    //         this.tk3d_config.venue = option.venueid;
    //         this.tk3d_config.mapId = option.mapid;
    //         this.tk3d.changeVenue(option.venueid);
    //         if(this.minisite_config.hasMinimap){
    //             this.minimapService.changeVenue(option.venueid, option.minimapid);
    //         }
    //     } 
    //     // else if(this.state.seatmap && this.state.seatmap.visible === true && this.state.seatmap.size === 'large'){
    //     //     this.tk3d_config.venue = option.venueid;
    //     //     this.tk3d_config.mapId = this.state.seatmap.moduleRef.getMapId();
    //     //     //this.tk3d.changeVenue(option.venueid);
            
    //     //     this.state.seatmap.moduleRef.loadMap(this.state.seatmap.moduleRef.getMapId(), () => {
    //     //         const forcedMessage: InteractionMessageProtocol = {
    //     //             event: 'loaded',
    //     //             emmitterSize: 'large',
    //     //             emitter: 'seatmap',
    //     //             //element: {id:message.element.id, HTMLElement:null, status:null}
    //     //         };
    //     //         //this.state.seatmap.moduleRef.setContainer('seatmap');
    //     //         this.tk3d.handle(forcedMessage);
    //     //     });

    //     //     if(this.minisite_config.hasMinimap){
    //     //         this.minimapService.changeVenue(option.venueid, option.minimapid);
    //     //     }
    //     // } 
    //     else if(this.state.view3d && this.state.view3d.visible === true && this.state.view3d.size === 'large'){
    //         this.tk3d_config.venue = option.venueid;
    //         this.tk3d_config.mapId = this.state.seatmap.moduleRef.getMapId();
    //         this.state.view3d.moduleRef.changeVenue(option.venueid);

    //         $(".popover").each(function(){
    //             $(this).popover("dispose").remove();
    //         });

    //         this.state.view3d.moduleRef.load(this.state.seatmap.moduleRef.getMapId());
    //         if(this.minisite_config.hasMinimap){
    //             this.minimapService.changeVenue(option.venueid, option.minimapid);
    //             this.minimapService.setAvailability(this.state.seatmap.moduleRef.getMapId());
    //         }
    //         this.state.view3d.visible = true;
    //     }
    // }

    // opt = [];

    // get options(){
    //     return this.opt;
    // }

    constructor(private sanitization:DomSanitizer, private tk3d: Tk3dService, @Inject(TK3D_CONFIG) private tk3d_config: Tk3dConfig,
        @Inject(MINISITE_CONFIGURATION) private minisite_config: MinisiteConfiguration, private minimapService: MinimapService) { 
    }

    exceptions;

    ngOnInit() {
        // if(this.minisite_config.tabs || this.minisite_config.tabsManual){
        //     for(var i in this.minisite_config.tabOptionsJson) {
        //         this.minisite_config.tabOptionsJson[i].imgActive = this.sanitization.bypassSecurityTrustUrl(this.minisite_config.tabOptionsJson[i].imgActive)
        //         this.opt.push(this.minisite_config.tabOptionsJson[i]);
        //     }
        // }

        if(global.interfaces.params.v360) {
            var h;
            h = document.getElementById('main-interface').offsetHeight;
            document.getElementById('view3d').style.height = h + 'px';
            this.state.view3d.moduleRef['setContainer']('view3d');
            this.state.view3d.moduleRef['load'](global.interfaces.params.v360);
        }

        if(this.minisite_config.exceptionsJsonCheck) {
            this.exceptions = this.minisite_config.exceptionsJson['sections'];
        } else {
            this.exceptions = this.minisite_config.exceptions;
        }

        $(".menu-tk3d").css("max-height", document.getElementById("main-interface").style.height)
    }

}
