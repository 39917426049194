import {Injectable, Inject} from '@angular/core';
import {Handler, GeneralState, InteractionMessageProtocol} from 'ngx-tk3d';
import * as globals from '../services/global-functions';
import { MinisiteConfiguration, MINISITE_CONFIGURATION } from '../minisite.configuration';
import { ResizeNormalService } from '../services/resize-normal.service';
declare var $:any;


@Injectable()
export class SeatmapMiniClickAppHandler extends Handler {

    block; h;

    constructor(private resizes: ResizeNormalService, @Inject(MINISITE_CONFIGURATION) private minisite_config: MinisiteConfiguration) {
        super();
        this.handling['seatmap'] = ['click'];
    }

    handle(message: InteractionMessageProtocol, status: GeneralState) {
        if (message.emmitterSize !== 'mini') { return; }
        status.seatmap.size = 'large';

        if(!globals.isMobile()) {
            status.seatmap.moduleRef['setZoom'](1);
            status.seatmap.moduleRef['setMaxZoom'](6);
            status.seatmap.moduleRef['setMinZoom'](1);
            status.seatmap.moduleRef['enableZoom']();
            if(this.minisite_config.hasMinimap) {
                globals.showMinimap(this.resizes.minimapModule);
            }
        }

        status.view3d.visible = false;
        status.view3d['size'] = 'mini';
        this.block = status.blockmap.selected;
        message.emitter = 'seatmap';
        status.resizeForce = true;

        if(this.minisite_config.neighbours && this.minisite_config.neighboursOptionsJson[status.seatmap.moduleRef.getMapId()]) {
            $(".neighbour-lab").removeClass("d-none");
        } else {
            $(".neighbour-lab").addClass("d-none");
        }
        
        if(globals.interfaces.seatmap.nodes_selected.length > 0) {
            //status.seatmap.moduleRef.setAvailability(globals.interfaces.seatmap.nodes_selected);
            $(".rows").removeClass("d-none");
            $(".seat").removeClass("d-none");
            $(".row-val").text(globals.interfaces.seatmap.nodes_selected[0].split("-")[1]);
            $(".seat-val").text(globals.interfaces.seatmap.nodes_selected[0].split("-")[2]);
        } else {
            status.seatmap.moduleRef.setAvailability(false);
        }

        globals.interfaceNavigationHide();

        if(this.minisite_config.unavailable) {
            status.seatmap.moduleRef.setElementUnavailable(this.minisite_config.unavailableOptionsJson[status.blockmap.moduleRef.getMapId()]['seats']);
        }
        //$("#view3d-box-view").addClass("d-none");
        status.seatmap.visible = true;
        status.view3d.moduleRef.close();
    }
}
