import { Injectable, Inject } from '@angular/core';
declare var DVM: any;
declare var TICKETING3D: any;
declare var $:any;
import { PopoverService } from './popover.service.js';
import { MinisiteConfiguration, MINISITE_CONFIGURATION } from 'src/app/minisite.configuration';
import { DomSanitizer } from '@angular/platform-browser';


@Injectable({
    providedIn: 'root'
})
export class DVMService {
    viewer_promise:any;
    viewer_mini_promise: any;
    viewer3d_promise:any;
    viewer: any;
    viewer_mini:any;
    viewer_3d:v3d;
    viewer_mini_bumper: any;
    minimap_load: any;
    integrationType: string = this.config.integrationType;
    venue_id: string = this.config.venueID;
    map_id: string = this.config.mapID;
    map_id_mini: string = this.config.minimapID;
    exceptions = this.config.exceptionsJsonCheck ? this.config.exceptionsJson['sections'] : this.config.exceptions;
    libraryVersion:string = this.config.libraryVersion ? this.config.libraryVersion : 'stable';
    filtering: any = this.config.filteringJson;
    navigation: any = this.config.navigationJson;
    viewerVersion = this.config.viewerVersion;

    current_view = 'topview';
    popover_timeout = null;

    actualVenue:string = this.config.venueID ? this.config.venueID : "";
    actualMap:string = this.config.mapID ? this.config.mapID : "";

    instance_options = {
        version: this.libraryVersion,
        plugins:['best_nodes','navigable_minimap'],
        container: "viewer",
        new_styles: true,
        callbacks: {
            click: result => this.onClick(result),
            enter: e => {
                var node = e.nodes[0];
                if(!this.interfaceIsMobile() && node && node.state !== 'unavailable'){
                    this.popover_timeout = setTimeout(() => {
                        this.popoverCreate(node);
                        this.popover_timeout = null;
                    },100);
                }
            },
            leave: e => { 
                if(!this.interfaceIsMobile()) {
                    if(this.popover_timeout) {
                        clearTimeout(this.popover_timeout);
                        this.popover_timeout = null;
                    }
                    else{
                        this.popoverService.popoverDestroyAll();
                    } 
                }   
            }
        }
    };

    instance_options_mini = {
        container: "blockmap-mini"
    };

    windowLoop = windowLoop.bind(this);

    interface = {
        current:"topview",
        seatmap:{
            nodes_selected: []
        },
        topview:{
            nodes_selected: []
        },
        filtering:{
            ref : null,
            nodes : {
                sections: [],
                rows: [],
                seats: []
            }
        },
        navigation:{
            to: "",
            from: ""
        },
        window:{
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight,
            animation: requestAnimationFrame(this.windowLoop)
        },
        params: this.interfaceGetParams(window.location.href)
    };

    interfaceGetParams(url) {
        var params = {v360:"", search:""};
        var parser = document.createElement('a');
        parser.href = url;
        var query = parser.search.substring(1);
        var vars = query.split('&');
      
        for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split('=');
          params[pair[0]] = decodeURIComponent(pair[1]);
        }
      
        if (params.v360) {
          params.v360 = atob(params.v360);
        }
      
        if (params.search) {
          params.search = atob(params.search);
        }
      
        return params;
    }

    interfaceChangeView(option, index){
        if(this.list.length === 0 && this.current_view != 'view3d') {
            if(!$(".option-tab-"+index).hasClass("active")){
                $(".option-tab").removeClass("active");
                $(".option-tab-"+index).addClass("active");
                $(".option-img").removeClass("active");
                $(".option-img-"+index).addClass("active");
            }
    
            $(".ticket-selected").addClass("hidden");
            $("#topbar").css("background",option.topbarColor);
            $(".ticket-lab").css("color", option.textColor);
            $(".ticket-val").css("color", option.idColor);
            $(".main-logo").attr("src",option.imgLogoactive);
            $(".subtitle").text(option.name);
            $("body").css("background-image","url("+option.imgBgactive+")");
            this.viewer_3d.close();
            this.config.venueID = option.venueid;
            this.config.mapID = option.mapid;
            this.config.minimapID = option.minimapid;
            this.changeVenue(option.venueid, option.mapid, option.minimapid);

            this.dvmStart();
        } else if(this.list.length > 0 && this.current_view != 'view3d') {
            if(this.viewer.getNodesByType("section").indexOf(this.viewer.getMapId()) !== -1) {
                if(!$(".option-tab-"+index).hasClass("active")){
                    $(".option-tab").removeClass("active");
                    $(".option-tab-"+index).addClass("active");
                    $(".option-img").removeClass("active");
                    $(".option-img-"+index).addClass("active");
                }
        
                $(".ticket-selected").addClass("hidden");
                $("#topbar").css("background",option.topbarColor);
                $(".ticket-lab").css("color", option.textColor);
                $(".ticket-val").css("color", option.idColor);
                $(".main-logo").attr("src",option.imgLogoactive);
                $(".subtitle").text(option.name);
                $("body").css("background-image","url("+option.imgBgactive+")");
                this.viewer_3d.close();
                this.config.venueID = option.venueid;
                this.config.mapID = option.mapid;
                this.config.minimapID = option.minimapid;
                this.changeVenue(option.venueid, option.mapid, option.minimapid);
    
    
                this.viewer.loadMap({
                    venue_id: option.venueid,
                    map_id: this.viewer.getMapId()
                })
                .then(result => this.onLoadD2MSuccess(result))
                .catch(err => this.onLoadD2MError(err));
            } else {
                $("#modal-message").modal('show');
            }

        } else if(this.list.length > 0 && this.current_view == 'view3d') {
            var obj = {venue_id: option.venueid, view_id : this.pano}
            this.viewer_3d.checkView3d(obj)
                .then((exists) => {
                    if(exists){
                        if(!$(".option-tab-"+index).hasClass("active")){
                            $(".option-tab").removeClass("active");
                            $(".option-tab-"+index).addClass("active");
                            $(".option-img").removeClass("active");
                            $(".option-img-"+index).addClass("active");
                        }
                
                        $(".ticket-selected").addClass("hidden");
                        $("#topbar").css("background",option.topbarColor);
                        $(".ticket-lab").css("color", option.textColor);
                        $(".ticket-val").css("color", option.idColor);
                        $(".main-logo").attr("src",option.imgLogoactive);
                        $(".subtitle").text(option.name);
                        $("body").css("background-image","url("+option.imgBgactive+")");
                        this.viewer_3d.close();
                        this.config.venueID = option.venueid;
                        this.config.mapID = option.mapid;
                        this.config.minimapID = option.minimapid;
                        this.changeVenue(option.venueid, option.mapid, option.minimapid);

                        this.venue_id = option.venueid;

                        if(this.integrationType === "dvm" && this.config.hasMinimap) {
                            this.viewer_mini.loadMap({ venue_id: this.venue_id, map_id: this.map_id_mini })
                                .then(result => this.onLoadMinimapSuccess(result))
                                .catch(err => this.onLoadD2MError(err));
                        }

                        var node = {
                            id: this.pano,
                            type: 'view3d'
                        }

                        this.dvmLoadViewer3D(node);
                    } else {
                        $("#modal-message").modal('show');
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }

    changeVenue(venueID:string, mapID:string, minimapID:string) {
        if(this.viewer){
            this.viewer.close();
        }

        if(this.viewer_mini) {
            this.viewer_mini.close();
        }

        if(this.viewer_mini_bumper) {
            this.viewer_mini_bumper.close();
        }

        this.venue_id = venueID;
        this.map_id = mapID;
        this.map_id_mini = minimapID;
        this.actualVenue = venueID;
        this.actualMap = mapID;

        //this.getMenuOptions();
    }

    getMenuOptions(){
        if(this.config.tabs) {
            for(var i in this.config.infoMenuOptions) {
                if(this.config.infoMenuOptions[i].venueid === this.venue_id && this.config.infoMenuOptions[i].mapid === this.map_id) {
                    // this.infoMenuOptions = this.config.infoMenuOptions[i].list;
                    // this.infoMenuTitle = this.config.infoMenuOptions[i].title;
                    return this.config.infoMenuOptions[i];
                }
            }
        } else {
            return this.config.infoMenuOptions;
            //this.infoMenuOptions = this.config.infoMenuOptions;
        }
      }

    changeSeatmap(node, section){
        var left = this.config.neighboursOptionsJson[this.viewer.getMapId()].l.split("_")[1];
        var right = this.config.neighboursOptionsJson[this.viewer.getMapId()].r.split("_")[1];

        if(node !== "none"){
            this.interface.topview.nodes_selected[0].id = node;
            // if(this.config.unavailable && this.config.unavailableOptionsJson[this.viewer.getMapId()]['sections'].indexOf(node) === -1) {
            //     this.viewer.loadMap({ venue_id: this.venue_id, map_id: node })
            //     .then(result => this.onLoadD2MSuccess(result))
            //     .catch(err => this.onLoadD2MError(err));
            
            //     this.viewer_mini.unselectAll();
            //     this.viewer_mini.select(node);
            //     this.viewer_mini.goTo(node,this.viewer_mini.min_scaling_factor*2.5,1000);
        
            //     this.actualMap = node;
            // } else if(!this.config.unavailable){
                $(".rows").addClass("d-none");
                $(".seat").addClass("d-none");
                this.viewer.loadMap({ venue_id: this.venue_id, map_id: node })
                .then(result => this.onLoadD2MSuccess(result))
                .catch(err => this.onLoadD2MError(err));
            
                this.viewer_mini.unselectAll();
                this.viewer_mini.select(node);
                this.viewer_mini.goTo(node,this.viewer_mini.min_scaling_factor*2.5,1000);
        
                this.actualMap = node;
            // } else if(section === "prev"){
            //     $("#modal-message-prev").modal('show');
            // } else if(section === "next") {
            //     $("#modal-message-next").modal('show');
            // }
        } else if(section === "prev"){
            $(".prev-neighbour").css("display", "none");
            $(".fa-angle-double-left").css("display", "none");
        } else if(section === "next") {
            $(".next-neighbour").css("display", "none");
            $(".fa-angle-double-right").css("display", "none");
        }
    }

    changeJson(filtering, navigation) {
        this.filtering = filtering;
        this.navigation = navigation;
    }

    FLAG;

    loadMap(){
        this.viewer_promise = DVM.loadModule("map_viewer", this.instance_options);

        if(this.integrationType === "dvm") {
            this.viewer_mini_promise = DVM.loadModule("map_viewer", this.instance_options_mini);
        }

        this.FLAG = this.config.viewerIntegration;

        if(this.FLAG === 'new') {
            this.viewer_3d = new newViewer(this.config);
        } else {
            this.viewer_3d = new oldViewer();
        }

        this.viewer3d_promise = this.viewer_3d.init(this.venue_id);
        
        Promise.all([this.viewer_promise, this.viewer_mini_promise, this.viewer3d_promise, this.filtering, this.navigation])
        .then((result) => {
            this.viewer = result[0];

            this.filtering = result[3];
            this.navigation = result[4];

            var mini_opts = {
                container: "blockmap-mini",
                callbacks: {
                    end_load: res => this.endLoad()
                }
            }

            if(this.integrationType === "dvm") {
                this.viewer_mini = result[1];
            } else {
                $(".minimap").css("background-color","#eee");
                this.viewer.navigable_minimap.createNavigableMinimap(mini_opts)
                    .then((m) => {
                        this.viewer_mini_bumper = m;
                        (window as any).viewer_mini_bumper = this.viewer_mini_bumper;
                    });
            }

            this.dvmBindInterfaceEvents();
            this.dvmSetInitialFlags();
            this.dvmStart();
        })
        .catch((err) => {
            console.error(err);
        });

        this.windowLoop();
    }

    endLoad(){
        this.viewer_mini_bumper.flags.fixed_aspect_ratio = false;
        this.viewer_mini_bumper.setAvailability("section", this.viewer_mini_bumper.getNodesByType("section"));
    }

    dvmStart(){
        if (this.interface.params.v360) {
            var node = {
                id: this.interface.params.v360
            };
            this.dvmLoadViewer3D(node);
            this.interfacesetViewer3D(node);
        } else if(this.config.onlyViewer) {
            var node = {
                id: this.map_id
            };
            this.dvmLoadViewer3D(node);
            this.interfacesetViewer3D(node);
        } else {
            this.viewer.loadMap({ venue_id: this.venue_id, map_id: this.map_id })
                .then(result => this.onLoadD2MSuccess(result))
                .catch(err => this.onLoadD2MError(err));
        }

        if(this.integrationType === "dvm" && this.config.hasMinimap) {
            this.minimap_load = this.viewer_mini.loadMap({ venue_id: this.venue_id, map_id: this.map_id_mini })
                .then(result => this.onLoadMinimapSuccess(result))
                .catch(err => this.onLoadD2MError(err));
        }
            
        return null;
    }

    array;

    dvmStartFiltering(venue?, map?, minimap?, nodes?, unavailable?){
        if(venue !== this.actualVenue || map !== this.actualMap) {
            this.array = {};
            this.interface.filtering.nodes = nodes;
            this.array = {venue:venue, map:map, minimap:minimap, nodes:nodes}
            if(document.getElementById("accordionContainer")) {
                for(var q = 0; q < document.getElementById("accordionContainer").querySelectorAll('button').length; q++) {
                    document.getElementById("accordionContainer").querySelectorAll('button')[q].classList.remove("selected");
                }
            }

            if(document.getElementsByClassName("accordion").length > 0) {
                for(var h = 0; h < document.getElementsByClassName("accordion").length; h++) {
                    for(var w = 0; w < document.getElementsByClassName("accordion")[h].querySelectorAll('div').length; w++) {
                        document.getElementsByClassName("accordion")[h].querySelectorAll('div')[w].classList.remove("selected");
                    }
                }
            }
            $(".bread-crumbs").find(".breadcrumbs-val").html("");
            this.viewer.loadMap({ venue_id: venue, map_id: map })
                .then(result => this.onLoadD2MSuccess(result))
                .catch(err => this.onLoadD2MError(err));
    
            if(this.integrationType === "dvm" && this.config.hasMinimap) {
                this.minimap_load = this.viewer_mini.loadMap({ venue_id: venue, map_id: minimap})
                    .then(result => this.onLoadMinimapSuccess(result))
                    .catch(err => this.onLoadD2MError(err));
            }

        } else {
            this.viewer.setAvailability("section", nodes)
        }
    }

    nodes;


    onLoadD2MSuccess(obj) {
        this.interfaceViewerShow();
        this.viewer.flags.fixed_aspect_ratio = false;
        this.viewer.flags.automatic_selection = false;
        this.viewer.max_selection = 1;
        this.actualVenue = this.viewer.getVenueId();

        $("#loadingmap").addClass("d-none");

        if(obj.instance.getNodesByType("seat").length < 1 && this.list.length === 1){
            this.interfaceSetArea();
            this.dvmOnLoadArea(obj);
            this.dvmSetFullAvailability(obj.instance);
        } else if(obj.instance.getNodesByType("seat").length < 1 || this.list.length < 1){
            this.interfaceSetTopview();
            this.dvmOnLoadTopview(obj);
            this.dvmSetFullAvailability(obj.instance);
        } else {
            this.interfaceSetSeatmap();
            this.dvmOnLoadSeatmap(obj);
        }

        this.interfaceHomeBtnHide();
        this.interfaceViewer3DBtnsHide();
        this.interfaceBCSetColours();
        
        if(this.interfaceIsMobile()) {
            this.interfaceMoveArrowsHide();
        } 

        this.popoverService.popoverDestroyAll();
        //this.viewer.unselectAll();
        this.nodes = this.viewer.getNodesByState("section","available");

        (window as any).viewer = this.viewer;
        (window as any).viewer_mini = this.viewer_mini;
        (window as any).viewer_3d = this.viewer_3d;
    }

    onLoadD2MError(err) {
        console.log(err);
        /*if(this.interfaceGetCurrent() === 'seatmap'){
            this.dvmLoadViewer3D(this.interface.topview.nodes_selected[0]);
        }*/
    }

    list = [];
    pano;
    sectionGroup = false;

    onClick(obj) {
        var node = obj.nodes[0];
        if(node){
            var faketype = node.type;
            var fakeid = node.id;
            if(node && node.state !== 'unavailable' && this.exceptions.indexOf(node.id) != -1 
            || node && node.state !== 'unavailable' && this.config.viewType === 'section'){
                faketype = 'general_admission';
            }
            
            if (node && node.state !== 'unavailable') {
                this.pano = node.id;
                if ((faketype === "section" || faketype === "areaprice")) {
                    if(this.integrationType === 'bumper'){
                        $("#loadingmap").addClass("d-none");
                        if(this.interfaceIsMobile()) {
                            this.viewer.goTo(node.id,this.viewer.min_scaling_factor*20,1000);
                        } else {
                            this.viewer.goTo(node.id,this.viewer.min_scaling_factor*9.5,1000);
                        }
                    } else{
                        if(node.children.length == 0) {
                            this.list.push(this.viewer.getMapId());
                            $("#loadingmap").removeClass("d-none");
        
                            if(this.list.length < 1) {
                                this.interfaceMinimapHide();
                            } else {
                                this.interfaceMinimapShow();
                                this.dvmSelectViewerMini(node.id);
                            }
    
                            $("#mapstyle-button-id").addClass("d-none");
                            this.actualMap = node.id;
    
                            for(var i = 0; i < this.viewer.getNodesByType("section").length; i++) {
                                if(this.viewer.getNodesByType("section")[i].id.indexOf("G_") !== -1) {
                                    this.sectionGroup = true;
                                    $(".section-lab").text("Group");
                                    break;
                                } else if (this.viewer.getNodesByType("section")[i].id.indexOf("S_") !== -1) {
                                    this.sectionGroup = false;
                                    $(".section-lab").text("Section");
                                    break;
                                }else if (this.viewer.getNodesByType("section")[i].id.indexOf("V_") !== -1) {
                                    this.sectionGroup = false;
                                    $(".section-lab").text("Section");
                                    break;
                                }
                            }
        
                            this.viewer.loadMap({
                                venue_id: this.venue_id,
                                map_id: node.id
                            })
                            .then(result => this.onLoadD2MSuccess(result))
                            .catch(err => this.onLoadD2MError(err));
    
                            this.viewer.unselectAll();
                            this.viewer.select(node);
                            this.interface.topview.nodes_selected = [node];
                        }
                    }
                    this.popoverService.popoverDestroyAll();
                } else if (faketype === 'general_admission') {
                    if(this.config.hasPano) {
                        this.interfaceShowSectionLabel();
                        this.interfaceHideRowLabel();
                        this.interfaceHideSeatLabel();
                        this.current_view = 'view3d';
                        this.actualMap = node.id;
                        this.list.push(this.viewer.getMapId());
                        if(fakeid.split("-")[0]) {
                            fakeid = fakeid.split("-")[0]
                        }
                        this.dvmLoadViewer3D(node);
                        this.popoverService.popoverDestroyAll();
                    }
                    this.viewer.unselectAll();
                    this.viewer.select(fakeid)
                } else if (faketype === "seat") {
                    if(this.integrationType === 'bumper'){
                        $("#loadingmap").addClass("d-none");
                        if(this.interfaceIsMobile()) {
                            this.viewer.goTo(node.id,this.viewer.min_scaling_factor*20,1000);
                        } else {
                            this.viewer.goTo(node.id,this.viewer.min_scaling_factor*9.5,1000);
                        }
                    }
                    this.viewer.unselectAll();
                    this.viewer.select(node.id);
    
                    for(var i = 0; i < this.viewer.getNodesByType("section").length; i++) {
                        if(this.viewer.getNodesByType("section")[i].id.indexOf("G_") !== -1) {
                            this.sectionGroup = true;
                            $(".section-lab").text("Group");
                            break;
                        } else if (this.viewer.getNodesByType("section")[i].id.indexOf("S_") !== -1) {
                            this.sectionGroup = false;
                            $(".section-lab").text("Section");
                            break;
                        } else if (this.viewer.getNodesByType("section")[i].id.indexOf("V_") !== -1) {
                            this.sectionGroup = false;
                            $(".section-lab").text("Section");
                            break;
                        }
                    }
    
                    if(this.config.hasPano) {
                        this.interfaceShowSectionLabel();
                        this.interfaceShowRowLabel();
                        this.interfaceShowSeatLabel();
                        this.interfaceMinimapShow();
                        this.current_view = 'view3d';
                        this.list.push(this.viewer.getMapId());
                        this.dvmLoadViewer3D(node);
                        this.popoverService.popoverDestroyAll();
                    }
                }
    
                if(document.getElementById("filter-btn")) {
                    document.getElementById("filter-btn").classList.add("d-none");
                }
    
            }
        }
    }

    multIndoors = [];

    dvmLoadViewer3D(node) {
        var obj;
        this.interfacesetViewer3D(node);
        if(this.exceptions.indexOf(node.id) !== -1) {
            this.interface.topview.nodes_selected = [node];
        } else {
            this.interface.seatmap.nodes_selected = [node];
        }

        if(this.config.viewerVersion){
            obj = {venue_id: this.venue_id, view_id:node.id, set_id:this.viewerVersion}
        } else {
            obj = {venue_id: this.venue_id, view_id:node.id}
        }

        this.interfaceLinkToShare(node);
        this.viewer_3d.loadView3d(obj).then((result) => {
            this.multIndoors = [];
            if(this.viewer_3d.related && this.viewer_3d.related.getRelatedViews()){
                var keys = Object.keys(this.viewer_3d.related.getRelatedViews());
                var keysLength = Object.keys(this.viewer_3d.related.getRelatedViews()).length;
                
                for(var i = 0; i < keysLength; i++){
                    this.multIndoors.push(this.viewer_3d.related.getRelatedViewByName(keys[i]));
                }
    
                for(var j = 0; j < this.multIndoors.length; j++){
                    const num = j
                    this.viewer_3d.getThumbnail(this.multIndoors[j]).then((img) => {
                        this.multIndoors[num].image = "'" + img + "'";
                        this.multIndoors[num].view_back = node.id;
                        //$("#navigation-popup"+num).attr("style","background-image: url('"+img+"')");
                    }).catch(function(e){
                        console.log(e);
                    })
                }
            }
                
            $("#ticketing3dwidget-fullscreen-button0").css("visibility","hidden");
            $("#ticketing3dwidget-fullscreen-button1").css("visibility","hidden");
            this.interfaceZoomHide();
            this.interfaceFSBtnShow();
            this.interfaceHomeBtnShow();
            this.breadcrumbsMobileHide();
            this.interfaceShowShareBtn();
            this.current_view = 'view3d';
            this.interface.topview.nodes_selected = [node];
            this.interface.seatmap.nodes_selected = [node];
            $("#loadingmap").addClass("d-none");
            this.popoverService.popoverDestroyAll();
            
            if(this.viewer.getNodesByState("section","selected")[0]) {
                $(".section-val").text(this.viewer.getNodesByState("section","selected")[0].id.split("_")[1]);
                if(this.config.translation) {
                    if(this.viewer.getNodesByType("section").length > 0) {
                        if(this.config.translationOptionsJson[this.viewer.getNodesByState("section","selected")[0].id]) {
                            var section = this.config.translationOptionsJson[this.viewer.getNodesByState("section","selected")[0].id]
                            $(".section-val").text(section);
                        } else {
                            $(".section-val").text(this.viewer.getNodesByState("section","selected")[0].id.split("_")[1]);
                        }
                    } else if (this.viewer.getNodesByType("areaprice").length > 0) {
                        if(this.config.translationOptionsJson[this.viewer.getNodesByState("areaprice","selected")[0].id]) {
                            var section = this.config.translationOptionsJson[this.viewer.getNodesByState("areaprice","selected")[0].id]
                            $(".section-val").text(section);
                        } else {
                            $(".section-val").text(this.viewer.getNodesByState("areaprice","selected")[0].id.split("_")[1]);
                        }
                    }
                }
            }

            if(this.config.neighbours){
                //$(".neighbour-lab").removeClass("d-none");
            } else {
                //$(".neighbour-lab").addClass("d-none");
                //$(".app-topbar").css("justify-content","left");
            }
            
            $(".neighbour-lab").addClass("d-none");
            $(".app-topbar").css("justify-content","center");
            $(".multi-navigation").removeClass("d-none");

            if (this.config.galleryPluginCheck && this.viewer_3d.gallery && this.viewer_3d.gallery.hasGallery()){
                this.interfaceGalleryBtnShow();
            }

            if(this.config.hasMinimap){
                this.viewer_mini.open();
                this.minimap_load.then(() => {
                    this.viewer_mini.unselectAll();
                    if(this.exceptions.indexOf(node.id) !== -1) {
                        this.viewer_mini.select(node);
                        this.viewer_mini.goTo(node,this.viewer_mini.min_scaling_factor*2.5,1000);
                    } else {
                        if(this.viewer.getMapId() && this.viewer.getMapId() !== "blockmap") {
                            this.viewer_mini.select(this.viewer.getMapId());
                            this.viewer_mini.goTo(this.viewer.getMapId(),this.viewer_mini.min_scaling_factor*2.5,1000);
                        } else if(this.viewer_3d.getViewId()) {
                            this.viewer_mini.select(this.viewer_3d.getViewId().split("-")[0]);
                            this.viewer_mini.goTo(this.viewer_3d.getViewId().split("-")[0],this.viewer_mini.min_scaling_factor*2.5,1000);
                        } else if(this.viewer_3d.visual_id) {
                            this.viewer_mini.select(this.viewer_3d.visual_id.split("-")[0]);
                            this.viewer_mini.goTo(this.viewer_3d.visual_id.split("-")[0],this.viewer_mini.min_scaling_factor*2.5,1000);
                        }
                    }
                    
                });
            }

            this.interfaceTicketsMobileBtnShow();
            this.interfaceSetInitialNavigation();
            this.dvmNavigationUpdatePopup();
            //this.dvmSelectViewerMini(node.id);

            if(this.config.onlyViewer){
                $(".interface-btns-desktop").removeClass("d-none");
                $(".back-btn").addClass("d-none");
                $(".home-btn").addClass("d-none");
                $(".share-btn").addClass("d-none");
            }
        })

        this.interfaceMinimapShow();
    }

    dvmloadNavigation(obj){
        this.interfaceSetInitialNavigation();
        this.dvmNavigationUpdatePopup();
    }

    dvmSetInitialFlags(){
        this.viewer.flags.fixed_aspect_ratio = false;
        this.viewer.flags.automatic_selection = false;
        //this.viewer_3d.flags.fixed_aspect_ratio = false;

        if (this.viewer_3d.gallery && this.viewer_3d.gallery.hasGallery()){
            this.viewer_3d.gallery.flags.show_gallery_button = false;
        }

        if( this.FLAG === 'new'){
            if(this.interfaceIsMobile()){
                this.viewer_3d.interface.setLabelOffset(10,4);
            }else {
                this.viewer_3d.interface.setLabelOffset(20,7);
            }
        }

        if(this.viewer_mini) {
            this.viewer_mini.flags.fixed_aspect_ratio = false;
            this.viewer_mini.flags.automatic_selection = false;
        }

        return false;
    }
    
    dvmBindInterfaceEvents(){
        var zoom_in_btn = document.getElementsByClassName("zoom-in-btn")[0];
        var zoom_out_btn = document.getElementsByClassName("zoom-out-btn")[0];
    
        this.viewer.bindInterfaceAction(zoom_in_btn, "zoom-in");
        this.viewer.bindInterfaceAction(zoom_out_btn, "zoom-out");
    
        var move_left_btn = document.getElementById("move-left");
        var move_right_btn = document.getElementById("move-right");
        var move_up_btn = document.getElementById("move-up");
        var move_down_btn = document.getElementById("move-down");
    
        this.viewer.bindInterfaceAction(move_left_btn, "move-left");
        this.viewer.bindInterfaceAction(move_right_btn, "move-right");
        this.viewer.bindInterfaceAction(move_up_btn, "move-up");
        this.viewer.bindInterfaceAction(move_down_btn, "move-down");
    
        return null;
    }
    
    onEnterCB(obj) {
        if(!this.interfaceIsMobile() && obj.nodes[0].state !== 'unavailable'){
            this.popover_timeout = setTimeout(() => {
                this.popoverCreate(obj);
                this.popover_timeout = null;
            },100);
        }
        return null;
    }
    
    onLeaveCB(obj) {
        if(!this.interfaceIsMobile()){
            if(this.popover_timeout) {
                clearTimeout(this.popover_timeout);
                this.popover_timeout = null;
            }
            else{
                this.popoverService.popoverDestroyAll();
            }
        }
        return null;
    }
    
    onZoomCB(obj){
        
    }
    
    onZoomingPanning(obj){
    
    }

    onLoadMinimap(err) {
        if (err) {
            console.error(err);
        }
        
        $(".minimap").removeClass("d-none");
        (window as any).viewer_mini_bumper = this.viewer_mini_bumper;
        var size = this.viewer_mini_bumper.limits.bbox_scene;
        this.viewer_mini_bumper.aspect_ratio = size[3] / size[2];
        this.viewer_mini_bumper.setAvailability("section", this.viewer_mini_bumper.getNodesByType("section"));
    
        //need_update = true;
    }
    
    onLoadMinimapSuccess(obj) {
        this.dvmSetFullAvailability(obj.instance);
        this.viewer_mini.flags.panning = false;
        this.viewer_mini.flags.zooming = false;
        this.viewer_mini.flags.max_zoom_on_first_limit = false;
        this.viewer_mini.scaling_factor = this.viewer_mini.min_scaling_factor;

        var style_mini = this.viewer_mini.getStyles();
        if(style_mini[0].section){
            if(style_mini[0].section.selected){
                if(this.config.blockSelected){
                    style_mini[0].section.selected.normal.none.fillStyle = this.config.blockSelected;
                }
            }
            this.viewer_mini.setStyles(style_mini);
        } else if(style_mini[0].area){
            if(style_mini[0].area.selected){
                if(this.config.blockSelected){
                    style_mini[0].area.selected.normal.none.fillStyle = this.config.blockSelected;
                }
            }
            this.viewer_mini.setStyles(style_mini);
        }

        if (!this.viewer_mini.getNodesByState("section", "selected").length && this.interface.params.v360) {
            var section = this.interface.params.v360.split("-")[0];
            this.viewer_mini.select(section);
        }

        return Promise.resolve(obj);
    }
    
    dvmHideLabs(){
        var labs = document.getElementsByClassName("mmc-labels");
        if(labs.length>0){
            for(var i = 0;i<labs.length;i++){
                labs[i].classList.add("d-none");
            }
        }
        return null;
    }

    dvmShowLabs(){
        var labs = document.getElementsByClassName("mmc-labels");
        if(labs.length>0){
            for(var i = 0;i<labs.length;i++){
                labs[i].classList.remove("d-none");
            }
        }
        return null;
    }

    dvmLoadSeatmap(node){
        this.interface.seatmap.nodes_selected = [node];
    
        this.viewer.loadMap({
            venue_id: this.venue_id,
            map_id: node.id
        })
        .then(result => this.onLoadD2MSuccess(result))
        .catch(err => this.onLoadD2MError(err));
    
        if (this.viewer_mini.isLoaded()) {
            this.viewer_mini.unselectAll();
        }

        this.interfaceMinimapShow();
        this.interfaceSetCurrent('seatmap');
        this.dvmSelectViewerMini(node.id);
    }

    dvmSelectViewerMini(id){
        if(this.integrationType === "dvm" && this.config.hasMinimap) {
            this.minimap_load.then(()=>{
                this.viewer_mini.unselectAll();
                this.viewer_mini.select(id.split("-")[0]);
                this.viewer_mini.goTo(id.split("-")[0],this.viewer_mini.min_scaling_factor*2.5,1000);
            })
        }
    }

    seat_styles = {
        available:{
            normal: this.config.seatAvailable,
            hover: this.config.seatAvailableHover
        },
        unavailable: {
            normal: this.config.seatUnavailable,
        },
        selected: {
            normal: this.config.seatSelected,
            hover:this.config.seatSelectedHover
        }
    }

    dvmBlockmapSetStyles(){
        var style = this.viewer.getStyles();
        if(style[0].section){
            if(style[0].section.available){
                if(this.config.blockAvailable){
                    style[0].section.available.normal.none.fillStyle = this.config.blockAvailable;
                }

                if(this.config.blockAvailableOpacity) {
                    style[0].section.available.normal.none["fill-opacity"] = this.config.blockAvailableOpacity;
                }
                
                if(this.config.blockAvailableHover){
                    style[0].section.available.hover.none.fillStyle = this.config.blockAvailableHover;
                }
                
                if(this.config.blockAvailableHoverOpacity) {
                    style[0].section.available.hover.none["fill-opacity"] = this.config.blockAvailableHoverOpacity;
                }

                if(this.config.blockAvailableStroke) {
                    style[0].section.available.normal.none.strokeStyle = this.config.blockAvailableStroke;
                }

                if(this.config.blockAvailableStrokeOpacity) {
                    style[0].section.available.normal.none["stroke-opacity"] = this.config.blockAvailableStrokeOpacity;
                }

                if(this.config.blockAvailableHoverStroke) {
                    style[0].section.available.hover.none.strokeStyle = this.config.blockAvailableHoverStroke;
                }

                if(this.config.blockAvailableHoverStrokeOpacity) {
                    style[0].section.available.hover.none["stroke-opacity"] = this.config.blockAvailableHoverStrokeOpacity;
                }

                if(this.config.blockAvailableLineWidth) {
                    style[0].section.available.normal.none.lineWidth =this.config.blockAvailableLineWidth;
                }

                if(this.config.blockAvailableHoverLineWidth) {
                    style[0].section.available.hover.none.lineWidth =this.config.blockAvailableHoverLineWidth;
                }

                if(this.config.blockFiltered) {
                    style[0].section.available.normal.filtered = {
                        fillStyle: this.config.blockFiltered,
                        opacity: 1,
                        ["fill-opacity"]: this.config.blockFilteredOpacity,
                        strokeStyle: this.config.blockFilteredStroke,
                        ["stroke-opacity"]: this.config.blockFilteredStrokeOpacity,
                        lineWidth: this.config.blockFilteredLineWidth
                    };

                    style[0].section.available.hover.filtered = {
                        fillStyle: this.config.blockFilteredHover,
                        opacity: 1,
                        fillOpacity: this.config.blockFilteredHoverOpacity,
                        strokeStyle: this.config.blockFilteredHoverStroke,
                        ["stroke-opacity"]: this.config.blockFilteredHoverStrokeOpacity,
                        lineWidth: this.config.blockFilteredHoverLineWidth
                    };

                    style[0].section.unavailable.normal.filtered = {
                        fillStyle: this.config.blockFilteredUnavailable,
                        opacity: 1,
                        ["fill-opacity"]: this.config.blockFilteredUnavailableOpacity,
                        strokeStyle: this.config.blockFilteredUnavailableStroke,
                        ["stroke-opacity"]: this.config.blockFilteredUnavailableStrokeOpacity
                    };
                }
            }

            if(style[0].section.unavailable){
                if(style[0].section.unavailable.normal.none){
                    if(this.config.blockUnavailable){
                        style[0].section.unavailable.normal.none.fillStyle = this.config.blockUnavailable;
                        style[0].section.unavailable.normal.none.opacity = 1;
                    }
                    if(this.config.blockUnavailableOpacity) {
                        style[0].section.unavailable.normal.none["fill-opacity"] = this.config.blockUnavailableOpacity;
                        style[0].section.unavailable.normal.none.opacity = 1;
                    }
                    if(this.config.blockUnavailableStroke){
                        style[0].section.unavailable.normal.none.strokeStyle = this.config.blockUnavailableStroke
                    }
                    if(this.config.blockUnavailableStrokeOpacity){
                        style[0].section.unavailable.normal.none["stroke-opacity"] = this.config.blockUnavailableStrokeOpacity;
                    }
                }
            }

            if(style[0].section.selected){
                if(this.config.blockSelected) {
                    style[0].section.selected.normal.none.fillStyle = this.config.blockSelected;
                }

                if(this.config.blockSelectedOpacity) {
                    style[0].section.selected.normal.none["fill-opacity"] = this.config.blockSelectedOpacity;
                }
                
                if(this.config.blockSelectedHover) {
                    style[0].section.selected.hover.none.fillStyle =this.config.blockSelectedHover;
                }

                if(this.config.blockSelectedHoverOpacity) {
                    style[0].section.selected.hover.none["fill-opacity"] = this.config.blockSelectedHoverOpacity;
                }

                if(this.config.blockSelectedStroke) {
                    style[0].section.selected.normal.none.strokeStyle = this.config.blockSelectedStroke;
                }

                if(this.config.blockSelectedStrokeOpacity) {
                    style[0].section.selected.normal.none["stroke-opacity"] = this.config.blockSelectedStrokeOpacity;
                }

                if(this.config.blockSelectedHoverStroke) {
                    style[0].section.selected.hover.none.strokeStyle = this.config.blockSelectedHoverStroke;
                }

                if(this.config.blockSelectedHoverStrokeOpacity) {
                    style[0].section.selected.hover.none["stroke-opacity"] = this.config.blockSelectedHoverStrokeOpacity
                }

                if(this.config.blockSelectedLineWidth) {
                    style[0].section.selected.normal.none.lineWidth = this.config.blockSelectedLineWidth;
                }

                if(this.config.blockSelectedHoverLineWidth) {
                    style[0].section.selected.hover.none.lineWidth = this.config.blockSelectedHoverLineWidth;
                }
            }

            this.viewer.setStyles(style);

        } else if(style[0].areaprice) {
            if(style[0].areaprice.available){
                if(this.config.blockAvailable){
                    style[0].areaprice.available.normal.none.fillStyle = this.config.blockAvailable;
                }

                if(this.config.blockAvailableOpacity) {
                    style[0].areaprice.available.normal.none["fill-opacity"] = this.config.blockAvailableOpacity;
                }
                
                if(this.config.blockAvailableHover){
                    style[0].areaprice.available.hover.none.fillStyle = this.config.blockAvailableHover;
                }
                
                if(this.config.blockAvailableHoverOpacity) {
                    style[0].areaprice.available.hover.none["fill-opacity"] = this.config.blockAvailableHoverOpacity;
                }

                if(this.config.blockAvailableStroke) {
                    style[0].areaprice.available.normal.none.strokeStyle = this.config.blockAvailableStroke;
                }

                if(this.config.blockAvailableStrokeOpacity) {
                    style[0].areaprice.available.normal.none["stroke-opacity"] = this.config.blockAvailableStrokeOpacity;
                }

                if(this.config.blockAvailableHoverStroke) {
                    style[0].areaprice.available.hover.none.strokeStyle = this.config.blockAvailableHoverStroke;
                }

                if(this.config.blockAvailableHoverStrokeOpacity) {
                    style[0].areaprice.available.hover.none["stroke-opacity"] = this.config.blockAvailableHoverStrokeOpacity;
                }

                if(this.config.blockAvailableLineWidth) {
                    style[0].areaprice.available.normal.none.lineWidth = this.config.blockAvailableLineWidth;
                }

                if(this.config.blockAvailableHoverLineWidth) {
                    style[0].areaprice.available.hover.none.lineWidth = this.config.blockAvailableHoverLineWidth;
                }

                if(this.config.blockFiltered) {
                    style[0].areaprice.available.normal.filtered = {
                        fillStyle: this.config.blockFiltered,
                        opacity: 1,
                        ["fill-opacity"]: this.config.blockFilteredOpacity,
                        strokeStyle: this.config.blockFilteredStroke,
                        ["stroke-opacity"]: this.config.blockFilteredStrokeOpacity
                    };

                    style[0].areaprice.available.hover.filtered = {
                        fillStyle: this.config.blockFilteredHover,
                        opacity: 1,
                        fillOpacity: this.config.blockFilteredHoverOpacity,
                        strokeStyle: this.config.blockFilteredHoverStroke,
                        ["stroke-opacity"]: this.config.blockFilteredHoverStrokeOpacity
                    };

                    style[0].areaprice.unavailable.normal.filtered = {
                        fillStyle: this.config.blockFilteredUnavailable,
                        opacity: 1,
                        ["fill-opacity"]: this.config.blockFilteredUnavailableOpacity,
                        strokeStyle: this.config.blockFilteredUnavailableStroke,
                        ["stroke-opacity"]: this.config.blockFilteredUnavailableStrokeOpacity
                    };
                }
            }

            if(style[0].areaprice.unavailable){
                if(style[0].areaprice.unavailable.normal.none){
                    if(this.config.blockUnavailable){
                        style[0].areaprice.unavailable.normal.none.fillStyle = this.config.blockUnavailable;
                    }
                    if(this.config.blockUnavailableOpacity) {
                        style[0].areaprice.unavailable.normal.none["fill-opacity"] = this.config.blockUnavailableOpacity;
                    }
                    if(this.config.blockUnavailableStroke){
                        style[0].areaprice.unavailable.normal.none.strokeStyle = this.config.blockUnavailableStroke
                    }
                    if(this.config.blockUnavailableStrokeOpacity){
                        style[0].areaprice.unavailable.normal.none["stroke-opacity"] = this.config.blockUnavailableStrokeOpacity;
                    }
                }
            }

            if(style[0].areaprice.selected){
                if(this.config.blockSelected) {
                    style[0].areaprice.selected.normal.none.fillStyle = this.config.blockSelected;
                }

                if(this.config.blockSelectedOpacity) {
                    style[0].areaprice.selected.normal.none["fill-opacity"] = this.config.blockSelectedOpacity;
                }
                
                if(this.config.blockSelectedHover) {
                    style[0].areaprice.selected.hover.none.fillStyle =this.config.blockSelectedHover;
                }

                if(this.config.blockSelectedHoverOpacity) {
                    style[0].areaprice.selected.hover.none["fill-opacity"] = this.config.blockSelectedHoverOpacity;
                }

                if(this.config.blockSelectedStroke) {
                    style[0].areaprice.selected.normal.none.strokeStyle = this.config.blockSelectedStroke;
                }

                if(this.config.blockSelectedStrokeOpacity) {
                    style[0].areaprice.selected.normal.none["stroke-opacity"] = this.config.blockSelectedStrokeOpacity;
                }

                if(this.config.blockSelectedHoverStroke) {
                    style[0].areaprice.selected.hover.none.strokeStyle = this.config.blockSelectedHoverStroke;
                }

                if(this.config.blockSelectedHoverStrokeOpacity) {
                    style[0].areaprice.selected.hover.none["stroke-opacity"] = this.config.blockSelectedHoverStrokeOpacity
                }

                if(this.config.blockSelectedLineWidth) {
                    style[0].areaprice.selected.normal.none.lineWidth = this.config.blockSelectedLineWidth;
                }

                if(this.config.blockSelectedHoverLineWidth) {
                    style[0].areaprice.selected.hover.none.lineWidth = this.config.blockSelectedHoverLineWidth;
                }
            }

            this.viewer.setStyles(style);
        }

        return null;
    }

    dvmSeatmapSetStyles(){
        var style = this.viewer.getStyles();

        if(style[0].seat){
            if(style[0].seat.available){
                if(this.config.seatAvailable){
                    style[0].seat.available.normal.none.fillStyle = this.config.seatAvailable;
                }

                if(this.config.seatAvailableOpacity) {
                    style[0].seat.available.normal.none["fillOpacity"] = parseFloat(this.config.seatAvailableOpacity);
                }

                if(this.config.seatAvailableStroke) {
                    style[0].seat.available.normal.none.strokeStyle = this.config.seatAvailableStroke;
                }

                if(this.config.seatAvailableStrokeOpacity) {
                    style[0].seat.available.normal.none["strokeOpacity"] = parseFloat(this.config.seatAvailableStrokeOpacity);
                }

                if(this.config.seatAvailableLineWidth) {
                    style[0].seat.available.normal.none.lineWidth = parseFloat(this.config.seatAvailableLineWidth);
                }

                if(this.config.seatAvailableHover) {
                    style[0].seat.available.hover.none.fillStyle = this.config.seatAvailableHover;
                }

                if(this.config.seatAvailableHoverOpacity) {
                    style[0].seat.available.hover.none["fillOpacity"] = parseFloat(this.config.seatAvailableHoverOpacity);
                }

                if(this.config.seatAvailableHoverStroke) {
                    style[0].seat.available.hover.none.strokeStyle = this.config.seatAvailableHoverStroke;
                }

                if(this.config.seatAvailableHoverStrokeOpacity) {
                    style[0].seat.available.hover.none["strokeOpacity"] = parseFloat(this.config.seatAvailableHoverStrokeOpacity);
                }

                if(this.config.seatAvailableHoverLineWidth) {
                    style[0].seat.available.hover.none.lineWidth = parseFloat(this.config.seatAvailableHoverLineWidth);
                }
            }

            if(style[0].seat.unavailable){
                if(style[0].seat.unavailable.normal.none){
                    if(this.config.seatUnavailable){
                        style[0].seat.unavailable.normal.none.fillStyle = this.config.seatUnavailable;
                    }
                }
            }
    
            if(style[0].seat.selected){
                if(this.config.seatSelected){
                    style[0].seat.selected.normal.none.fillStyle = this.config.seatSelected;
                }

                if(this.config.seatSelectedOpacity) {
                    style[0].seat.selected.normal.none["fillOpacity"] = parseFloat(this.config.seatSelectedOpacity);
                }

                if(this.config.seatSelectedStroke) {
                    style[0].seat.selected.normal.none.strokeStyle = this.config.seatSelectedStroke;
                }

                if(this.config.seatSelectedStrokeOpacity) {
                    style[0].seat.selected.normal.none["strokeOpacity"] = parseFloat(this.config.seatSelectedStrokeOpacity);
                }

                if(this.config.seatSelectedLineWidth) {
                    style[0].seat.selected.normal.none.lineWidth = parseFloat(this.config.seatSelectedLineWidth);
                }


                if(this.config.seatSelectedHover) {
                    style[0].seat.selected.hover.none.fillStyle = this.config.seatSelectedHover;
                }

                if(this.config.seatSelectedHoverOpacity) {
                    style[0].seat.selected.hover.none["fillOpacity"] = parseFloat(this.config.seatSelectedHoverOpacity);
                }

                if(this.config.seatSelectedHoverStroke) {
                    style[0].seat.selected.hover.none.strokeStyle = this.config.seatSelectedHoverStroke;
                }

                if(this.config.seatSelectedHoverStrokeOpacity) {
                    style[0].seat.selected.hover.none["strokeOpacity"] = parseFloat(this.config.seatSelectedHoverStrokeOpacity);
                }

                if(this.config.seatSelectedHoverLineWidth) {
                    style[0].seat.selected.hover.none.lineWidth = parseFloat(this.config.seatSelectedHoverLineWidth);
                }
            }

            this.viewer.setStyles(style);
        }

        return null;
    }

    allBlocks;

    dvmOnLoadArea(obj){
        this.interfaceViewerShow();
        this.dvmBlockmapSetStyles();    
        this.viewer.max_selection = 1;
        this.dvmSetFullAvailability(obj.instance);
        this.filteringTopviewApply();
        this.allBlocks = this.getAllBlocks();
        this.viewer.scaling_factor = 1;
        this.interfaceHideShareBtn();

        if(this.interface.topview.nodes_selected.length > 0) {
            this.viewer.select(this.interface.topview.nodes_selected[0].id.split("-")[0])
        }

        // var snodes = this.viewer.getNodesByState("section","selected");
        // if(snodes.length > 0){
        //     this.interfaceSetNodeBC(snodes[0]);
        //     this.viewer.select(snodes[0])
        // }
        
        this.current_view = 'map';
        this.interfaceSetCurrent("area");
        this.actualMap = this.config.mapID ? this.config.mapID : "";
        this.popoverService.popoverDestroyAll();
        $(".neighbour-lab").addClass("d-none");
        //$(".app-topbar").css("justify-content","left");
        if(this.config.filteringJson){
            if(this.array) {
                this.venue_id = this.array.venue,
                this.map_id = this.array.map,
                this.map_id_mini = this.array.minimap,
                this.actualVenue = this.array.venue,
                this.actualMap = this.array.map
            }
        }

        return null;
    }
    
    dvmOnLoadTopview(obj){
        this.interfaceViewerShow();
        this.dvmBlockmapSetStyles();    
        this.viewer.max_selection = 1;
        this.dvmSetFullAvailability(obj.instance);
        this.filteringTopviewApply();
        this.allBlocks = this.getAllBlocks();
        this.viewer.scaling_factor = 1;
        this.interfaceHideShareBtn();

        if(this.interface.topview.nodes_selected.length > 0) {
            this.viewer.select(this.interface.topview.nodes_selected[0].id.split("-")[0])
        }

        // var snodes = this.viewer.getNodesByState("section","selected");
        // if(snodes.length > 0){
        //     this.interfaceSetNodeBC(snodes[0]);
        //     this.viewer.select(snodes[0])
        // }
        
        this.current_view = 'map';
        this.interfaceSetCurrent("topview");
        this.actualMap = this.config.mapID ? this.config.mapID : "";
        this.popoverService.popoverDestroyAll();
        $(".neighbour-lab").addClass("d-none");
        //$(".app-topbar").css("justify-content","left");
        if(this.config.filteringJson){
            if(this.array) {
                this.venue_id = this.array.venue,
                this.map_id = this.array.map,
                this.map_id_mini = this.array.minimap,
                this.actualVenue = this.array.venue,
                this.actualMap = this.array.map
            }
        }

        return null;
    }
    
    dvmOnLoadSeatmap(obj){
        this.dvmSetFullAvailability(obj.instance);
        this.viewer.select(this.interface.seatmap.nodes_selected[0])
        //this.filteringSeatmapApply();
        this.interfaceHideShareBtn();
        if(this.config.filteringJson) {
            this.filteringSeatmapApply();
        }
        if(this.config.bestNodesCheck){
            setTimeout(() => {
                this.dvmSelectBestSeat();
              }, 1000);
        } else {
            var snodes = this.viewer.getMapId();
            var objs = {id:snodes}
            this.interfaceSetNodeBC(objs);
        }
        this.popoverService.popoverDestroyAll();
        $(".app-topbar").css("justify-content","center");

        return null;
    }

     dvmSelectBestSeat(){
        if(this.viewer.best_nodes){
            if(this.viewer.getNodesByType("seat").length > 0) {
                this.viewer.best_nodes.getBestNodes("seat",1)
                .then((res)=>{
                    this.viewer.goTo(res[0].id,65,900)
                        .then(()=>{
                            this.viewer.select(res);
                            this.interfaceSetNodeBC(res[0]);
                        })
                }).catch((e)=>{
                    console.log(e);
                });
            }
        }
    }
    
    dvmToggleFS(){
        this.viewer_3d.toggleFullscreen().then(() => {
            $(".dvm-v3d-button-fullscreen").addClass("d-none");
            
            if($(".dvm-v3d-main").hasClass("dvm-v3d-main-fullscreen")){
                if(this.interfaceIsMobile()){
                    $(".dvm-v3d-bottom-center").append($(".fsclose-btn").removeClass("d-none"));
                }else{
                    $(".dvm-v3d-top-right").append($(".fsclose-btn").removeClass("d-none"));
                }
            } else if($("#ticketing3dwidget-fullscreen-button0").length > 0) {
                $("#ticketing3dwidget-fullscreen-button0").addClass("d-none");
                setTimeout(()=>{
                    if($("#ticketing3dwidget-fullscreen-button0").hasClass("onfullscreen-tk3d")) {
                        $("#ticketing3dwidget0").append($(".fsclose-btn").removeClass("d-none"))
                    } else {
                        $(".fsclose-btn").addClass("d-none");
                    }
                },50);
            } else{
                $(".fsclose-btn").addClass("d-none");
                $(".interface-btns-desktop").append($(".fsclose-btn"));
            }
        });
    }
    
    dvmSetFullAvailability(instance) {
        var node_types = instance.getTypesList();

        node_types.forEach((type) => {
            var ntypes = instance.getNodesByType(type);
            if(ntypes.length > 0){
                if(this.config.unavailable) {
                    instance.setAvailability(type,ntypes);
                    if(type === "section") {
                        if(this.config.unavailableOptionsJson[this.viewer.getMapId()]){
                            instance.setUnavailable(type, this.config.unavailableOptionsJson[this.viewer.getMapId()]['sections']);
                        } else if(this.config.unavailableOptionsJson[this.viewer.getVenueId()]){
                            instance.setUnavailable(type, this.config.unavailableOptionsJson[this.viewer.getVenueId()]['sections']);
                        }
                    } else if(type === "seat") {
                        if(this.config.unavailableOptionsJson[this.viewer.getMapId()]) {
                            instance.setUnavailable(type, this.config.unavailableOptionsJson[this.viewer.getMapId()]['seats']);
                        }
                    } 
                } else {
                    instance.setAvailability(type,ntypes);
                }

                if(instance !== this.viewer_mini) {
                    if(this.interface.filtering.nodes.sections) {
                        if(this.interface.filtering.nodes.sections.length > 0 && type === "section") {
                            instance.setAvailability(type,this.interface.filtering.nodes.sections);
                        }
                    }
                }
            }
        });

        return null;
    }

    dvmNavigationUpdatePopup(){
        if(this.config.indoorPluginCheck){
            var nav = this.viewer_3d.indoor.getIndoorRelatedView();
            if(nav){
                this.viewer_3d.getThumbnail(nav).then((img) => {
                    $("#navigation-popup").attr("style","background-image: url('"+img+"')");
                    $(".navigation-popup-title p").text(nav.label);
                    this.interfaceNavigationShow(true);
        
                }).catch(function(e){
                    console.log(e);
                })
            }
        } else {
            var oldNav = this.interfaceGetNavigation();
            if(oldNav.to){
                this.viewer.getThumbnail((oldNav.to as any).id, this.venue_id,true).then((imgUrl)=>{
                    $("#navigation-popup").attr("style","background-image: url('"+imgUrl+"')");
                    $(".navigation-popup-title p").text((oldNav.to as any).title);
                    this.interfaceNavigationShow(false);
                }).catch((e)=>{
                    console.log(e);
                })
            }
        }
    }
    
    dvmViewer3dOnClickNavNode(obj) {
        var nav = obj.navigation;
        if (nav) {
            nav.reset_camera_rotation = false;
            this.viewer_3d.loadView3d(nav)
        }
    }

    multiNavigation(item, index){
        var obj = {venue_id: item.venue_id, view_id:item.view_id}
        var reverse = {venue_id: item.venue_id, view_id:this.interface.topview.nodes_selected[0].id}

        if(this.viewer_3d.getViewId().split("-")[0] === item.view_back){
            this.viewer_3d.loadView3d(obj).then(()=>{
                $("#ticketing3dwidget-fullscreen-button0").css("visibility","hidden");
                const num = index;
                this.viewer_3d.getThumbnail(reverse).then((img) => {
                    this.multIndoors[num].image = "'" + img + "'";
                    $("#navigation-popup-title-" + num + " p").text(item.label_back);
                    this.interfaceNavigationShow(true);
        
                }).catch(function(e){
                    console.log(e);
                })
    
                this.interfaceZoomHide();
                this.interfaceFSBtnShow();
                this.interfaceHomeBtnShow();
                this.breadcrumbsMobileHide();
                this.popoverService.popoverDestroyAll();
                
                if (this.config.galleryPluginCheck && this.viewer_3d.gallery && this.viewer_3d.gallery.hasGallery()){
                    this.interfaceGalleryBtnShow();
                }
    
                this.interfaceTicketsMobileBtnShow();
            });
        } else if(this.viewer_3d.getViewId().split("-")[0] === item.view_id){
            this.viewer_3d.loadView3d(reverse).then(()=>{
                $("#ticketing3dwidget-fullscreen-button0").css("visibility","hidden");
                const num = index;
                this.viewer_3d.getThumbnail(obj).then((img) => {
                    this.multIndoors[num].image = "'" + img + "'";
                    $("#navigation-popup-title-" + num + " p").text(item.label);
                    this.interfaceNavigationShow(true);
        
                }).catch(function(e){
                    console.log(e);
                })
    
                this.interfaceZoomHide();
                this.interfaceFSBtnShow();
                this.interfaceHomeBtnShow();
                this.breadcrumbsMobileHide();
                this.popoverService.popoverDestroyAll();
                
                if (this.config.galleryPluginCheck && this.viewer_3d.gallery && this.viewer_3d.gallery.hasGallery()){
                    this.interfaceGalleryBtnShow();
                }
    
                this.interfaceTicketsMobileBtnShow();
            });
        } else {
            for(var j = 0; j < this.multIndoors.length; j++){
                const num = j;
                this.viewer_3d.getThumbnail(this.multIndoors[j]).then((img) => {
                    this.multIndoors[num].image = "'" + img + "'";
                    this.multIndoors[num].view_back = this.viewer_3d.getViewId();
                    $("#navigation-popup-title-" + num + " p").text(this.multIndoors[num].label);
                  }).catch(function(e){
                      console.log(e);
                  })
            }

            this.viewer_3d.loadView3d(obj).then(()=>{
                $("#ticketing3dwidget-fullscreen-button0").css("visibility","hidden");
                const num = index;
                this.viewer_3d.getThumbnail(reverse).then((img) => {
                    this.multIndoors[num].image = "'" + img + "'";
                    $("#navigation-popup-title-" + num + " p").text(item.label_back);
                    this.interfaceNavigationShow(true);
        
                }).catch(function(e){
                    console.log(e);
                })
    
                this.interfaceZoomHide();
                this.interfaceFSBtnShow();
                this.interfaceHomeBtnShow();
                this.breadcrumbsMobileHide();
                this.popoverService.popoverDestroyAll();
                
                if (this.config.galleryPluginCheck && this.viewer_3d.gallery && this.viewer_3d.gallery.hasGallery()){
                    this.interfaceGalleryBtnShow();
                }
    
                this.interfaceTicketsMobileBtnShow();
            });

            console.log(this.multIndoors)
        }
    }
    
    dvmNavigationGo(){
        var node = this.viewer.getNodesByState("seat","selected")[0];
        if (!node && this.interface.params.v360) {
            node = {
              id: this.interface.params.v360
            };
          }

        if(node) {
            this.interfacesetViewer3D(node);
            this.interface.seatmap.nodes_selected = [node];
        }
        var nav = this.interfaceGetNavigation();
        var id = (nav.to as any).id;
        var obj = {venue_id: this.venue_id, view_id:id}

        this.viewer_3d.loadView3d(obj).then(()=>{
            $("#ticketing3dwidget-fullscreen-button0").css("visibility","hidden");
            this.interfaceSetNavigationReverse();
            this.dvmNavigationUpdatePopup();
            this.interfaceZoomHide();
            this.interfaceFSBtnShow();
            this.interfaceHomeBtnShow();
            this.breadcrumbsMobileHide();
            this.popoverService.popoverDestroyAll();
            
            if (this.config.galleryPluginCheck && this.viewer_3d.gallery && this.viewer_3d.gallery.hasGallery()){
                this.interfaceGalleryBtnShow();
            }

            this.interfaceTicketsMobileBtnShow();
        });
    }

    filterSectionOnBlockmap(section) {
        if(this.viewer && this.viewer.isLoaded()){
            if(this.viewer.getNodesByType("section").length > 0) {
                this.viewer.removeNodesFromGroup(this.viewer.getNodesByGroups("section","filtered"), "filtered");
            } else if (this.viewer.getNodesByType("areaprice").length > 0) {
                this.viewer.removeNodesFromGroup(this.viewer.getNodesByGroups("areaprice","filtered"), "filtered");
            }

            if(this.config.translation) {
                for(var i in this.config.translationOptionsJson) {
                    for(var j = 0; j < section.length; j++) {
                        if(section[j] === this.config.translationOptionsJson[i]) {
                            section[j] = i;
                        } else {
                            section[j] = section[j];
                        }
                    }
                }
            } 

            if(this.viewer.getNodesByType("section").length > 0) {
                this.viewer.addNodesToGroup(section, "filtered");
                this.viewer.setAvailability("section", section);
            } else if (this.viewer.getNodesByType("areaprice").length > 0) {
                this.viewer.addNodesToGroup(section, "filtered");
                this.viewer.setAvailability("areaprice", section);
            }
        }
    }

    removeFilteringSectionOnBlockmap() {
        if(this.viewer.getNodesByType("section").length > 0) {
            if(this.viewer.getNodesByGroups("section","searched").length > 0){
                this.viewer.setAvailability("section",this.viewer.getNodesByGroups("section","searched"));
            } else {
                this.viewer.setAvailability("section",this.viewer.getNodesByType("section"));
            }
            this.viewer.removeNodesFromGroup(this.viewer.getNodesByGroups("section","filtered"), "filtered");
            //this.viewer.removeNodesFromGroup(this.viewer.getNodesByGroups("section","searched"), "searched");
            if(this.config.unavailable && this.config.unavailableOptionsJson[this.viewer.getMapId()]){
                this.viewer.setUnavailable("section", this.config.unavailableOptionsJson[this.viewer.getMapId()]['sections'])
            }
        } else if (this.viewer.getNodesByType("areaprice").length > 0) {
            if(this.viewer.getNodesByGroups("areaprice","searched").length > 0){
                this.viewer.setAvailability("areaprice",this.viewer.getNodesByGroups("areaprice","searched"));
            } else {
                this.viewer.setAvailability("areaprice",this.viewer.getNodesByType("areaprice"));
            }
            this.viewer.removeNodesFromGroup(this.viewer.getNodesByGroups("areaprice","filtered"), "filtered");
            //this.viewer.removeNodesFromGroup(this.viewer.getNodesByGroups("areaprice","searched"), "searched");
            if(this.config.unavailable && this.config.unavailableOptionsJson[this.viewer.getMapId()]){
                this.viewer.setUnavailable("areaprice", this.config.unavailableOptionsJson[this.viewer.getMapId()]['sections'])
            }
        }
    }
    
    locateSeatmap(id) {
        var node = {
            id: id
        }
        this.interface.topview.nodes_selected = [node]
        if(this.config.viewType === "seat") {
            if(this.exceptions.length > 0 && this.exceptions.indexOf(id) != -1){ 
                this.interfaceShowSectionLabel();
                this.current_view = 'view3d';
                this.list.push(this.viewer.getMapId());
                this.dvmLoadViewer3D(node);
            } else {
                if(this.integrationType === 'bumper'){
                    this.viewer.goTo(id,this.viewer.min_scaling_factor*9.5,1000);
                }else{
                    this.list.push(this.viewer.getMapId());
                
                    if(this.list.length < 1) {
                        this.interfaceMinimapHide();
                    } else {
                        this.interfaceMinimapShow();
                        this.dvmSelectViewerMini(id);
                    }

                    this.actualMap = id;
                    $("#mapstyle-button-id").addClass("d-none");

                    this.viewer.loadMap({
                        venue_id: this.venue_id,
                        map_id: id
                    })
                    .then(result => this.onLoadD2MSuccess(result))
                    .catch(err => this.onLoadD2MError(err));
                }
            }
        } else {
            if(this.exceptions.length > 0 && this.exceptions.indexOf(id) != -1){ 
                if(this.integrationType === 'bumper'){
                    this.viewer.goTo(id,this.viewer.min_scaling_factor*9.5,1000);
                }else{
                    this.list.push(this.viewer.getMapId());
                
                    if(this.list.length < 1) {
                        this.interfaceMinimapHide();
                    } else {
                        this.interfaceMinimapShow();
                        this.dvmSelectViewerMini(id);
                    }

                    $("#mapstyle-button-id").addClass("d-none");

                    this.viewer.loadMap({
                        venue_id: this.venue_id,
                        map_id: id
                    })
                    .then(result => this.onLoadD2MSuccess(result))
                    .catch(err => this.onLoadD2MError(err));
                }
            } else {
                this.interfaceShowSectionLabel();
                this.current_view = 'view3d';
                this.list.push(this.viewer.getMapId());
                this.dvmLoadViewer3D(node);
            }   
        }
    }

    getAllBlocks() {
        if(this.viewer && this.viewer.isLoaded()) {
            if(this.viewer.getNodesByType("section").length > 0) {
                return this.viewer.getNodesByType("section");
            } else if(this.viewer.getNodesByType("areaprice").length > 0) {
                return this.viewer.getNodesByType("areaprice");
            }
        }
    }

    /* INTERFACE */

    interfaceShowSectionLabel() {
        $("#top-guide .section").removeClass("d-none");
    }
    
    interfaceHideSectionLabel() {
        $("#top-guide .section").addClass("d-none");
    }
    
    interfaceShowRowLabel() {
        $("#top-guide .rows").removeClass("d-none");
    }
    
    interfaceHideRowLabel() {
        $("#top-guide .rows").addClass("d-none");
    }
    
    interfaceShowSeatLabel() {
        $("#top-guide .seat").removeClass("d-none");
    }
    
    interfaceHideSeatLabel() {
        $("#top-guide .seat").addClass("d-none");
    }

    interfaceIsMobile(){
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || $(window).width()<620) {
            return true;
        } else {
            return false;
        }
    }

    interfaceMoveArrowsHideOnLimits(){
        if(this.viewer.limits) {
            if(this.viewer.limits.left_limit) {
                $("#move-left").addClass("d-none");
            }
            
            if(this.viewer.limits.right_limit) {
                $("#move-right").addClass("d-none");
            }
            
            if(this.viewer.limits.upper_limit) {
                $("#move-up").addClass("d-none");
            }
            
            if(this.viewer.limits.lower_limit) {
                $("#move-down").addClass("d-none");
            }
        }
        return null;
    }

    interfaceMoveArrowsShow(){
        $("#move-up").removeClass("d-none");
        $("#move-down").removeClass("d-none");
        $("#move-left").removeClass("d-none");
        $("#move-right").removeClass("d-none");
        return null;
    }

    interfaceMoveArrowsHide(){
        $("#move-up").addClass("d-none");
        $("#move-down").addClass("d-none");
        $("#move-left").addClass("d-none");
        $("#move-right").addClass("d-none");
        return null;
    }

    interfaceReset(){
        this.interface = {
            current:"topview",
            seatmap:{
                nodes_selected: []
            },
            topview:{
                nodes_selected: []
            },
            filtering:{
                ref : null,
                nodes : {
                    sections: [],
                    rows: [],
                    seats: []
                }
            },
            navigation:{
                to: "",
                from: ""
            },
            window:{
                innerWidth: window.innerWidth,
                innerHeight: window.innerHeight,
                animation: requestAnimationFrame(this.windowLoop)
            },
            params: this.interfaceGetParams(window.location.href)
        }
    }

    interfaceResetFiltering(){
        this.interface.filtering = {
            ref : null,
            nodes : {
                sections: [],
                rows: [],
                seats: []
            }
        }
    }

    interfaceZoomHide(){
        $(".zoom-in-btn").addClass("d-none");
        $(".zoom-out-btn").addClass("d-none");
        return;
    }

    interfaceZoomShow(){
        if(!this.interfaceIsMobile()){
            $(".zoom-in-btn").removeClass("d-none");
            $(".zoom-out-btn").removeClass("d-none");
        }
       return;
    }

    interfaceMoveFSbtn(){
        if(this.interfaceIsMobile()){
            if(this.viewer_3d.interface) {
                this.viewer_3d.interface.setInterfaceElementPosition("toggle-fullscreen","bottomcenter")
            }
        }
        return;
    }

    interfaceFSBtnShow(){
        $(".fs-btn").removeClass("d-none");
        return null;
    }

    interfaceFSBtnHide(){
        $(".fs-btn").addClass("d-none");
        return null;
    }

    interfaceHomeBtnShow(){
        if (!this.interface.params.v360) {
            $(".home-btn").removeClass("d-none");
        }
        return null;
    }
    
    interfaceHomeBtnHide(){
        $(".home-btn").addClass("d-none");
        return null;
    }

    interfaceGalleryArrowsToggle(){
        $(".glide__arrow").removeClass("d-none");
        if(this.interfaceIsMobile()){
            $(".glide__arrow").addClass("d-none");
        }
        return null;
    }

    interfaceSetCurrent(current){
        this.interface.current = current;
        return this.interface;
    }

    interfaceGetCurrent(){
        return this.interface.current;
    }

    interfaceViewer3DBtnsHide(){
        $(".viewer-btn").addClass("d-none");
        return null;
    }

    interfaceBackBtnShow(){
        if (!this.interface.params.v360) {
            $(".back-btn").removeClass("d-none");
        }
        return null;
    }
    
    interfaceBackBtnHide(){
        $(".back-btn").addClass("d-none");
        return null;
    }

    interfaceShowShareBtn() {
        if (!this.interface.params.v360 && this.current_view === "view3d") {
          $(".share-btn").removeClass("d-none");
        }
      
        return null;
    }

    interfaceHideShareBtn() {
        $(".share-btn").addClass("d-none");
        $(".custom-modal").addClass("d-none");
      }

    interfaceGalleryBtnHide(){
        $(".gallery-btn").addClass("d-none");
    }

    interfaceGalleryBtnShow(){
        $(".gallery-btn").removeClass("d-none");
    }

    interfaceMinimapHide(){
        $(".minimap").addClass("d-none")
        return null;
    }

    interfaceMinimapShow(){
        if(this.config.hasMinimap) {
            if(!this.map_id_mini){
                return;
            }

            $(".minimap").removeClass("d-none")
        }
        return null;
    }

    interfaceBreadcrumbsValueHide(element){
        $("#top-guide "+element).addClass("d-none");
        return null;
    }

    interfaceZoomSetTopview(){
        $(".zoom-btns-interface").addClass("ci-topview");
        $(".zoom-btns-interface").removeClass("ci-seatmap");
        return null;
    }

    interfaceZoomSetSeatmap(){
        $(".zoom-btns-interface").removeClass("ci-topview");
        $(".zoom-btns-interface").addClass("ci-seatmap");
        return null;
    }

    interfaceLinkToShare(node) {
        var link = window.location.href.split('?')[0]; //let link = "local.3ddigitalvenue.com:8080";
      
        if (!node && this.interface.filtering.ref != 'regularseating') {
          link += "?seatingtype=vip";
      
          if (this.interface.filtering.ref && this.interface.filtering.ref != 'premiumseating') {
            link += "&search=" + btoa(this.interface.filtering.ref);
          }
        } else if (node) {
          link += "?v360=" + btoa(node.id);
        } 
      
        $(".linktoshare-input").val(link);
        return;
    }

    interfaceSetArea(){
        this.viewer_3d.close();
        
        this.interfaceViewerShow();
        this.interfaceBackBtnShow();
        this.interfaceViewer3DHide();
        this.interfaceMinimapHide();
        $(".multi-navigation").addClass("d-none");
        this.interfaceZoomSetTopview();
        this.interfaceZoomShow();
        //this.interfaceBreadcrumbsValueHide(".section");
        this.interfaceBreadcrumbsValueHide(".rows");
        this.interfaceBreadcrumbsValueHide(".seat");
        this.interfaceShowShareBtn();
        if(this.config.neighbours){
            $(".neighbour-lab").addClass("d-none");
        }

        this.interfaceHomeBtnShow();
        this.interfaceFSBtnHide();
        this.interfaceGalleryBtnHide();
        this.interfaceNavigationHide();
        this.menuBtnShow();

        if(this.viewer_mini) {
            this.viewer_mini.scaling_factor = this.viewer_mini.min_scaling_factor;
        }

        this.breadcrumbsShowToggle();

        if(this.interfaceIsMobile()) {
            this.interfaceMoveArrowsHide();
            //this.viewer.scaling_factor = 2;
        }
        //this.interfaceTicketsMobileBtnHide();
        this.popoverService.popoverDestroyAll();
        this.interfaceSetCurrent("area");
        this.viewer.scaling_factor = 1;
    }

    interfaceSetTopview(){
        this.viewer_3d.close();

        this.interfaceViewerShow();
        this.interfaceViewer3DHide();
        this.interfaceBackBtnHide();
        this.interfaceMinimapHide();
        $(".multi-navigation").addClass("d-none");
        this.interfaceZoomSetTopview();
        this.interfaceZoomShow();
        //this.interfaceBreadcrumbsValueHide(".section");
        this.interfaceBreadcrumbsValueHide(".rows");
        this.interfaceBreadcrumbsValueHide(".seat");
        this.interfaceShowShareBtn();
        if(this.config.neighbours){
            $(".neighbour-lab").addClass("d-none");
        }

        this.interfaceHomeBtnHide();
        this.interfaceFSBtnHide();
        this.interfaceGalleryBtnHide();
        this.interfaceNavigationHide();
        this.menuBtnShow();

        if(this.viewer_mini) {
            this.viewer_mini.scaling_factor = this.viewer_mini.min_scaling_factor;
        }

        this.breadcrumbsShowToggle();

        if(this.interfaceIsMobile()) {
            this.interfaceMoveArrowsHide();
            //this.viewer.scaling_factor = 2;
        }
        //this.interfaceTicketsMobileBtnHide();
        this.popoverService.popoverDestroyAll();
        this.interfaceSetCurrent("topview");
        this.viewer.scaling_factor = 1;
    }

    interfaceViewerShow(){
        $("#viewer").removeClass("d-none");
        return null;
    }

    interfaceViewer3DHide(){
        $("#view3d").addClass("d-none");
        return null;
    }

    interfaceSetSeatmap(){
        this.viewer_3d.close();

        this.interfaceViewerShow();
        this.interfaceViewer3DHide();
        this.interfaceViewer3DBtnsHide();
        this.dvmSeatmapSetStyles();
        this.interfaceHideShareBtn();
        $(".multi-navigation").addClass("d-none");

        if(this.integrationType === 'bumper'){
            this.interfaceBackBtnHide();
        }else{
            this.interfaceBackBtnShow();
        }

        this.menuClose();
        this.menuBtnHide();

        this.interfaceMoveFSbtn();
        this.interfaceFSBtnHide();
        this.interfaceHomeBtnHide();
        this.interfaceZoomSetSeatmap();
        this.interfaceZoomShow();
        this.interfaceNavigationHide();
        this.interfaceMinimapShow();

        if(this.config.neighbours && this.config.neighboursOptionsJson[this.viewer.getMapId()]){
            $(".neighbour-lab").removeClass("d-none");
            
            var left = this.config.neighboursOptionsJson[this.viewer.getMapId()].l;
            var right = this.config.neighboursOptionsJson[this.viewer.getMapId()].r;

            var leftText = "Left section";
            var rightText = "Right section";

            if(!this.config.leftRight) {
                if(this.config.translation) {
                    if(this.config.translationOptionsJson[left]) {
                        leftText = this.config.translationOptionsJson[left];
                    } else {
                        leftText = left.split("_")[1];
                    }
    
                    if(this.config.translationOptionsJson[right]){
                        rightText = this.config.translationOptionsJson[right];
                    } else {
                        rightText = right.split("_")[1];
                    }
                } else {
                    leftText = left.split("_")[1];
                    rightText = right.split("_")[1];
                }
            }

            if(right !== "none"){
                $(".next-neighbour").css("opacity", "1");
                $(".fa-angle-double-right").css("opacity", "1");
                $(".next-neighbour").text(rightText);
                $(".next-neighbour").parent().css('pointer-events','all');
            } else {
                $(".next-neighbour").css("opacity", "0");
                $(".fa-angle-double-right").css("opacity", "0");
                $(".next-neighbour").parent().css('pointer-events','none');
            }

            if(left !== "none") {
                $(".prev-neighbour").css("opacity", "1");
                $(".fa-angle-double-left").css("opacity", "1");
                $(".prev-neighbour").text(leftText);
                $(".prev-neighbour").parent().css('pointer-events','all');
            } else {
                $(".prev-neighbour").css("opacity", "0");
                $(".fa-angle-double-left").css("opacity", "0");
                $(".prev-neighbour").parent().css('pointer-events','none');
            }
            
        } else {
            $(".neighbour-lab").addClass("d-none");
        }
        if(!this.interfaceIsMobile()) {
            this.interfaceHomeBtnHide();
            this.breadcrumbsShowToggle();
        }
        //this.interfaceTicketsMobileBtnHide();
        //this.interfaceSetCurrent("seatmap");
        return null;
    }

    interfacesetViewer3D(node){
        this.interfaceGalleryArrowsToggle();
        $("#viewer").addClass("d-none")
        $("#view3d").removeClass("d-none");
        this.interfaceSetNodeBC(node);
        this.interfaceSetCurrent('view3d');
        this.menuBtnHide();
        this.menuClose();
        this.interfaceBackBtnShow();
        this.current_view = 'view3d';
        if(this.config.onlyViewer){
            $(".navigation").css("left", "20px");
        }
        
        return null;
    }

    interfaceGoBack(){
        this.interfaceViewerShow();
        var pop = this.list.pop();

        if(this.list.length < 2) {
            this.interfaceMinimapHide();
        } else {
            this.interfaceMinimapShow();
        }

        if(this.current_view === "map") {
            if(this.list.length === 0) {
                if(document.getElementById("filter-btn")) {
                    document.getElementById("filter-btn").classList.remove("d-none");
                }
                $("#mapstyle-button-id").removeClass("d-none");
                this.interfaceGoHome();
                return;
            }

            this.viewer.loadMap({
                venue_id: this.venue_id,
                map_id: pop
            })
            .then(result => this.onLoadD2MSuccess(result))
            .catch(err => this.onLoadD2MError(err));

        } else if(this.current_view === "view3d") {
            this.current_view = 'map';
            this.viewer.open();
            document.getElementById("gallery-btn").classList.add("d-none");
            
            if(this.list.length === 0 && this.config.integrationType === 'dvm') {
                this.interfaceGoHome();
                return;
            }

            this.viewer.loadMap({
                venue_id: this.venue_id,
                map_id: pop
            })    
            .then(result => this.onLoadD2MSuccess(result))
            .catch(err => this.onLoadD2MError(err));

            this.interfaceSetSeatmap();
        }

        this.popoverService.popoverDestroyAll();
    }

    interfaceGoHome(){
        if (this.interface.params.v360) {
            return null;
        }

        this.list = [];
        this.interfaceViewerShow();
        $("#mapstyle-button-id").removeClass("d-none");
        if(document.getElementById("filter-btn")) {
            document.getElementById("filter-btn").classList.remove("d-none");
        }
        $("#loadingmap").removeClass("d-none");
        for(var i = 0; i < this.viewer.getNodesByType("section").length; i++) {
            if(this.viewer.getNodesByType("section")[i].id.indexOf("G_") !== -1) {
                this.sectionGroup = true;
                $(".section-lab").text("Group");
                break;
            } else if (this.viewer.getNodesByType("section")[i].id.indexOf("S_") !== -1) {
                this.sectionGroup = false;
                $(".section-lab").text("Section");
                break;
            }else if (this.viewer.getNodesByType("section")[i].id.indexOf("V_") !== -1) {
                this.sectionGroup = false;
                $(".section-lab").text("Section");
                break;
            }
        }
        this.interfaceMinimapHide();
        //this.interfaceSetTopview();
        //this.interfaceHideSectionLabel();
        this.interfaceHideRowLabel();
        this.interfaceHideSeatLabel();
        this.dvmStart();
    }

    interfaceBCSetColours(){
        for(var type in this.filtering_typereferenced){
            var ft = this.filtering_typereferenced[type];
            $("#top-guide").find(".section-lab").removeClass(type);
            $("#top-guide").find(".row-lab").removeClass(type);
            $("#top-guide").find(".seat-lab").removeClass(type);
            for(var i = 0;i < ft.length; i++){
                var section_id = ft[i];
                if(this.interface.topview.nodes_selected.length > 0 && this.interface.topview.nodes_selected[0].id == section_id){
                    $(".bread-crumbs").find(".breadcrumbs-val").addClass(type);
                    $("#top-guide").find(".section-lab").addClass(type);
                    $("#top-guide").find(".row-lab").addClass(type);
                    $("#top-guide").find(".seat-lab").addClass(type);
                }
                    
            }
        }
    }

    interfaceBCResetColours(){
        for(var type in this.filtering_typereferenced){
            $(".bread-crumbs").find(".breadcrumbs-val").removeClass(type);
            $("#top-guide").find(".section-lab").removeClass(type);
            $("#top-guide").find(".row-lab").removeClass(type);
            $("#top-guide").find(".seat-lab").removeClass(type);
        }
        return null;
    }

    interfaceGoViewer(){ 
        this.interfaceGalleryBtnShow();
        this.interface360BtnHide();
        this.interfaceFSBtnShow();
        if(this.viewer_3d.gallery) {
            this.viewer_3d.gallery.toggleCurrentView();
        }
        this.interfaceMinimapShow();
        this.interfaceNavigationShow(true);
        this.interfaceHomeBtnShow();
    }

    interface360BtnShow(){
        $(".viewer360-btn").removeClass("d-none");
        return null;
    }

    interface360BtnHide(){
        $(".viewer360-btn").addClass("d-none");
        return null;
    }

    interfaceGoGallery(){
        this.interfaceGalleryBtnHide();
        this.interface360BtnShow();

        this.interfaceFSBtnHide();
        if(this.viewer_3d.gallery) {
            this.viewer_3d.gallery.toggleCurrentView();
        }
        this.interfaceNavigationHide();
        this.interfaceHomeBtnHide();
        this.interfaceMinimapHide();
        $(".glide__arrow--right").html("<span class='interface-btn-standard icon-arrow-right'></span>");
        $(".glide__arrow--left").html("<span class='interface-btn-standard icon-arrow-left'></span>");

        return null;
    }

    interfaceSetNodeBC(obj) {
        if(this.viewer.getMapId() !== "blockmap"){
            // $("#top-guide .rows").addClass("d-none");
            // $("#top-guide .seat").addClass("d-none");
            $(".section").removeClass("d-none");
            $(".section-val").text(obj.id.split("_")[1].split("-")[0]);
            
            if(this.config.translation) {
                if(this.config.translationOptionsJson[obj.id]) {
                    var section = this.config.translationOptionsJson[obj.id]
                    $(".section-val").text(section);
                } else {
                    $(".section-val").text(obj.id.split("_")[1].split("-")[0]);
                }
            }
        }

        if(this.interface.filtering.ref && !this.interfaceIsMobile()){
            $("#top-guide").addClass("filtered");
            if(obj.id.indexOf("G_") !== -1) {
                $(".section-lab").html("Group");
            } else {
                $(".section-lab").html("Section");
            }
        } else {
            if(obj.id.indexOf("G_") !== -1) {
                $(".section-lab").html("Group");
            } else {
                $(".section-lab").html("Section");
            }
        }

        if(this.config.onlyViewer){
            var seat_id = obj.id.split("_")[1].split("-");
            var section;
            var row = seat_id[1];
            var seat = seat_id[2];
            
            if(this.config.translation) {
                section = this.config.translationOptionsJson["S_"+seat_id[0]]
            } else {
                section = seat_id[0];
            }

            if(section) {
                $("#top-guide .section").removeClass("d-none");
                $(".section-val").text(section);   
            }

            if(row){
                $("#top-guide .rows").removeClass("d-none");
                $(".row-val").text(row);
            }

            if(seat) {
                $("#top-guide .seat").removeClass("d-none");
                $(".seat-val").text(seat);
            }
        }

        var selected_nodes = this.viewer.getNodesByState("seat","selected");
        if(obj.type === 'seat'){
            var seat_id = selected_nodes[0].id.split("_")[1].split("-");
            $("#top-guide .rows").removeClass("d-none");
            $("#top-guide .seat").removeClass("d-none");
            $("#top-guide .section").removeClass("d-none");
            $(".section-val").text(seat_id[0]);
            if(this.config.translation) {
                if(this.config.translationOptionsJson["S_"+seat_id[0]]) {
                    var section = this.config.translationOptionsJson["S_"+seat_id[0]]
                    $(".section-val").text(section);
                } else {
                    $(".section-val").text(seat_id[0]);
                }
            }
            $(".row-val").text(seat_id[1]);
            $(".seat-val").text(seat_id[2]);
        } else if(obj.type === 'view3d') {
            var seat_id = obj.id.split("_")[1].split("-");
            $("#top-guide .rows").removeClass("d-none");
            $("#top-guide .seat").removeClass("d-none");
            $("#top-guide .section").removeClass("d-none");
            $(".section-val").text(seat_id[0]);   
            if(this.config.translation) {
                if(this.config.translationOptionsJson["S_"+seat_id[0]]) {
                    var section = this.config.translationOptionsJson["S_"+seat_id[0]]
                    $(".section-val").text(section);
                } else {
                    $(".section-val").text(seat_id[0]);
                }
            }     
            $(".row-val").text(seat_id[1]);
            $(".seat-val").text(seat_id[2]);
        }
    }

    interfaceSetTicketsURL(url){
        //Set link URL
        $(".tickets-link").attr("href",url);
        return null;
    }

    interfaceTicketsMobileBtnHide(){
        $("#interface-btns-mobile .tickets-link").addClass("d-none");
        return null;
    }

    interfaceTicketsMobileBtnShow(){
        $("#interface-btns-mobile .tickets-link").removeClass("d-none");
        return null;
    }

    interfaceNavigationShow(force){
        let current_mapid = this.interface.topview.nodes_selected[0].id;
        var current_sectorid = current_mapid.split("-")[0];
        var current_rowid = current_mapid.split("-")[0] + "-" + current_mapid.split("-")[1];
        var current_seatid = current_mapid;

        if(this.config.navigationPluginCheck || this.config.navigation || this.config.indoorPluginCheck){
            if(this.navigation.outdoor.seats && this.navigation.outdoor.seats[current_seatid]) {
                $(".navigation").removeClass("d-none");
            } else if(this.navigation.outdoor.rows && this.navigation.outdoor.rows[current_rowid]) {
                $(".navigation").removeClass("d-none");
            } else if(this.navigation.outdoor.sections && this.navigation.outdoor.sections[current_sectorid]) {
                $(".navigation").removeClass("d-none");
            } else if(force) {
                $(".navigation").removeClass("d-none");
            }
        }
    }

    interfaceNavigationHide(){
        $(".navigation").addClass("d-none");
    }

    interfaceSetNavigationReverse(){
        var aux = this.interface.navigation.to;
        this.interface.navigation.to = this.interface.navigation.from;
        this.interface.navigation.from  = aux;
        return this.interface.navigation;
    }

    interfaceGetNavigation(){
        return this.interface.navigation;
    }

    interfaceSetInitialNavigation(){
        if(this.config.indoorPluginCheck) {
            var navfromviewer = this.viewer_3d.indoor.getIndoorRelatedView();
            if(navfromviewer){
                var toObj = {
                    id : navfromviewer.view_id,
                    title : navfromviewer.label
                };
                (this.interface.navigation.to as any) = toObj;
                (this.interface.navigation.from as any) = {id: this.viewer_3d.getViewId(), title:"Back to seat"};
            }
        } else {
            let current_mapid = this.interface.topview.nodes_selected[0].id;
            var navitem = null;
            var current_sectorid = current_mapid.split("-")[0];
            var current_rowid = current_mapid.split("-")[0] + "-" + current_mapid.split("-")[1];
            var current_seatid = current_mapid;

            if(this.navigation.outdoor.seats && this.navigation.outdoor.seats[current_seatid]) {
                navitem = this.navigation.outdoor.seats[current_seatid];
            } else if(this.navigation.outdoor.rows && this.navigation.outdoor.rows[current_rowid]) {
                navitem = this.navigation.outdoor.rows[current_rowid];
            } else if(this.navigation.outdoor.sections && this.navigation.outdoor.sections[current_sectorid]) {
                navitem = this.navigation.outdoor.sections[current_sectorid];
            } else {
                navitem = null;
            }

            if(navitem){
                var parent = navitem;
                var outdoor = parent.node;
                if(!outdoor.title){ outdoor.title = "Acceso sala VIP"; }
                if(!parent.title){ parent.title = "Volver al asiento"; }
                this.interface.navigation.to = outdoor;
                (this.interface.navigation.from as any) = {id: current_sectorid, title:parent.title};
            } else {
                this.interface.navigation = {to: "", from: ""};
            }
        }
        
        return this.interface.navigation
    }

    // is_touch_enabled() { 
    //     return ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 );
    // }

    /* POPOVER */

    popoverCreate(obj) {
        if ("ontouchstart" in document.documentElement) {
            
        } else {
            this.popoverService.popoverDestroyAll();
            if(!this.interface.current){
                return;
            }
            /*Popover OBJ COnf*/
            var template;
            var seatview = this.viewer.getVisibleNodes("seat").length;

            let that = this;
            new Promise(function(success){
                success(that.config);
            }).then(function(m_config){
                $(".fpopover .ticket-lab").css("color", m_config['popoverLab']);
                $(".fpopover .ticket-val").css("color", m_config['popoverVal']);
                $(".fpopover").css("background-color", m_config['bgPopover']);
            });

            if(!this.config.popupJsonCheck) {
                if(!seatview && obj.type === "section") {
                    if($.inArray(obj.id, (this.filtering_typereferenced as any).vip) !== -1){
                        if(this.config.popupImg === "img") {
                            template = this.popoverService.popoverGetContentBlockVIPImg(obj);
                        } else {
                            template = this.popoverService.popoverGetContentBlockVIP(obj);
                        }
                    }else{
                        if(this.config.popupImg === "img") {
                            template = this.popoverService.popoverGetContentBlockImg(obj);
                        }else {
                            template = this.popoverService.popoverGetContentBlock(obj);
                        }
                    }
                } if(!seatview && obj.type === "areaprice") {
                    if($.inArray(obj.id, (this.filtering_typereferenced as any).vip) !== -1){
                        if(this.config.popupImg === "img") {
                            template = this.popoverService.popoverGetContentBlockVIPImg(obj);
                        } else {
                            template = this.popoverService.popoverGetContentBlockVIP(obj);
                        }
                    }else{
                        if(this.config.popupImg === "img") {
                            template = this.popoverService.popoverGetContentBlockImg(obj);
                        }else {
                            template = this.popoverService.popoverGetContentBlock(obj);
                        }
                    }
                } else if(obj.type === "general_admission") {
                    if($.inArray(obj.id, (this.filtering_typereferenced as any).vip) !== -1){
                        if(this.config.popupImg === "img") {
                            template = this.popoverService.popoverGetContentBlockVIPImg(obj);
                        } else {
                            template = this.popoverService.popoverGetContentBlockVIP(obj);
                        }
                    }else{
                        if(this.config.popupImg === "img") {
                            template = this.popoverService.popoverGetContentBlockImg(obj);
                        }else {
                            template = this.popoverService.popoverGetContentBlock(obj);
                        }
                    }
                } else if(obj.type === "seat"){
                    if($.inArray(obj.id.split("-")[0], (this.filtering_typereferenced as any).vip) !== -1){
                        if(this.config.popupImg === "img") {
                            template = this.popoverService.popoverGetContentSeatVIPImg(obj);
                        } else {
                            template = this.popoverService.popoverGetContentSeatVIP(obj);
                        }
                    }else{
                        if(this.config.popupImg === "img") {
                            template = this.popoverService.popoverGetContentSeatImg(obj);
                        } else {
                            template = this.popoverService.popoverGetContentSeat(obj);
                        }
                    }
                }
            } else {
                if(!seatview && obj.type === "section") {
                    if($.inArray(obj.id, (this.filtering_typereferenced as any).vip) !== -1){
                        if(this.config.popupImg === "img") {
                            template = this.popoverService.popoverGetContentBlockVIPImgJson(obj);
                        } else {
                            template = this.popoverService.popoverGetContentBlockVIPJson(obj);
                        }
                    }else{
                        if(this.config.popupImg === "img") {
                            template = this.popoverService.popoverGetContentBlockImgJson(obj);
                        }else {
                            template = this.popoverService.popoverGetContentBlockJson(obj);
                        }
                    }
                }if(!seatview && obj.type === "areaprice") {
                    if($.inArray(obj.id, (this.filtering_typereferenced as any).vip) !== -1){
                        if(this.config.popupImg === "img") {
                            template = this.popoverService.popoverGetContentBlockVIPImgJson(obj);
                        } else {
                            template = this.popoverService.popoverGetContentBlockVIPJson(obj);
                        }
                    }else{
                        if(this.config.popupImg === "img") {
                            template = this.popoverService.popoverGetContentBlockImgJson(obj);
                        }else {
                            template = this.popoverService.popoverGetContentBlockJson(obj);
                        }
                    }
                } else if(obj.type === "general_admission") {
                    if($.inArray(obj.id, (this.filtering_typereferenced as any).vip) !== -1){
                        if(this.config.popupImg === "img") {
                            template = this.popoverService.popoverGetContentBlockVIPImgJson(obj);
                        } else {
                            template = this.popoverService.popoverGetContentBlockVIPJson(obj);
                        }
                    }else{
                        if(this.config.popupImg === "img") {
                            template = this.popoverService.popoverGetContentBlockImgJson(obj);
                        }else {
                            template = this.popoverService.popoverGetContentBlockJson(obj);
                        }
                    }
                } else if(obj.type === "seat"){
                    if($.inArray(obj.id.split("-")[0], (this.filtering_typereferenced as any).vip) !== -1){
                        if(this.config.popupImg === "img") {
                            template = this.popoverService.popoverGetContentSeatVIPImgJson(obj);
                        } else {
                            template = this.popoverService.popoverGetContentSeatVIPJson(obj);
                        }
                    }else{
                        if(this.config.popupImg === "img") {
                            template = this.popoverService.popoverGetContentSeatImgJson(obj);
                        } else {
                            template = this.popoverService.popoverGetContentSeatJson(obj);
                        }
                    }
                }
            }

        
            if(template){
                var popover = $("<div class='fpopover row'>"+template+"</div>");
                $("#main-interface").append(popover);
        
                this.popoverService.popoverMove(popover,obj);

                if(this.config.popupImg === "img") {
                    this.viewer.getThumbnail(obj.id, this.venue_id,true).then((imgUrl) => {
                        $(".ticket-selected-view-img").html("");
                        $(".ticket-selected-view-img").css("background-image", "url('"+imgUrl+"')");
                    }).catch((e) => {
                        console.log(e);
                    })
                } else {

                }

            }

            return true;
        }

        
    }

    /* FILTERING */

    filtering_referenced = {};
    filtering_typereferenced = {};

    addItemRef(item,parentItem){
        //if(item.venue) {
        //    item.ref = item.title.replace(/[^a-zA-Z0-9]/g, "").replace(/ /g,'').toLowerCase();
        //} else {
            item.ref = item.title.replace(/[^a-zA-Z0-9]/g, "").replace(/ /g,'').toLowerCase();
            if(parentItem){
                if (parentItem.ref){
                    item.parentref = parentItem.ref;
                }
                if (parentItem.type){
                    item.parenttype = parentItem.type;
                }
            }
            this.filtering_referenced[item.ref]=item;
            if (item.type && !this.filtering_typereferenced[item.type]){
                this.filtering_typereferenced[item.type] = [];
            }
            if (item.parenttype && !this.filtering_typereferenced[item.parenttype]){
                this.filtering_typereferenced[item.parenttype] = [];
            }
    
            if(item.children){
                for(var i =0;i<item.children.length;i++){
                    item.children[i] = this.addItemRef(item.children[i],item);
                }
            }else if(item.nodes){
                var nodes_toadd = [];
                nodes_toadd = nodes_toadd.concat(item.nodes.sections);
                nodes_toadd = nodes_toadd.concat(item.nodes.rows);
                nodes_toadd = nodes_toadd.concat(item.nodes.seats);
                if(item.type){
                    this.filtering_typereferenced[item.type] = this.filtering_typereferenced[item.type].concat(nodes_toadd);
                }else{
                    this.filtering_typereferenced[item.parenttype] = this.filtering_typereferenced[item.parenttype].concat(nodes_toadd);
                }
            }
        //}

        return item;
    }

    filteringSeatmapApply() {
        if(!this.interface.filtering.ref){
            return;
        }
        var nodes = this.interface.filtering.nodes;
        var current_section = null;

        if(nodes.seats && nodes.seats.length > 0 || nodes.rows && nodes.rows.length > 0){
            var seatnodes = nodes.seats;
            var allseats = this.viewer.getNodesByType("seat");

            for(var i = 0;i < allseats.length; i++){
                var seat = allseats[i].id;
                var seat_arr = seat.split("-");
                var concat_row = seat_arr[0]+"-"+seat_arr[1];
                current_section = seat_arr[0];
                
                if(nodes.rows.indexOf(concat_row) > -1){
                    seatnodes = seatnodes.concat(seat);
                }
            }

            var nodes_toset = [];
            for(var i = 0;i < seatnodes.length; i++){
                if (current_section == seatnodes[i].split("-")[0]){
                    nodes_toset = nodes_toset.concat(seatnodes[i]);
                }
            }
            if(nodes_toset.length > 0){
                this.viewer.setAvailability("seat",seatnodes);
                this.interface.seatmap.nodes_selected = seatnodes;
            }
        }
    }

    filteringTopviewApply(){
        if(this.interface.filtering.nodes.sections) {
            if(this.interface.filtering.nodes.sections.length > 0){
                if(this.viewer.getNodesByType("section").length > 0) {
                    this.viewer.addNodesToGroup(this.viewer.getNodesByType("section"), "filtered");
                    this.viewer.setAvailability("section",this.interface.filtering.nodes.sections);
                } else if (this.viewer.getNodesByType("areaprice").length > 0) {
                    this.viewer.addNodesToGroup(this.viewer.getNodesByType("section"), "filtered");
                    this.viewer.setAvailability("areaprice",this.interface.filtering.nodes.sections);
                }
            }
        }
        return;
    }

    setBreadcrumbs(){
        var ref = this.interface.filtering.ref;
        let bc_val = "";
        this.interfaceBCResetColours();
        document.documentElement.style.setProperty('--label-color', this.config.ticketLab);
        if(ref){
            let current_fil = this.filtering_referenced[ref];
            bc_val = "<span class='breadcrumbs-atitle' style='width:auto; max-width: 111px; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;'>"+this.filtering_referenced[ref].title+"</span>";
            if(current_fil.parentref){
                bc_val = "<span style='width:auto; max-width: 111px; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;'>"+
                this.filtering_referenced[current_fil.parentref].title+"</span>"+
                "<div class='separator' style=' width: 1px; background: var(--label-color); margin-left: 12px; margin-right: 12px;opacity: 0.6;'></div>"+
                bc_val;
            }
            var type = current_fil.type;
            if(!type){
                type = current_fil.parenttype;
            }
            $(".bread-crumbs").find(".breadcrumbs-val").addClass(type);
            $(".section-lab").addClass(type);
            $(".row-lab").addClass(type);
            $(".seat-lab").addClass(type);
        }
        $(".breadcrumbs-val").html(bc_val);
    }

    filterBy(element, child?){
        this.interfaceResetFiltering();
        this.removeFilteringSectionOnBlockmap();

        if(this.viewer.getNodesByType("section").length > 0) {
            this.viewer.removeNodesFromGroup(this.viewer.getNodesByGroups("section","searched"), "searched");
        } else if(this.viewer.getNodesByType("areaprice").length > 0) {
            this.viewer.removeNodesFromGroup(this.viewer.getNodesByGroups("areaprice","searched"), "searched");
        }

        if(!$(element).hasClass("active")){
            var completeRef = $(element).attr("id");
            var ref = $(element).attr("id").split("-")[0];
            var parent = document.getElementById(completeRef).parentNode.parentNode;
            var btn = parent.firstElementChild;
            var id = $(btn).attr("id");
            this.removeOldFiltering();

            if(document.getElementById("accordionContainer")) {
                for(var q = 0; q < document.getElementById("accordionContainer").querySelectorAll('button').length; q++) {
                    document.getElementById("accordionContainer").querySelectorAll('button')[q].classList.remove("selected");
                }
            }

            if(document.getElementsByClassName("accordion").length > 0) {
                for(var h = 0; h < document.getElementsByClassName("accordion").length; h++) {
                    for(var w = 0; w < document.getElementsByClassName("accordion")[h].querySelectorAll('div').length; w++) {
                        document.getElementsByClassName("accordion")[h].querySelectorAll('div')[w].classList.remove("selected");
                    }
                }
            }

            document.getElementById(completeRef).classList.remove("selected");
            $(element).removeClass("active");
            $(element).addClass("active");
            document.getElementById(completeRef).classList.add("active");

            if(this.filtering_referenced[ref]) {
                var nodes = child.nodes;

                if(this.filtering_referenced[ref].venue || this.filtering_referenced[ref].map) {
                    //if(this.filtering_referenced[ref].venue !== this.actualVenue || this.filtering_referenced[ref].map !== this.actualMap) {
                        //for(var i = 0; i < this.filtering.filtering.length; i++){
                            //if(this.filtering.filtering[i].ref === ref){
                                //this.changeVenue(this.filtering_referenced[ref].venue, this.filtering_referenced[ref].map, this.filtering_referenced[ref].minimap_id);
                                if(child.venue){
                                    this.dvmStartFiltering(child.venue, child.map, child.minimap_id, child.nodes.sections)
                                } else {
                                    this.dvmStartFiltering(this.filtering_referenced[ref].venue, this.filtering_referenced[ref].map, this.filtering_referenced[ref].minimap_id, this.filtering_referenced[ref].nodes.sections)
                                }
                                //this.dvmStart();
                            //}
                        //}
                    //} 
                } else if (this.filtering_referenced[id]) {
                    if(this.filtering_referenced[id].venue || this.filtering_referenced[id].map) {
                        //if(this.filtering_referenced[ref].venue !== this.actualVenue || this.filtering_referenced[ref].map !== this.actualMap) {
                            //for(var i = 0; i < this.filtering.filtering.length; i++){
                                //if(this.filtering.filtering[i].ref === id){
                                    //this.changeVenue(this.filtering_referenced[id].venue, this.filtering_referenced[id].map, this.filtering_referenced[id].minimap_id);
                                    if(child.venue){
                                        this.dvmStartFiltering(child.venue, child.map, child.minimap_id, child.children)
                                    } else {
                                        this.dvmStartFiltering(this.filtering_referenced[id].venue, this.filtering_referenced[id].map, this.filtering_referenced[id].minimap_id, this.filtering_referenced[id].children)
                                    }
                                    //this.dvmStart();
                                //}
                            //}
                        //} 
                    }
                }

                if(this.filtering_referenced[ref].children) {
                    var ix = element.querySelector("i");
    
                    if(document.getElementById(ref).classList.contains("active")){
                        ix.classList.remove("icon-arrow-right");
                        ix.classList.add("icon-arrow-down");
                    }else{
                        ix.classList.remove("icon-arrow-down");
                        ix.classList.add("icon-arrow-right");
                    }
    
                    var dropItem = $(element).next(".accordion");

                    if (document.getElementById(completeRef).classList.contains("active")) {
                        $(dropItem).attr("style","height: auto");
                    } else {
                        $(dropItem).removeAttr("style");
                    };
                }
                
                document.getElementById(completeRef).classList.add("selected");

                if(this.viewer.getNodesByType("section").length > 0) {
                    if(nodes && nodes.sections) {
                        this.viewer.addNodesToGroup(nodes.sections, "filtered");
                        this.viewer.addNodesToGroup(nodes.sections, "searched");
                        this.viewer.setAvailability("section",nodes.sections);
                    }
                } else if (this.viewer.getNodesByType("areaprice").length > 0) {
                    this.viewer.addNodesToGroup(nodes.sections, "filtered");
                    this.viewer.addNodesToGroup(nodes.sections, "searched");
                    this.viewer.setAvailability("areaprice",nodes.sections);
                }

                if(this.config.unavailable) {
                    if(this.viewer.getNodesByType("section").length > 0) {
                        if(this.config.unavailableOptionsJson[this.viewer.getMapId()]){
                            this.viewer.setUnavailable("section", this.config.unavailableOptionsJson[this.viewer.getMapId()]['sections'])
                        } else if(this.config.unavailableOptionsJson[this.viewer.getVenueId()]){
                            this.viewer.setUnavailable("section", this.config.unavailableOptionsJson[this.viewer.getVenueId()]['sections'])
                        }
                    } else if (this.viewer.getNodesByType("areaprice").length > 0) {
                        if(this.config.unavailableOptionsJson[this.viewer.getMapId()]){
                            this.viewer.setUnavailable("areaprice", this.config.unavailableOptionsJson[this.viewer.getMapId()]['sections'])
                        } else if(this.config.unavailableOptionsJson[this.viewer.getVenueId()]){
                            this.viewer.setUnavailable("areaprice", this.config.unavailableOptionsJson[this.viewer.getVenueId()]['sections'])
                        }
                    }
                }

                //this.interface.topview.nodes_selected = nodes.sections;
                this.interface.filtering.nodes = nodes;
                this.interface.filtering.ref = ref;
                //$("#top-guide .section").addClass("d-none");
                if(this.interfaceGetCurrent()=='seatmap'){
                    this.interfaceGoHome();
                }
            } else if (this.filtering_referenced[id]) {
                var nodes = child.nodes;

                if(this.filtering_referenced[id].venue || this.filtering_referenced[id].map) {
                    //if(this.filtering_referenced[ref].venue !== this.actualVenue || this.filtering_referenced[ref].map !== this.actualMap) {
                        for(var i = 0; i < this.filtering.filtering.length; i++){
                            if(this.filtering.filtering[i].ref === ref){
                                if(child.venue){
                                    this.changeVenue(child.venue, child.map, child.minimap_id);
                                } else {
                                    this.changeVenue(this.filtering_referenced[id].venue, this.filtering_referenced[id].map, this.filtering_referenced[id].minimap_id);
                                }
                                this.dvmStart();
                            }
                        }
                    //} 
                } 

                document.getElementById(completeRef).classList.add("selected");

                if(this.viewer.getNodesByType("section").length > 0) {
                    this.viewer.addNodesToGroup(this.viewer.getNodesByType("section"), "filtered");
                    this.viewer.addNodesToGroup(this.viewer.getNodesByType("section"), "searched");
                    this.viewer.setAvailability("section",nodes.sections);
                } else if (this.viewer.getNodesByType("areaprice").length > 0) {
                    this.viewer.addNodesToGroup(this.viewer.getNodesByType("areaprice"), "filtered");
                    this.viewer.addNodesToGroup(this.viewer.getNodesByType("areaprice"), "searched");
                    this.viewer.setAvailability("areaprice",nodes.sections);
                }

                if(this.config.unavailable) {
                    if(this.viewer.getNodesByType("section").length > 0) {
                        if(this.config.unavailableOptionsJson[this.viewer.getMapId()]){
                            this.viewer.setUnavailable("section", this.config.unavailableOptionsJson[this.viewer.getMapId()]['sections'])
                        } else if(this.config.unavailableOptionsJson[this.viewer.getVenueId()]){
                            this.viewer.setUnavailable("section", this.config.unavailableOptionsJson[this.viewer.getVenueId()]['sections'])
                        }
                    } else if (this.viewer.getNodesByType("areaprice").length > 0) {
                        if(this.config.unavailableOptionsJson[this.viewer.getMapId()]){
                            this.viewer.setUnavailable("areaprice", this.config.unavailableOptionsJson[this.viewer.getMapId()]['sections'])
                        } else if(this.config.unavailableOptionsJson[this.viewer.getVenueId()]){
                            this.viewer.setUnavailable("areaprice", this.config.unavailableOptionsJson[this.viewer.getVenueId()]['sections'])
                        }
                    }
                }

                //this.interface.topview.nodes_selected = nodes.sections;
                this.interface.filtering.nodes = nodes;
                this.interface.filtering.ref = ref;
                //$("#top-guide .section").addClass("d-none");
                if(this.interfaceGetCurrent()=='seatmap'){
                    this.interfaceGoHome();
                }
            } else {
                for(var i = 0; i < this.filtering.filtering.length; i++){
                    if(this.filtering.filtering[i].ref === ref){
                        this.changeVenue(this.filtering.filtering[i].venue, this.filtering.filtering[i].map, this.filtering.filtering[i].minimap_id);
                        this.dvmStart();
                    } 
                }
            }
        }else{
            var completeRef = $(element).attr("id");
            var ref = $(element).attr("id").split("-")[0];
            document.getElementById(completeRef).classList.remove("selected");
            document.getElementById(completeRef).classList.remove("active")
            $(element).removeClass("active");

            for(var q = 0; q < document.getElementById("accordionContainer").querySelectorAll('button').length; q++) {
                document.getElementById("accordionContainer").querySelectorAll('button')[q].classList.remove("selected");
            }

            if(document.getElementsByClassName("accordion").length > 0) {
                for(var w = 0; w < document.getElementsByClassName("accordion")[0].querySelectorAll('div').length; w++) {
                    document.getElementsByClassName("accordion")[0].querySelectorAll('div')[w].classList.remove("selected");
                }
            }

            this.removeOldFiltering();
            this.removeFilteringSectionOnBlockmap();
            this.dvmSetFullAvailability(this.viewer);

            if(this.filtering_referenced[ref].children) {
                var ix = element.querySelector("i");

                if(document.getElementById(ref).classList.contains("active")){
                    ix.classList.remove("icon-arrow-right");
                    ix.classList.add("icon-arrow-down");
                }else{
                    ix.classList.remove("icon-arrow-down");
                    ix.classList.add("icon-arrow-right");
                }

                var dropItem = $(element).next(".accordion");
                if (document.getElementById(ref).classList.contains("active")) {
                    $(dropItem).attr("style","height: auto");
                } else {
                    $(dropItem).removeAttr("style");
                };
            }
        }
        this.setBreadcrumbs();

        if(this.interfaceIsMobile()){
            $("#menu").attr("style","display: none");
            this.menuClose();
        }
        return null;
    }

    removeOldFiltering(){
        //remove old filtering
        $(".filter").removeClass("active");

        if(this.viewer.getNodesByType("section").length > 0) {
            var nodes = this.viewer.getNodesByTag("section","vipFilter");
            nodes = nodes.concat(this.viewer.getNodesByTag("section","regFilter"));
            this.viewer.setAvailability("section",this.viewer.getNodesByType("section"));
            $("#top-guide").removeClass("filtered");
            $(".section-lab").html("Section");
            if(this.config.unavailable) {
                if(this.config.unavailableOptionsJson[this.viewer.getMapId()]) {
                    this.viewer.setUnavailable("section", this.config.unavailableOptionsJson[this.viewer.getMapId()]['sections'])
                } else if(this.config.unavailableOptionsJson[this.viewer.getVenueId()]){
                    this.viewer.setUnavailable("section", this.config.unavailableOptionsJson[this.viewer.getVenueId()]['sections'])
                }
            }
        } else if (this.viewer.getNodesByType("areaprice").length > 0) {
            var nodes = this.viewer.getNodesByTag("areaprice","vipFilter");
            nodes = nodes.concat(this.viewer.getNodesByTag("areaprice","regFilter"));
            this.viewer.setAvailability("areaprice",this.viewer.getNodesByType("areaprice"));
            $("#top-guide").removeClass("filtered");
            $(".section-lab").html("Section");
            if(this.config.unavailable) {
                if(this.config.unavailableOptionsJson[this.viewer.getMapId()]) {
                    this.viewer.setUnavailable("areaprice", this.config.unavailableOptionsJson[this.viewer.getMapId()]['sections'])
                } else if(this.config.unavailableOptionsJson[this.viewer.getVenueId()]){
                    this.viewer.setUnavailable("areaprice", this.config.unavailableOptionsJson[this.viewer.getVenueId()]['sections'])
                }
            }
        }

        return null;
    }

    drop = document.getElementsByClassName("drop accordionparent");

    menuToggle(event){
        var element = event.currentTarget;
        let action = $(element).attr("data-action");
        if(action === 'open'){
            this.menuOpen();
        }else{
            this.menuClose();
        }
        return null;
    }

    menuOpen(){
        $("#menu").attr("data-status","opened");
        $(".menu-btn").attr("data-action","close");
        $(".menu-btn").find("i").removeClass("icon-menu").addClass("icon-close");
        if(this.interfaceIsMobile()){
            $("#menu").attr("style","display: block");
        }
        return null;
    }

    menuClose(){
        $("#menu").attr("data-status","closed");
        $(".menu-btn").attr("data-action","open");
        $(".menu-btn").find("i").removeClass("icon-close").addClass("icon-menu");
        if(this.interfaceIsMobile()){
            $("#menu").attr("style","display: none");
        }
        return null;
    }

    menuBtnHide(){
        if(this.interfaceIsMobile()){
            $("#interface-btns-mobile .menu-btn").addClass("d-none");
        }else{
            $("#topbar .menu-btn").removeClass("d-md-block");
            $("#topbar .menu-btn").addClass("d-none");
        }
        return null;
    }

    menuBtnShow(){
        if(this.filtering && this.filtering.filtering && !this.filtering.filtering.length){
            //***** Add hidden class to the btn dom
            //**** to remove the delay on hidden
            this.menuBtnHide();
            return;
        }

        if(this.interfaceIsMobile()){
            $("#interface-btns-mobile .menu-btn").removeClass("d-none");
        }else{
            $("#topbar .menu-btn").removeClass("d-none");
            $("#topbar .menu-btn").addClass("d-md-block");
        }

        return null;
    }

    breadcrumbsShowToggle(){
        if(this.interfaceIsMobile()){
            $("#interface-btns-mobile .bread-crumbs").removeClass("d-none");
            $("#topbar .bread-crumbs").addClass("d-none");
        }else{
            $("#topbar .bread-crumbs").removeClass("d-none");
            $("#interface-btns-mobile .bread-crumbs").addClass("d-none");
        }
        return null;
    }

    breadcrumbsHide(){
        if(this.interfaceIsMobile()){
            $("#interface-btns-mobile .bread-crumbs").addClass("d-none");
        }else{
            $("#topbar .bread-crumbs").addClass("d-none");
        }
        return null;
    }

    breadcrumbsMobileHide(){
        $("#interface-btns-mobile .bread-crumbs").addClass("d-none");

        return null;
    }

    menuAddEventListeners(){
        let accordion_btn = $(".accordionparent").find("button");

        for (var i = 0; i < this.drop.length; i++) {
            accordion_btn[i].addEventListener("click", function() {
                var i = this.querySelector("i");
                if(i.classList.contains("icon-arrow-right")){
                    i.classList.remove("icon-arrow-right");
                    i.classList.add("icon-arrow-down");
                }else{
                    i.classList.remove("icon-arrow-down");
                    i.classList.add("icon-arrow-right");
                }
                var dropItem = $(this).next(".accordion");
                $(this).toggleClass("active");
                if ($(this).hasClass("active")) {
                    $(dropItem).attr("style","height: auto");
                } else {
                    $(dropItem).removeAttr("style");
                };
            });
        }

        return null;
    }

    constructor(private sanitization:DomSanitizer, private popoverService: PopoverService, 
        @Inject(MINISITE_CONFIGURATION) private config: MinisiteConfiguration) {
    }
}

function windowLoop() {
    if(this.interfaceIsMobile()) {
        var bh = window.innerHeight - $("#header-wrap").innerHeight() - $("#topbar").innerHeight() - $("#interface-btns-mobile").innerHeight();
    } else {
        var bh = window.innerHeight - $("#header-wrap").innerHeight() - $("#topbar").innerHeight();
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const iframe = urlParams.get("iframe");

    if(iframe === "true"){
        var bh = window.innerHeight - $("#topbar").innerHeight();
        $(".menu-btn").removeClass("d-none");
    } 

    $("#main-interface").css("height", bh);
    
    if(document.getElementById("main-interface")) {
        document.getElementById("main-interface").style.height = bh+"px";
    }

    if(document.getElementById("viewer")) {
        document.getElementById("viewer").style.height = bh+"px";
    }

    if(document.getElementById("blockmap-mini")) {
        document.getElementById("blockmap-mini").style.height = 100+"%";
    }

    $("#view3d").height(bh);

    if(typeof this.viewer !== 'undefined' && this.viewer.isLoaded()){
        if(this.interfaceIsMobile()){
            this.dvmHideLabs();
            $("#interface-btns-mobile").removeClass("d-none");
            $(".interface-btns-desktop").addClass("d-none");
        }else{
            $("#interface-btns-mobile").addClass("d-none");
            $(".interface-btns-desktop").removeClass("d-none");
            this.dvmShowLabs();
        }

        if(this.interfaceGetCurrent() !== 'view3d'){
            this.breadcrumbsShowToggle();
        }

        if(!this.interfaceIsMobile() && this.viewer.scaling_factor > this.viewer.min_scaling_factor && this.interface.current != "view3d") {
            this.interfaceMoveArrowsShow();
        } else {
            this.interfaceMoveArrowsHide();
        }

        this.interfaceMoveArrowsHideOnLimits();

        if(this.integrationType === "bumper" && this.config.hasMinimap) {
            if (this.viewer.scaling_factor > this.viewer.min_scaling_factor * 1.3 && !$("#blockmap").hasClass("d-none")) {
                $(".minimap").removeClass("d-none");
            } else {
                $(".minimap").addClass("d-none");
            }
        }
    }
    
    this.interface.window.animation = requestAnimationFrame(this.windowLoop);
}

interface v3d {
    loadView3d(pano_id): Promise<void>;
    init(venue_id:string): Promise<void>;
    close();
    toggleFullscreen();
    getThumbnail(nav);
    getViewId();
    checkView3d(obj): Promise<boolean>;
    gallery;
    interface;
    indoor;
    navigation;
    related;
    visual_id;
}

class oldViewer implements v3d {
    private _old_instance;
    private tk3d;
    gallery;
    interface;
    indoor;
    navigation;
    related;
    visual_id;

    constructor() {}

    getThumbnail(){}
    getViewId(){}

    checkView3d(obj): Promise<boolean>{
        return this._old_instance.has3DView(obj);
    }

    loadView3d(pano_id): Promise<void> {
        this._old_instance.fixedAspectRatio(false);
        return new Promise((resolve,reject) => {
            this._old_instance.load(pano_id.view_id, function() {
                resolve();
            });
        });
    }
    
    init(venue_id:string): Promise<void>{
        if(this.tk3d == null || this._old_instance == null){
            this.tk3d = new TICKETING3D(venue_id);
            this._old_instance = this.tk3d.loadModule({
                module: "view3d",
                container: "view3d",
                plugins:["NavigationPlugin"],
                config:{
                    callbacks:{
                        navigation: destinationId => {
                            var pano_id = {view_id: destinationId}
                            this.loadView3d(pano_id);
                        }
                    }
                }
            });
    
            return new Promise((resolve,reject) => {
                this._old_instance.init(() => {
                    this.gallery = this._old_instance.gallery;
                    this.interface = this._old_instance.interface;
    
                    resolve();
                });
            });
        }
    }

    toggleFullscreen(): Promise<void>{
        let data = this._old_instance.toggleFullscreen();
        return new Promise(resolve => {
            resolve(data);
        })
    }

    close(){
        this._old_instance.close();
    }
}

class newViewer implements v3d {
    private _new_instance;
    venue_id:string;
    gallery;
    indoor;
    navigation;
    interface;
    library;
    viewer3d_input_options;
    related;
    visual_id;
    
    constructor(config) {
        this.library = config.libraryVersion ? config.libraryVersion : 'stable';
        this.viewer3d_input_options = {
            version: this.library,
            container: "view3d",
            plugins: ["gallery", "indoor", "navigation", "related"],
            callbacks: {
                fullscreen_enabled: result => {
                    $(".fsclose-btn").removeClass("d-none");
                },
                fullscreen_disabled: result => {
                    $(".fsclose-btn").addClass("d-none");
                },
                click_navigation_node: obj => {
                    var nav = obj.navigation;
                    if (nav) {
                        this._new_instance.loadView3d(nav);
                    }
                }
            }
        };
    }

    checkView3d(obj): Promise<boolean>{
        return this._new_instance.checkView3d(obj);
    }

    getViewId(){
       return this._new_instance.getViewId();
    }

    getThumbnail(obj): Promise<string>{
        return this._new_instance.getThumbnail(obj,true);
    }

    loadView3d(obj): Promise<void> {
        this._new_instance.open();
        //const obj = { venue_id: this.venue_id, view_id: pano_id }
        return this._new_instance.loadView3d(obj);
    }
    
    init(venue_id:string): Promise<void>{
        this.venue_id = venue_id;
        return DVM.loadModule("3d_viewer", this.viewer3d_input_options)
            .then((res)=>{
                this._new_instance = res;
                this._new_instance.flags.fixed_aspect_ratio = false;
                this._new_instance.indoor.flags.show_indoor_preview = false;

                this.gallery = this._new_instance.gallery;
                this.interface = this._new_instance.interface;
                this.indoor = this._new_instance.indoor;
                this.navigation = this._new_instance.navigation;
                this.related = this._new_instance.related;
                return Promise.resolve();
            })
    }

    toggleFullscreen(){
        return this._new_instance.toggleFullscreen();
    }

    close(){
        this._new_instance.close();
        this._new_instance.reset();
    }
}