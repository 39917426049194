import { Component, OnInit, Inject } from '@angular/core';
import { GeneralState, Tk3dService } from 'ngx-tk3d';
import { MINISITE_CONFIGURATION, MinisiteConfiguration } from '../minisite.configuration';
declare var $:any;

@Component({
  selector: 'app-tk3d',
  templateUrl: './tk3d.component.html',
  styleUrls: ['./tk3d.component.scss']
})
export class TK3DComponent implements OnInit {

  bgHeader = this.minisiteConfig.bgHeader;
  iframe = false;

  get status(): GeneralState {
    return this.tk3d.tk3dStatus;
  }

  constructor (private tk3d: Tk3dService, @Inject(MINISITE_CONFIGURATION) private minisiteConfig: MinisiteConfiguration) {
    
  }

  ngOnInit() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const iframe = urlParams.get("iframe");
    if(iframe === "true"){
      this.iframe = true;
    } else {
      this.iframe = false;
    }

    if(this.minisiteConfig.blockFiltered) {
      document.documentElement.style.setProperty('--block-filtered', this.minisiteConfig.blockFiltered);
    } else {
      document.documentElement.style.setProperty('--block-filtered', this.minisiteConfig.blockAvailable);
    }

    if(this.minisiteConfig.blockFilteredOpacity) {
      document.documentElement.style.setProperty('--block-filtered-opacity', this.minisiteConfig.blockFilteredOpacity);
    } else {
      document.documentElement.style.setProperty('--block-filtered-opacity', this.minisiteConfig.blockAvailableOpacity);
    }

    if(this.minisiteConfig.blockFilteredHover) {
      document.documentElement.style.setProperty('--block-filtered-hover', this.minisiteConfig.blockFilteredHover);
    } else {
      document.documentElement.style.setProperty('--block-filtered-hover', this.minisiteConfig.blockAvailableHover);
    }

    if(this.minisiteConfig.blockFilteredHoverOpacity) {
      document.documentElement.style.setProperty('--block-filtered-hover-opacity', this.minisiteConfig.blockFilteredHoverOpacity);
    } else {
      document.documentElement.style.setProperty('--block-filtered-hover-opacity', this.minisiteConfig.blockAvailableHoverOpacity);
    }

    if(this.minisiteConfig.blockFilteredUnavailable) {
      document.documentElement.style.setProperty('--block-filtered-unavailable', this.minisiteConfig.blockFilteredUnavailable);
    } else {
      document.documentElement.style.setProperty('--block-filtered-unavailable', this.minisiteConfig.blockUnavailable);
    }

    if(this.minisiteConfig.blockFilteredUnavailableOpacity) {
      document.documentElement.style.setProperty('--block-filtered-unavailable-opacity', this.minisiteConfig.blockFilteredUnavailableOpacity);
    } else {
      document.documentElement.style.setProperty('--block-filtered-unavailable-opacity', this.minisiteConfig.blockUnavailableOpacity);
    }

    if(this.minisiteConfig.blockFilteredStroke) {
      document.documentElement.style.setProperty('--block-filtered-stroke', this.minisiteConfig.blockFilteredStroke);
    } else if(this.minisiteConfig.blockFiltered){
      document.documentElement.style.setProperty('--block-filtered-stroke', this.minisiteConfig.blockFiltered);
    } else {
      document.documentElement.style.setProperty('--block-filtered-stroke', this.minisiteConfig.blockAvailable);
    }

    if(this.minisiteConfig.blockFilteredStrokeOpacity) {
      document.documentElement.style.setProperty('--block-filtered-stroke-opacity', this.minisiteConfig.blockFilteredStrokeOpacity);
    } else if(this.minisiteConfig.blockFilteredOpacity){
      document.documentElement.style.setProperty('--block-filtered-stroke-opacity', this.minisiteConfig.blockFilteredOpacity);
    } else {
      document.documentElement.style.setProperty('--block-filtered-stroke-opacity', this.minisiteConfig.blockAvailableOpacity);
    }

    if(this.minisiteConfig.blockFilteredHoverStroke) {
      document.documentElement.style.setProperty('--block-filtered-hover-stroke', this.minisiteConfig.blockFilteredHoverStroke);
    } else if(this.minisiteConfig.blockFilteredHover) {
      document.documentElement.style.setProperty('--block-filtered-hover-stroke', this.minisiteConfig.blockFilteredHover);
    } else {
      document.documentElement.style.setProperty('--block-filtered-hover-stroke', this.minisiteConfig.blockAvailableHover);
    }

    if(this.minisiteConfig.blockFilteredHoverStrokeOpacity) {
      document.documentElement.style.setProperty('--block-filtered-hover-stroke-opacity', this.minisiteConfig.blockFilteredHoverStrokeOpacity);
    } else if(this.minisiteConfig.blockFilteredHoverOpacity) {
      document.documentElement.style.setProperty('--block-filtered-hover-stroke-opacity', this.minisiteConfig.blockFilteredHoverOpacity);
    } else {
      document.documentElement.style.setProperty('--block-filtered-hover-stroke-opacity', this.minisiteConfig.blockAvailableHoverOpacity);
    }
    
    if(this.minisiteConfig.blockFilteredUnavailableStroke) {
      document.documentElement.style.setProperty('--block-filtered-unavailable-stroke', this.minisiteConfig.blockFilteredUnavailableStroke);
    } else if(this.minisiteConfig.blockFilteredUnavailable){
      document.documentElement.style.setProperty('--block-filtered-unavailable-stroke', this.minisiteConfig.blockFilteredUnavailable);
    } else {
      document.documentElement.style.setProperty('--block-filtered-unavailable-stroke', this.minisiteConfig.blockUnavailable);
    }

    if(this.minisiteConfig.blockFilteredUnavailableStrokeOpacity) {
      document.documentElement.style.setProperty('--block-filtered-unavailable-stroke-opacity', this.minisiteConfig.blockFilteredUnavailableStrokeOpacity);
    } else if(this.minisiteConfig.blockFilteredUnavailableOpacity) {
      document.documentElement.style.setProperty('--block-filtered-unavailable-stroke-opacity', this.minisiteConfig.blockFilteredUnavailableOpacity);
    } else {
      document.documentElement.style.setProperty('--block-filtered-unavailable-stroke-opacity', this.minisiteConfig.blockUnavailableOpacity);
    }

    if(this.minisiteConfig.blockAvailableOpacity) {
      document.documentElement.style.setProperty('--block-available-opacity', this.minisiteConfig.blockAvailableOpacity);
    } else {
      document.documentElement.style.setProperty('--block-available-opacity', this.minisiteConfig.blockOpacity);
    }

    if(this.minisiteConfig.blockAvailableHoverOpacity) {
      document.documentElement.style.setProperty('--block-available-hover-opacity', this.minisiteConfig.blockAvailableHoverOpacity);
    } else {
      document.documentElement.style.setProperty('--block-available-hover-opacity', this.minisiteConfig.blockOpacity);
    }

    if(this.minisiteConfig.blockSelectedOpacity) {
      document.documentElement.style.setProperty('--block-selected-opacity', this.minisiteConfig.blockSelectedOpacity);
    } else {
      document.documentElement.style.setProperty('--block-selected-opacity', this.minisiteConfig.blockOpacity);
    }

    if(this.minisiteConfig.blockSelectedHoverOpacity) {
      document.documentElement.style.setProperty('--block-selected-hover-opacity', this.minisiteConfig.blockSelectedHoverOpacity);
    } else {
      document.documentElement.style.setProperty('--block-selected-hover-opacity', this.minisiteConfig.blockOpacity);
    }

    if(this.minisiteConfig.blockUnavailableOpacity) {
      document.documentElement.style.setProperty('--block-unavailable-opacity', this.minisiteConfig.blockUnavailableOpacity);
    } else {
      document.documentElement.style.setProperty('--block-unavailable-opacity', this.minisiteConfig.blockOpacity);
    }

    if(this.minisiteConfig.blockUnavailableStroke) {
      document.documentElement.style.setProperty('--block-unavailable-stroke', this.minisiteConfig.blockUnavailableStroke);
    } else {
      document.documentElement.style.setProperty('--block-unavailable-stroke', this.minisiteConfig.blockUnavailable);
    }

    if(this.minisiteConfig.blockUnavailableStrokeOpacity) {
      document.documentElement.style.setProperty('--block-unavailable-stroke-opacity', this.minisiteConfig.blockUnavailableStrokeOpacity);
    } else {
      document.documentElement.style.setProperty('--block-unavailable-stroke-opacity', this.minisiteConfig.blockUnavailableOpacity);
    }

    if(this.minisiteConfig.blockAvailableStroke) {
      document.documentElement.style.setProperty('--block-available-stroke', this.minisiteConfig.blockAvailableStroke);
    } else {
      document.documentElement.style.setProperty('--block-available-stroke', this.minisiteConfig.blockAvailable);
    }

    if(this.minisiteConfig.blockAvailableStrokeOpacity) {
      document.documentElement.style.setProperty('--block-available-stroke-opacity', this.minisiteConfig.blockAvailableStrokeOpacity);
    } else {
      document.documentElement.style.setProperty('--block-available-stroke-opacity', this.minisiteConfig.blockAvailableOpacity);
    }

    if(this.minisiteConfig.blockAvailableHoverStroke) {
      document.documentElement.style.setProperty('--block-available-hover-stroke', this.minisiteConfig.blockAvailableHoverStroke);
    } else {
      document.documentElement.style.setProperty('--block-available-hover-stroke', this.minisiteConfig.blockAvailableHover);
    }

    if(this.minisiteConfig.blockAvailableHoverStrokeOpacity) {
      document.documentElement.style.setProperty('--block-available-hover-stroke-opacity', this.minisiteConfig.blockAvailableHoverStrokeOpacity);
    } else {
      document.documentElement.style.setProperty('--block-available-hover-stroke-opacity', this.minisiteConfig.blockAvailableHoverOpacity);
    }

    if(this.minisiteConfig.blockSelectedStroke) {
      document.documentElement.style.setProperty('--block-selected-stroke', this.minisiteConfig.blockSelectedStroke);
    } else {
      document.documentElement.style.setProperty('--block-selected-stroke', this.minisiteConfig.blockSelected);
    }

    if(this.minisiteConfig.blockSelectedStrokeOpacity) {
      document.documentElement.style.setProperty('--block-selected-stroke-opacity', this.minisiteConfig.blockSelectedStrokeOpacity);
    } else {
      document.documentElement.style.setProperty('--block-selected-stroke-opacity', this.minisiteConfig.blockSelectedOpacity);
    }

    if(this.minisiteConfig.blockSelectedHoverStroke) {
      document.documentElement.style.setProperty('--block-selected-hover-stroke', this.minisiteConfig.blockSelectedHoverStroke);
    } else {
      document.documentElement.style.setProperty('--block-selected-hover-stroke', this.minisiteConfig.blockSelectedHover);
    }

    if(this.minisiteConfig.blockSelectedHoverStrokeOpacity) {
      document.documentElement.style.setProperty('--block-selected-hover-stroke-opacity', this.minisiteConfig.blockSelectedHoverStrokeOpacity);
    } else {
      document.documentElement.style.setProperty('--block-selected-hover-stroke-opacity', this.minisiteConfig.blockSelectedHoverOpacity);
    }



    if(this.minisiteConfig.seatAvailableOpacity) {
      document.documentElement.style.setProperty('--seat-available-opacity', this.minisiteConfig.seatAvailableOpacity);
    } else {
      document.documentElement.style.setProperty('--seat-available-opacity', this.minisiteConfig.seatOpacity);
    }

    if(this.minisiteConfig.seatAvailableHoverOpacity) {
      document.documentElement.style.setProperty('--seat-available-hover-opacity', this.minisiteConfig.seatAvailableHoverOpacity);
    } else {
      document.documentElement.style.setProperty('--seat-available-hover-opacity', this.minisiteConfig.seatOpacity);
    }

    if(this.minisiteConfig.seatSelectedOpacity) {
      document.documentElement.style.setProperty('--seat-selected-opacity', this.minisiteConfig.seatSelectedOpacity);
    } else {
      document.documentElement.style.setProperty('--seat-selected-opacity', this.minisiteConfig.seatOpacity);
    }

    if(this.minisiteConfig.seatSelectedHoverOpacity) {
      document.documentElement.style.setProperty('--seat-selected-hover-opacity', this.minisiteConfig.seatSelectedHoverOpacity);
    } else {
      document.documentElement.style.setProperty('--seat-selected-hover-opacity', this.minisiteConfig.seatOpacity);
    }

    if(this.minisiteConfig.seatUnavailableOpacity) {
      document.documentElement.style.setProperty('--seat-unavailable-opacity', this.minisiteConfig.seatUnavailableOpacity);
    } else {
      document.documentElement.style.setProperty('--seat-unavailable-opacity', this.minisiteConfig.seatOpacity);
    }

    document.documentElement.style.setProperty('--block-available', this.minisiteConfig.blockAvailable);
    //document.documentElement.style.setProperty('--block-available-opacity', this.minisiteConfig.blockAvailableOpacity);
    document.documentElement.style.setProperty('--block-available-hover', this.minisiteConfig.blockAvailableHover);
    //document.documentElement.style.setProperty('--block-available-hover-opacity', this.minisiteConfig.blockAvailableHoverOpacity);
    document.documentElement.style.setProperty('--block-selected', this.minisiteConfig.blockSelected);
    //document.documentElement.style.setProperty('--block-selected-opacity', this.minisiteConfig.blockSelectedOpacity);
    document.documentElement.style.setProperty('--block-selected-hover', this.minisiteConfig.blockSelectedHover);
    //document.documentElement.style.setProperty('--block-selected-hover-opacity', this.minisiteConfig.blockSelectedHoverOpacity);
    document.documentElement.style.setProperty('--block-unavailable', this.minisiteConfig.blockUnavailable);
    //document.documentElement.style.setProperty('--block-unavailable-opacity', this.minisiteConfig.blockUnavailableOpacity);

    //document.documentElement.style.setProperty('--block-opacity', this.minisiteConfig.blockOpacity);
    //document.documentElement.style.setProperty('--seat-opacity', this.minisiteConfig.seatOpacity);

    document.documentElement.style.setProperty('--seat-available', this.minisiteConfig.seatAvailable);
    //document.documentElement.style.setProperty('--seat-available-opacity', this.minisiteConfig.seatAvailableOpacity);
    document.documentElement.style.setProperty('--seat-available-hover', this.minisiteConfig.seatAvailableHover);
    //document.documentElement.style.setProperty('--seat-available-hover-opacity', this.minisiteConfig.seatAvailableHoverOpacity);
    document.documentElement.style.setProperty('--seat-selected', this.minisiteConfig.seatSelected);
    //document.documentElement.style.setProperty('--seat-selected-opacity', this.minisiteConfig.seatSelectedOpacity);
    document.documentElement.style.setProperty('--seat-selected-hover', this.minisiteConfig.seatSelectedHover);
    //document.documentElement.style.setProperty('--seat-selected-hover-opacity', this.minisiteConfig.seatSelectedHoverOpacity);
    document.documentElement.style.setProperty('--seat-unavailable', this.minisiteConfig.seatUnavailable);
    //document.documentElement.style.setProperty('--seat-unavailable-opacity', this.minisiteConfig.seatUnavailableOpacity);

  }

}
