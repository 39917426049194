import {Injectable} from '@angular/core';
import {Handler, GeneralState, InteractionMessageProtocol} from 'ngx-tk3d';
declare var $:any;


@Injectable()
export class PopoverDestroy extends Handler {
    constructor() {
        super();
        this.handling['blockmap'] = ['mouseleave', 'loaded'];
        this.handling['seatmap'] = ['mouseleave', 'loaded'];
        this.handling['view3d'] = ['loaded'];
    }

    handle(message: InteractionMessageProtocol, status: GeneralState) {
        $(".popover").each(function(){
           $(this).popover("dispose").remove();
        });
    }
}
