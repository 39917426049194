import {Injectable, Inject, INJECTOR, Injector} from '@angular/core';
import {Handler, GeneralState, InteractionMessageProtocol, Tk3dService} from 'ngx-tk3d';
import { MINISITE_CONFIGURATION, MinisiteConfiguration } from '../minisite.configuration';
import { ResizeNormalService } from '../services/resize-normal.service';
import * as global from '../services/global-functions';
declare var $:any;


@Injectable()
export class BlockClickAppHandler extends Handler {
    constructor(private resizes: ResizeNormalService, @Inject(MINISITE_CONFIGURATION) private minisite_config: MinisiteConfiguration, private injector: Injector) {
        super();
        this.handling['blockmap'] = ['click'];
    }

    exceptions;

    handle(message: InteractionMessageProtocol, status: GeneralState) {
        if (message.emmitterSize === 'mini' || !status.blockmap.moduleRef['getElementById'](message.element.id).isAvailable()) {
            return;
        }
        const tk3d = this.injector.get(Tk3dService)

        global.selectedBlockWrite(message.element.id);

        if(this.minisite_config.translation) {
            if(this.minisite_config.translationOptionsJson[message.element.id]) {
                var section = this.minisite_config.translationOptionsJson[message.element.id]
                global.selectedBlockWrite("S_"+section);
            } else {
                global.selectedBlockWrite(message.element.id);
            }
        } 

        status.blockmap.selected = message.element.id;

        if(this.minisite_config.exceptionsJsonCheck) {
            this.exceptions = this.minisite_config.exceptionsJson['sections'];
        } else {
            this.exceptions = this.minisite_config.exceptions;
        }

        if(this.minisite_config.viewType === "seat") {
            if(this.exceptions && this.exceptions.indexOf(message.element.id) > -1){
                status.blockmap['size'] = 'mini';
                status.seatmap['size'] = 'mini';
                status.view3d['size'] = 'large';
                status.view3d.moduleRef['setContainer']('view3d');
                status.view3d.moduleRef['load'](message.element.id);
                status.blockmap.selected = message.element.id;
                status.view3d.visible = true;
            } else {
                status.seatmap.moduleRef.loadMap(message.element.id, () => {
                    const forcedMessage: InteractionMessageProtocol = {
                        event: 'loaded',
                        emmitterSize: 'large',
                        emitter: 'seatmap',
                        //element: {id:message.element.id, HTMLElement:null, status:null}
                    };
                    tk3d.handle(forcedMessage);
                });
                
                status.blockmap['size'] = 'mini';
                status.seatmap['size'] = 'large';
                status.seatmap.visible = true;
                status.view3d['size'] = 'mini';
                $('#seatmap').removeClass("d-none");
                if(this.minisite_config.hasMinimap) {
                    global.showMinimap(this.resizes.minimapModule);
                }
                //$("#view3d-box-view").addClass("d-none");
            }
        } else if(this.minisite_config.viewType === 'section') {
            if(this.exceptions && this.exceptions.indexOf(message.element.id) > -1){
                status.seatmap.moduleRef.loadMap(message.element.id, () => {
                    const forcedMessage: InteractionMessageProtocol = {
                        event: 'loaded',
                        emmitterSize: 'large',
                        emitter: 'seatmap',
                        //element: elementClicked as TK3dElement
                    };
                    tk3d.handle(forcedMessage);
                });

                status.blockmap['size'] = 'mini';
                status.seatmap['size'] = 'large';
                status.seatmap.visible = true;
                status.view3d['size'] = 'mini';
                $('#seatmap').removeClass("d-none");
                if(this.minisite_config.hasMinimap) {
                    global.showMinimap(this.resizes.minimapModule);
                }
                //$("#view3d-box-view").addClass("d-none");
            } else {
                status.blockmap['size'] = 'mini';
                status.seatmap['size'] = 'mini';
                status.view3d['size'] = 'large';
                status.view3d.moduleRef['setContainer']('view3d');
                status.view3d.moduleRef['load'](message.element.id);
                status.blockmap.selected = message.element.id;
                status.view3d.visible = true;
                message.emitter = "blockmap";
            }
        }

        status.resizeForce = true;

        $(".back-btn").removeClass("hidden");
        $("#filtering-wrap").removeClass("open");
        $("#filtering-wrap").addClass("closed");
        $("#filtering-wrap").css("left", "-230px");
        $("#menu").attr("data-status","closed");
        $(".menu-btn").attr("data-action","open");
        $(".menu-btn").find("i").removeClass("icon-arrow-down").addClass("icon-menu");
        global.selectedDataWrite(message.element.id);
        status.blockmap.moduleRef.setAvailability(true);
        status.blockmap.moduleRef.setElementAvailable(status.blockmap.selected);
        status.blockmap.moduleRef.select(message.element.id);
        status.blockmap.moduleRef['disableZoom'](true);
        global.interfaces.topview.nodes_selected = [message.element.id];
        $("#loading-container").removeClass("d-none");
        
        $(".popover").each(function(){
            $(this).popover("dispose").remove();
        });
    }
}
