import {Injectable, Inject} from '@angular/core';
import {Handler, GeneralState, InteractionMessageProtocol} from 'ngx-tk3d';
import { MinisiteConfiguration, MINISITE_CONFIGURATION } from '../minisite.configuration';
import { ResizeNormalService } from '../services/resize-normal.service';
import { environment } from '@environment';
import * as globalFunctions from '../services/global-functions';
declare var $:any;


@Injectable()
export class OnLoadMapHandler extends Handler {
    h: number;
    w: number;

    block;
    id: string;

    constructor( private resizes: ResizeNormalService, @Inject(MINISITE_CONFIGURATION) private minisite_config: MinisiteConfiguration) {
        super();
        this.handling['blockmap'] = ['loaded'];
        this.handling['seatmap'] = ['loaded'];
        this.handling['view3d'] = ['loaded'];
    }

    handle(message: InteractionMessageProtocol, status: GeneralState) {
        if(this.minisite_config.backgroundColor) {
            $("body").css("background-color", this.minisite_config.backgroundColor);
        } else {
            $("body").css("background-image", `url(https://3ddigitalvenue.com/3dmap/${environment.url}/` + this.minisite_config.minisiteUrl + "/bg.jpg)");
        }
        
        (window as any).blockmap = status.blockmap.moduleRef;
        (window as any).seatmap = status.seatmap.moduleRef;
        (window as any).view3d = status.view3d.moduleRef;      
        
        if(globalFunctions.interfaces.params.v360) {
            this.h = document.getElementById('main-interface').offsetHeight;
            document.getElementById('view3d').style.height = this.h + 'px';
            status.view3d.moduleRef['setContainer']('view3d');
            status.view3d.visible = true;
            status.blockmap['size'] = 'mini';
            status.seatmap['size'] = 'mini';
            status.blockmap['visible'] = false;
            status.seatmap['visible'] = false;
            status.view3d['size'] = 'large';
            status.view3d.moduleRef['fixedAspectRatio'](false);
            status.view3d.moduleRef['responsive'](true);
            status.resizeForce = true;
            message.emitter = 'view3d';
            status.view3d.moduleRef['open'];
            globalFunctions.selectedDataWrite(globalFunctions.interfaces.params.v360);
            $(".back-btn").addClass("d-none");
            $(".home-btn").addClass("d-none");
            $(".share-btn").addClass("d-none");
            $(".tickets-link").addClass("d-none");
            $(".search-bar").addClass("d-none");
            $(".bread-crumbs").addClass("d-none");
            $(".neighbour-lab").addClass("d-none");
            $("#topbar").css("justify-content","center");
            $("#loading-container").addClass("d-none");
            $("#minimap").addClass("d-none");
        } else {
            //if (message.emmitterSize === 'large') {
                if (message.emitter === 'blockmap' && status.blockmap.size === "large") {
                    status.blockmap.selected = status.seatmap.moduleRef.getMapId();
                    globalFunctions.hideMinimap();
                    $(".back-btn").addClass("hidden");
                    $(".ticket-selected").addClass("hidden");
                    status.blockmap.showLoader = false;
                    status.blockmap.moduleRef['setAvailability'](false);
                    $("#topbar").css("justify-content","left");
                    $(".neighbour-lab").addClass("d-none");
                    $("#minimap").addClass("d-none");
                    $("#loading-container").addClass("d-none");
    
                    if(this.minisite_config.unavailable) {
                        if([status.blockmap.moduleRef.getMapId()]['sections']){
                            status.blockmap.moduleRef.setElementUnavailable(this.minisite_config.unavailableOptionsJson[status.blockmap.moduleRef.getMapId()]['sections']);
                        } else if([status.blockmap.moduleRef.getMapId()]['blocks']){
                            status.blockmap.moduleRef.setElementUnavailable(this.minisite_config.unavailableOptionsJson[status.blockmap.moduleRef.getMapId()]['blocks']);
                        }
                    }
    
                    status.resizeForce = true;
                    var allBlocks = status.blockmap.moduleRef.getAllElements();
    
                    for(var i in allBlocks){
                        var blockID = allBlocks[i].id;
                        globalFunctions.blockhash[blockID] = blockID;
                    }
    
                }
    
                if (message.emitter === 'seatmap' && status.seatmap.size === "large") {
                    $("#loading-container").addClass("d-none");
                    $('.form-group input').blur();
                    $('.form-group input').val('');
                    $("#minimap").removeClass("d-none");
                    status.blockmap.moduleRef.removeStatus(status.blockmap.moduleRef.getElementsByStatus("filtered"), "filtered");
                    status.blockmap.moduleRef.setAvailability(status.blockmap.moduleRef.getAllElements());
                    status.seatmap.moduleRef.open();
                    status.seatmap.selected = status.seatmap.moduleRef.getMapId();
                    status.blockmap.selected = status.seatmap.moduleRef.getMapId();
                    
                    if(this.minisite_config.filteringJson) {
                        var sectorRows = {};
                        var seatsid = status.seatmap.moduleRef.getElementsByStatus("available");
        
                        for(var a = 0; a < seatsid.length; a++) {
                            var split = seatsid[a].id.split("-");
                            var row = split[0] + "-" + split[1];
                            sectorRows[row] = true;
                        }
        
                        var filterviprow = {};
                        
                        for(var k = 0; k < this.minisite_config.filteringJson.filtering.length; k++){
                            if(this.minisite_config.filteringJson.filtering[k].children) {
                                for(var j = 0; j < this.minisite_config.filteringJson.filtering[k].children.length; j++) {
                                    var r = this.minisite_config.filteringJson.filtering[k].children[j].nodes.rows;
                                    for( var l = 0; l < r.length; l++) {
                                        filterviprow[r[l]] = true;
                                    }
                                }
                            }
                        }
        
                        for(var b in sectorRows){
                            if(!filterviprow[b]) {
                                for(var n = 0; n < this.minisite_config.filteringJson.filtering.length; n++){
                                    if(this.minisite_config.filteringJson.filtering[n].title === "Regular Seating") {
                                        this.minisite_config.filteringJson.filtering[n].nodes.rows.push(b);
                                    }
                                }
                            }
                        }
                    }
    
                    if(!globalFunctions.isMobile() && this.minisite_config.hasMinimap) {
                        globalFunctions.showMinimap(this.resizes.minimapModule);
                    }
    
                    if(this.minisite_config.neighbours && this.minisite_config.neighboursOptionsJson[status.seatmap.moduleRef.getMapId()]){
                        $(".tk3d-neighbor-btns-container").css("display","none");
                        $(".neighbour-lab").removeClass("d-none");
                        var left = this.minisite_config.neighboursOptionsJson[status.seatmap.moduleRef.getMapId()].l;
                        var right = this.minisite_config.neighboursOptionsJson[status.seatmap.moduleRef.getMapId()].r;
                        
                        var leftText = "Left section";
                        var rightText = "Right section";
    
                        if(!this.minisite_config.leftRight) {
                            if(this.minisite_config.translation) {
                                if(this.minisite_config.translationOptionsJson[left]) {
                                    leftText = this.minisite_config.translationOptionsJson[left];
                                } else {
                                    leftText = left.split("_")[1];
                                }
        
                                if(this.minisite_config.translationOptionsJson[right]){
                                    rightText = this.minisite_config.translationOptionsJson[right];
                                } else {
                                    rightText = right.split("_")[1];
                                }
                            } else {
                                leftText = left.split("_")[1];
                                rightText = right.split("_")[1];
                            }
                        }
    
                        if(right !== "none"){
                            $(".next-neighbour").css("opacity", "1");
                            $(".fa-angle-double-right").css("opacity", "1");
                            $(".next-neighbour").text(rightText);
                            $(".next-neighbour").parent().css('pointer-events','all');
                        } else {
                            $(".next-neighbour").css("opacity", "0");
                            $(".fa-angle-double-right").css("opacity", "0");
                            $(".next-neighbour").parent().css('pointer-events','none');
                        }
    
                        if(left !== "none") {
                            $(".prev-neighbour").css("opacity", "1");
                            $(".fa-angle-double-left").css("opacity", "1");
                            $(".prev-neighbour").text(leftText);
                            $(".prev-neighbour").parent().css('pointer-events','all');
                        } else {
                            $(".prev-neighbour").css("opacity", "0");
                            $(".fa-angle-double-left").css("opacity", "0");
                            $(".prev-neighbour").parent().css('pointer-events','none');
                        }
                    } else {
                        $(".neighbour-lab").addClass("d-none");
                    }
    
                    $("#topbar").css("justify-content","center");
    
                    status.seatmap.showLoader = false;
                    status.seatmap.moduleRef['setAvailability'](false);
    
                    if(this.minisite_config.filteringJson){
                        globalFunctions.filteringSeatmapApply(status.seatmap.moduleRef);
                    }
    
                    if(this.minisite_config.unavailable) {
                        status.seatmap.moduleRef.setElementUnavailable(this.minisite_config.unavailableOptionsJson[status.blockmap.moduleRef.getMapId()]['seats']);
                    }
    
                    status.resizeForce = true;
                    status.view3d.moduleRef['close'];
                    status.view3d.moduleRef['reset'];
                }
    
                this.h = document.getElementById('main-interface').offsetHeight;
                this.w = document.getElementById('main-interface').offsetWidth;
                //status.blockmap.moduleRef['setAspectRatio'](this.h / this.w);
                //status.seatmap.moduleRef['setAspectRatio'](this.h / this.w);
    
                if (message.emitter === 'view3d' && status.view3d.size === "large") {
                    $("#loading-container").addClass("d-none");
                    status.view3d.moduleRef['open'];
                    $(".neighbour-lab").addClass("d-none");
                    $("#topbar").css("justify-content","center");
                    if(!globalFunctions.isMobile() && this.minisite_config.hasMinimap) {
                        globalFunctions.showMinimap(this.resizes.minimapModule);
                    }
                    if(this.minisite_config.navigation) {
                        globalFunctions.dvmNavigationUpdatePopup(status.blockmap.moduleRef);
                    }
                    if(this.minisite_config.copyLinkCheck) {
                        globalFunctions.interfaceLinkToShare(status.view3d.moduleRef);
                    }
                    status.view3d.moduleRef['fixedAspectRatio'](false);
                    status.view3d.moduleRef['responsive'](true);
                    status.resizeForce = true;
                    message.emitter = "view3d";
                }
            //}
    
            if (message.emitter === 'view3d' && message.emmitterSize === 'mini'
                && status.seatmap.visible === true && status.seatmap.size === 'large') {
                    message.emitter = 'seatmap';
            }

        }

    }
}
