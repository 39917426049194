import { Injectable } from '@angular/core';
import { ResizeNormalService } from 'src/app/services/resize-normal.service';
import { Tk3dService } from 'ngx-tk3d';
import * as global from '../../services/global-functions';


@Injectable({
    providedIn: 'root'
})
export class MinimapService{
    h; w;
    blockmap_mini: any;

    constructor(private resizes: ResizeNormalService ,private tk3d: Tk3dService){}

    public loadMinimap(venueID, mapID){
        this.h = document.getElementById('minimap').offsetHeight;
        this.w = document.getElementById('minimap').offsetWidth;
    
        const config = {
          module: 'map',
          venue: venueID,
          container: 'area-map-mini',
          plugins: ['SelectionPlugin'],
          config: {} // config como siempre
        };
        
        this.blockmap_mini = this.tk3d['tk3d']['loadModule'](config);
        this.blockmap_mini.loadMap(mapID);
        this.blockmap_mini['setAspectRatio'](this.h / this.w);
        this.resizes.minimapModule = this.blockmap_mini;
        global.showMinimap(this.resizes.minimapModule);
        (window as any).blockmap_mini = this.blockmap_mini;
    }

    public setAvailability(node) {
        this.blockmap_mini.setAvailability(node);
        this.blockmap_mini.select(node);
    }
    
    public changeVenue(venueid, mapid) {
        this.blockmap_mini.changeVenue(venueid, () => {
          this.blockmap_mini.loadMap(mapid);
        });
    }
}
