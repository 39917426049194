import {UIRouter, RootModule} from '@uirouter/angular';
import { Injector } from '@angular/core';
import { states } from './app-routing.states';

export const config: RootModule = {
  states,
  otherwise: {state: 'common.home'},
  initial: {state: 'common.home'},
  useHash: false,
  config: uiRouterConfigFn
};

/** UIRouter Config placeholder  */
export function uiRouterConfigFn(router: UIRouter, injector: Injector) {

  const criteria = {
    to: (state) => {
      let check = true;
      if (state.data) {
        check = !state.data.openAccess;
      }
      return check;
    }
  };

  const notAdministratorCriteria = {
    to: (state) => {
      let check = false;
      if (state.name.indexOf('user-') !== -1) {
        check = true;
      }
      return check;
    }
  };


//  router.transitionService.onBefore(criteria, requireAuthentication);
//  router.transitionService.onBefore(notAdministratorCriteria, requireAdministrator);
}
