import { InjectionToken, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from '@environment';
import { StateService } from '@uirouter/angular';
import { ObservableInput, Observable, of } from 'rxjs';

const _venue = window.location.pathname.split("/")[1];


export function getDataconfigUrl() {
    var url = `https://3ddigitalvenue.com/3dmap/${environment.url}/${_venue}/config.json`;
    return url;
}

export interface MinisiteConfiguration {
    flag: string;
    integrationType:string;
    viewerIntegration: string;
    libraryVersion:string;
    minisiteUrl: string;
    mapID: string;
    venueID: string;
    minimapID: string;
    venueName: string;
    subtitleName: string;
    viewType: string;
    exceptions?: string[];
    exceptionsJson: [];
    exceptionsJsonCheck: boolean;
    ticketsUrl?: string;
    ticketsName?: string;
    hasPano?: boolean;
    hasMinimap: boolean;
    logoUrl: string;
    logoType:string;
    faviconType: string;
    minimapZoom:number;
    translation: boolean;
    logo:boolean;
    bg:boolean;
    backgroundColor: string;
    favicon:boolean;
    buyTickets: boolean,//true
    filters?: boolean; //false
    navigation?: boolean;
    tabs?: boolean; //false
    tabsManual?:boolean;
    neighbours?:boolean;
    leftRight?: boolean;
    unavailable?: boolean; //false
    popupJsonCheck:boolean;
    popupJson: any;
    popupImg: string; //true
    searchbar:boolean;
    navigationPluginCheck:boolean;
    indoorPluginCheck:boolean;
    galleryPluginCheck:boolean;
    bestNodesCheck: boolean;
    infoMenuCheck: boolean;
    infoMenuOptions: any;
    infoMenuTitle: string;
    copyLinkCheck: boolean;
    multiIndoorCheck:boolean;

    bgHeader: string;
    txtHeader: string;
    bgTopbar:string;
    ticketLab: string;
    ticketVal: string;
    bgPopover: string;
    popoverLab: string;
    popoverVal: string;
    bgButton: string;
    buttonLab: string;

    blockAvailable:string;
    blockAvailableOpacity:string;
    blockAvailableStroke:string;
    blockAvailableStrokeOpacity:string;
    blockAvailableLineWidth:string;
    blockAvailableHover:string;
    blockAvailableHoverOpacity:string;
    blockAvailableHoverStroke:string;
    blockAvailableHoverStrokeOpacity:string;
    blockAvailableHoverLineWidth:string;
    blockSelected:string;
    blockSelectedOpacity:string;
    blockSelectedStroke:string;
    blockSelectedStrokeOpacity:string;
    blockSelectedLineWidth:string;
    blockSelectedHover:string;
    blockSelectedHoverOpacity:string;
    blockSelectedHoverStroke:string;
    blockSelectedHoverStrokeOpacity:string;
    blockSelectedHoverLineWidth:string;
    blockUnavailable:string;
    blockUnavailableOpacity:string;
    blockUnavailableStroke:string;
    blockUnavailableStrokeOpacity:string;
    blockFiltered:string;
    blockFilteredOpacity:string;
    blockFilteredHover:string;
    blockFilteredHoverOpacity:string;
    blockFilteredUnavailable: string;
    blockFilteredUnavailableOpacity: string;
    blockFilteredStroke:string;
    blockFilteredStrokeOpacity:string;
    blockFilteredHoverStroke:string;
    blockFilteredHoverStrokeOpacity:string;
    blockFilteredUnavailableStroke:string;
    blockFilteredUnavailableStrokeOpacity:string;
    blockFilteredLineWidth:string;
    blockFilteredHoverLineWidth:string;

    seatAvailable:string;
    seatAvailableOpacity:string;
    seatAvailableStroke:string;
    seatAvailableStrokeOpacity:string;
    seatAvailableLineWidth:string;
    seatAvailableHover:string;
    seatAvailableHoverOpacity:string;
    seatAvailableHoverStroke:string;
    seatAvailableHoverStrokeOpacity:string;
    seatAvailableHoverLineWidth:string;
    seatSelected:string;
    seatSelectedOpacity:string;
    seatSelectedStroke:string;
    seatSelectedStrokeOpacity:string;
    seatSelectedLineWidth:string;
    seatSelectedHover:string;
    seatSelectedHoverOpacity:string;
    seatSelectedHoverStroke:string;
    seatSelectedHoverStrokeOpacity:string;
    seatSelectedHoverLineWidth:string;
    seatUnavailable:string;
    seatUnavailableOpacity:string;

    blockOpacity:string;
    seatOpacity:string;

    tabOptionsJson?: any;
    unavailableOptionsJson?: any;
    filteringJson?: any;
    navigationJson?: any;
    neighboursOptionsJson?: any;
    translationOptionsJson?: any;
    viewerVersion?:any;
    onlyViewer?:any;
}

export interface SeatmapNavigation {
    [key: string]: {
        l: string;
        r: string;
    }
}

export const MINISITE_CONFIGURATION = new InjectionToken<MinisiteConfiguration>('Minisite configuration');

export const minisiteConfig: MinisiteConfiguration = {
    flag: "",
    integrationType: "",
    viewerIntegration: "",
    libraryVersion:"",
    minisiteUrl: "",
    mapID: "",
    venueID: "",
    minimapID: "",
    venueName: "",
    subtitleName: "",
    viewType: "",
    exceptions: [],
    exceptionsJsonCheck: false,
    exceptionsJson: [],
    ticketsUrl: "",
    ticketsName: "",
    hasPano: true,
    hasMinimap: true,
    logoUrl: "",
    logoType: "",
    faviconType: "",
    minimapZoom:1,
    backgroundColor: "",
    logo:true,
    bg:true,
    favicon:true,
    buyTickets: true,
    filters: false, //false
    navigation: false,
    tabs: false, //false
    tabsManual: false,
    neighbours:false,
    leftRight: true,
    unavailable: false, //false
    searchbar: true,
    translation: false,
    popupJsonCheck:false,
    popupJson:[],
    popupImg: "", //true
    navigationPluginCheck:false,
    indoorPluginCheck:false,
    galleryPluginCheck:false,
    bestNodesCheck:false,
    infoMenuCheck: false,
    infoMenuOptions: [],
    infoMenuTitle: "",
    bgHeader: "",
    txtHeader: "",
    bgTopbar:"",
    ticketLab: "",
    ticketVal: "",
    bgPopover: "",
    popoverLab: "",
    popoverVal: "",
    bgButton: "",
    buttonLab: "",
    copyLinkCheck: true,
    multiIndoorCheck: false,

    blockAvailable:"",
    blockAvailableOpacity:"",
    blockAvailableStroke:"",
    blockAvailableStrokeOpacity:"",
    blockAvailableLineWidth:"",
    blockAvailableHover:"",
    blockAvailableHoverOpacity:"",
    blockAvailableHoverStroke:"",
    blockAvailableHoverStrokeOpacity:"",
    blockAvailableHoverLineWidth:"",
    blockSelected:"",
    blockSelectedOpacity:"",
    blockSelectedStroke:"",
    blockSelectedStrokeOpacity:"",
    blockSelectedLineWidth:"",
    blockSelectedHover:"",
    blockSelectedHoverOpacity:"",
    blockSelectedHoverStroke:"",
    blockSelectedHoverStrokeOpacity:"",
    blockSelectedHoverLineWidth:"",
    blockUnavailable:"",
    blockUnavailableOpacity:"",
    blockUnavailableStroke:"",
    blockUnavailableStrokeOpacity:"",
    blockFiltered: "",
    blockFilteredOpacity: "",
    blockFilteredHover:"",
    blockFilteredHoverOpacity:"",
    blockFilteredUnavailable: "",
    blockFilteredUnavailableOpacity: "",
    blockFilteredStroke:"",
    blockFilteredStrokeOpacity:"",
    blockFilteredHoverStroke:"",
    blockFilteredHoverStrokeOpacity:"",
    blockFilteredUnavailableStroke:"",
    blockFilteredUnavailableStrokeOpacity:"",
    blockFilteredLineWidth:"",
    blockFilteredHoverLineWidth:"",

    seatAvailable:"",
    seatAvailableOpacity:"",
    seatAvailableStroke:"",
    seatAvailableStrokeOpacity:"",
    seatAvailableLineWidth:"",
    seatAvailableHover:"",
    seatAvailableHoverOpacity:"",
    seatAvailableHoverStroke:"",
    seatAvailableHoverStrokeOpacity:"",
    seatAvailableHoverLineWidth:"",
    seatSelected:"",
    seatSelectedOpacity:"",
    seatSelectedStroke:"",
    seatSelectedStrokeOpacity:"",
    seatSelectedLineWidth:"",
    seatSelectedHover:"",
    seatSelectedHoverOpacity:"",
    seatSelectedHoverStroke:"",
    seatSelectedHoverStrokeOpacity:"",
    seatSelectedHoverLineWidth:"",
    seatUnavailable:"",
    seatUnavailableOpacity:"",
    
    blockOpacity:"",
    seatOpacity:"",

    tabOptionsJson: null,
    unavailableOptionsJson: null,
    filteringJson: null,
    navigationJson: null,
    translationOptionsJson: null,
    viewerVersion:"",
    onlyViewer:""
}

@Injectable()
export class MinisiteFileConfigurationService {
    constructor(private http: HttpClient, 
        @Inject(MINISITE_CONFIGURATION) private minisite_config: MinisiteConfiguration,
        private state: StateService) {
    } 

    public configureFromFile() {
        const url = getDataconfigUrl();
        var that = this;
        return this.http.get(url).pipe(
            map((data: MinisiteConfiguration) => {
                for (let [key, value] of Object.entries(data)){
                    this.minisite_config[key] = value;
                }
                console.log(this.minisite_config)
            }),catchError((err) => {
                window.location.href = "https://map.3ddigitalvenue.com/error.html";
                return of('error');
            })).toPromise();
        }
}
