import { Tk3dConfig } from 'ngx-tk3d';
import { getDataconfigUrl, MinisiteConfiguration } from './minisite.configuration';

export const Tk3dConfiguration: Tk3dConfig = {
  venue: 'nam-us-00094-islanders',
  mapId: 'blockmap',
  flags: {
    seatmap: {
      available_by_default: true,
      user_selection: false,
      max_selection: 60,
      multiple_selection: true
    },
    blockmap: {
      available_by_default: false,
      user_selection: false,
      max_selection: 1,
      multiple_selection: false
    }
  },
  onlineConfiguration: {
    function: getConfig,
    filePath: getDataconfigUrl(),
  },
  defaultPlugins: ['ThumbnailPlugin','NavigationPlugin','NeighborsPlugin'],
  neighborsInfo: null,
  removeUnavailable: {seatmap: true, blockmap: true},
  injectTk3dService: true
};

export function getConfig(configuration: Tk3dConfig, fileData: MinisiteConfiguration) {
  configuration.venue = fileData['venueID'];
  configuration.mapId = fileData['mapID'];
  configuration.neighborsInfo = fileData['neighboursOptionsJson'];
}
