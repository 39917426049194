import {Injectable, Inject, Injector} from '@angular/core';
import {Handler, GeneralState, InteractionMessageProtocol, Tk3dService} from 'ngx-tk3d';
import { MinimapService } from '../main-interface/minimap-interface/minimap-interface.service';
import { MINISITE_CONFIGURATION, MinisiteConfiguration } from '../minisite.configuration';
declare var $:any;
import * as global from '../services/global-functions';


@Injectable()
export class NeighbourClickAppHandler extends Handler {
  constructor(private injector: Injector, private minimapService: MinimapService, @Inject(MINISITE_CONFIGURATION) private minisite_config: MinisiteConfiguration) {
    super();
    this.handling['seatmap'] = ['clickNeighbor'];
  }

  handle(message: InteractionMessageProtocol, status: GeneralState) {
    var neighbor_id = message.element;
    const tk3d = this.injector.get(Tk3dService)

    if (message.element !== null) {
      $("#loading-container").removeClass("d-none");
        status.seatmap.moduleRef.loadMap(neighbor_id, () => {
            const forcedMessage: InteractionMessageProtocol = {
                event: 'loaded',
                emmitterSize: 'large',
                emitter: 'seatmap',
                //element: elementClicked as TK3dElement
            };
            tk3d.handle(forcedMessage);
        });

        if(this.minisite_config.hasMinimap){
          this.minimapService.setAvailability(neighbor_id);
        }

        if(this.minisite_config.translation) {
          if(this.minisite_config.translationOptionsJson[(neighbor_id as any)]) {
              var section = this.minisite_config.translationOptionsJson[(neighbor_id as any)]
              $(".section-val").text(section);
          } else {
              $(".section-val").text((neighbor_id as any).split("_")[1]);
          }
        } else {
          $(".section-val").text((neighbor_id as any).split("_")[1]);
        }
    }
  }
}
