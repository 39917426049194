import {Injectable, Inject} from '@angular/core';
import {Handler, GeneralState, InteractionMessageProtocol} from 'ngx-tk3d';
import * as globals from '../services/global-functions';
import { MinisiteConfiguration, MINISITE_CONFIGURATION } from '../minisite.configuration';
declare var $:any;

@Injectable()
export class BlockmapMiniClickAppHandler extends Handler {

    constructor(@Inject(MINISITE_CONFIGURATION) private minisite_config: MinisiteConfiguration) {
        super();
        this.handling['blockmap'] = ['click'];
    }

    handle(message: InteractionMessageProtocol, status: GeneralState) {
        if (message.emmitterSize !== 'mini') {
            return;
        }

        if(!globals.isMobile()) {
            status.blockmap.moduleRef['setZoom'](1);
            status.blockmap.moduleRef['setMaxZoom'](6);
            status.blockmap.moduleRef['setMinZoom'](1);
            status.blockmap.moduleRef['enableZoom']();
        }

        $(".back-btn").addClass("hidden");
        $(".ticket-selected").addClass("hidden");
        $(".neighbour-lab").addClass("d-none");
        $(".neighbour-lab").addClass("d-none");
        $("#minimap").addClass("d-none");
        //$("#topbar").css("justify-content","left");

        // if(globals.interfaces.topview.nodes_selected.length > 0) {
        //     status.blockmap.moduleRef.setAvailability(globals.interfaces.topview.nodes_selected);
        // } else {
        //     status.blockmap.moduleRef.setAvailability(false);
        // }

        if(this.minisite_config.unavailable) {
            status.blockmap.moduleRef.setElementUnavailable(this.minisite_config.unavailableOptionsJson[status.blockmap.moduleRef.getMapId()]['sections']);
        }

        message.emitter = 'blockmap';
        status.blockmap.moduleRef['enableZoom']();
        status.blockmap.size = 'large';
        status.blockmap.visible = true;
        status.blockmap.moduleRef['setMaxZoom'](6);
        status.blockmap.moduleRef['setMinZoom'](1);
        status.view3d.visible = false;
        globals.hideMinimap();
        status.view3d.moduleRef.close();
        $('#seatmap').addClass("d-none");
        globals.interfaceNavigationHide();
        status.blockmap.selected = status.blockmap.selected;
        status.blockmap.moduleRef.select(status.blockmap.selected);
        globals.interfaces.seatmap.nodes_selected = [];
        status.resizeForce = true;
        if(this.minisite_config.viewType === "seat"){
            status.blockmap.moduleRef.select(status.seatmap.selected);
            status.blockmap.selected = status.seatmap.selected;
            status.seatmap.size = 'large';
            status.seatmap.visible = false;
            status.seatmap.moduleRef.close();
            status.seatmap.moduleRef.reset();
        }
    }
}
