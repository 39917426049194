import { Component, OnInit, Input, AfterViewInit, Inject } from '@angular/core';
import { GeneralState, Tk3dService, InteractionMessageProtocol } from 'ngx-tk3d';
import * as global from '../../services/global-functions';
import { MinisiteConfiguration, MINISITE_CONFIGURATION } from 'src/app/minisite.configuration';
import { ResizeNormalService } from 'src/app/services/resize-normal.service';
import { MinimapService } from './minimap-interface.service';

@Component({
  selector: 'app-minimap-interface',
  templateUrl: './minimap-interface.component.html',
  styleUrls: ['./minimap-interface.component.scss']
})
export class MinimapInterfaceComponent implements AfterViewInit {

  @Input() state: GeneralState;
  @Input() set blockSelected(value: string) {
    if (!value) {
      return;
    }

    if(this.config.hasMinimap) {
      this.minimapService.blockmap_mini.setAvailability(true);
      if(global.interfaces.params.v360){
        this.minimapService.blockmap_mini.setElementAvailable(global.interfaces.params.v360);
        this.minimapService.blockmap_mini.select(global.interfaces.params.v360);
        this.minimapService.blockmap_mini.focusOn(global.interfaces.params.v360, this.config.minimapZoom);
      } else {
        this.minimapService.blockmap_mini.setElementAvailable(this.state.blockmap.selected);
        this.minimapService.blockmap_mini.select(this.state.blockmap.selected);
        this.minimapService.blockmap_mini.focusOn(this.state.blockmap.selected, this.config.minimapZoom);
      }
      this.minimapService.blockmap_mini.disableZoom();
    }
  }

  mapMiniId: string;

  get minimap_visibility() {
    return false;
  }

  ngAfterViewInit(): void {
    if(this.config.hasMinimap) {
      this.state.resizeForce = true;
      this.minimapService.loadMinimap(this.config.venueID, this.config.minimapID);
    }
  }

  constructor(private resizes: ResizeNormalService ,private tk3d: Tk3dService, 
    @Inject(MINISITE_CONFIGURATION) private config: MinisiteConfiguration, private minimapService: MinimapService) { 
  }

  onClick() {
    const message: InteractionMessageProtocol = {
      event: 'click',
      emmitterSize: 'mini',
      emitter: 'blockmap',
      // element: elementClicked as TK3dElement
    };
    this.tk3d.handle(message);
    document.getElementById('minimap').classList.add('hidden');
  }
}
