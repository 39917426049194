import {Injectable, Inject} from '@angular/core';
import {Handler, GeneralState, InteractionMessageProtocol} from 'ngx-tk3d';
import * as global from '../services/global-functions'
import { MINISITE_CONFIGURATION, MinisiteConfiguration } from '../minisite.configuration';
declare var $:any;


@Injectable()
export class SeatClickAppHandler extends Handler {
  constructor(@Inject(MINISITE_CONFIGURATION) private minisite_config: MinisiteConfiguration) {
    super();
    this.handling['seatmap'] = ['click'];
  }

  handle(message: InteractionMessageProtocol, status: GeneralState) {
    if (message.emmitterSize === 'mini' ||  !status.seatmap.moduleRef['getElementById'](message.element.id).isAvailable()) {
      return;
    }

    if(this.minisite_config.hasPano) {
      status.seatmap['size'] = 'mini';
      status.view3d['size'] = 'large';
      global.interfaces.seatmap.nodes_selected = [message.element.id];
      global.selectedDataWrite(message.element.id);
      status.view3d.moduleRef['setContainer']('view3d');
      //$("#view3d-box-view").removeClass("d-none");
      $(".popover").each(function(){
        $(this).popover("dispose").remove();
      });
      status.seatmap.moduleRef.unselectAll();
      status.seatmap.moduleRef.select(message.element.id);
      status.view3d.moduleRef['load'](message.element.id);
      status.view3d.visible = true;
      if(this.minisite_config.navigation) {
        global.interfaceSetInitialNavigation(message.element.id,this.minisite_config.navigationJson);
      }
    }

    //global.hideMinimap();
  }
}
