declare var $:any;
import * as $ from 'jquery';

export var blockhash = {};

export var interfaces = {
    current:"topview",
    seatmap:{
        nodes_selected: []
    },
    topview:{
        nodes_selected: []
    },
    filtering:{
        ref : null,
        nodes : {
            sections: [],
            rows: [],
            seats: []
        }
    },
    navigation:{
        to: "",
        from: ""
    },
    window:{
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
        //animation: requestAnimationFrame(this.windowLoop)
    },
    params: interfaceGetParams(window.location.href)
};

export function interfaceGetParams(url) {
    var params = {v360:"", search:""};
    var parser = document.createElement('a');
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split('&');
  
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
  
    if (params.v360) {
      params.v360 = atob(params.v360);
    }
  
    if (params.search) {
      params.search = atob(params.search);
    }
  
    return params;
}

export function interfaceLinkToShare(node) {
    var link = window.location.href.split('?')[0]; //let link = "local.3ddigitalvenue.com:8080";
  
    if (!node && this.interface.filtering.ref != 'regularseating') {
      link += "?seatingtype=vip";
  
      if (this.interface.filtering.ref && this.interface.filtering.ref != 'premiumseating') {
        link += "&search=" + btoa(this.interface.filtering.ref);
      }
    } else if (node) {
      link += "?v360=" + btoa(node.visual_id);
    } // console.log(link)
  
  
    $(".linktoshare-input").val(link);
    return;
}

export function interfaceNavigationShow(current_mapid, navigationJson){
    if(navigationJson.outdoor[current_mapid]){
        $(".navigation").removeClass("d-none");
    }
}

export function interfaceNavigationHide(){
    $(".navigation").addClass("d-none");
}

export function interfaceSetNavigationReverse(){
    var aux = interfaces.navigation.to;
    interfaces.navigation.to = interfaces.navigation.from;
    interfaces.navigation.from  = aux;
    return interfaces.navigation;
}

export function interfaceGetNavigation(){
    return interfaces.navigation;
}

export function interfaceSetInitialNavigation(current_mapid,navigationJson){
    var navitem = null;
    var current_sectorid = current_mapid.split("-")[0];
    var current_rowid = current_mapid.split("-")[0] + "-" + current_mapid.split("-")[1];
    var current_seatid = current_mapid;

    if(navigationJson.outdoor.seats && navigationJson.outdoor.seats[current_seatid]) {
        navitem = navigationJson.outdoor.seats[current_seatid];
    } else if(navigationJson.outdoor.rows && navigationJson.outdoor.rows[current_rowid]) {
        navitem = navigationJson.outdoor.rows[current_rowid];
    } else if(navigationJson.outdoor.sections && navigationJson.outdoor.sections[current_sectorid]) {
        navitem = navigationJson.outdoor.sections[current_sectorid];
    } else {
        navitem = null;
    }

    if(navitem){
        var parent = navitem;
        var outdoor = parent.node;
        if(!outdoor.title){ outdoor.title = "Acceso sala VIP"; }
        if(!parent.title){ parent.title = "Volver al asiento"; }
        interfaces.navigation.to = outdoor;
        (interfaces.navigation.from as any) = {id: current_sectorid, title:parent.title};
    } else {
        interfaces.navigation = {to: "", from: ""};
    }

    return interfaces.navigation
}

export function filteringSeatmapApply(seatmap){
    if(!interfaces.filtering.ref){
        return 
    }

    var nodes = interfaces.filtering.nodes;
    var current_section = null;
    if(nodes.seats && nodes.seats.length > 0 || nodes.rows && nodes.rows.length > 0){
        var seatnodes = nodes.seats;
        var allseats = seatmap.getAllElements();

        for(var i = 0;i < allseats.length; i++){
            var seat = allseats[i].id;
            var seat_arr = seat.split("-");
            var concat_row = seat_arr[0]+"-"+seat_arr[1];
            current_section = seat_arr[0];
            if(nodes.rows.indexOf(concat_row) > -1){
                seatnodes = seatnodes.concat(seat);
            }
        }

        var nodes_toset = [];
        for(var i = 0;i < seatnodes.length; i++){
            if (current_section == seatnodes[i].split("-")[0]){
                nodes_toset = nodes_toset.concat(seatnodes[i]);
            }
        }
        
        if(nodes_toset.length > 0){
            seatmap.setAvailability(seatnodes);
            interfaces.seatmap.nodes_selected = seatnodes;
        }
    }
}

export function dvmNavigationGo(view3d, blockmap) {
    var nav = this.interfaceGetNavigation();
    view3d.load(nav.to.id);
    this.interfaceSetNavigationReverse();
    this.dvmNavigationUpdatePopup(blockmap);
}

export function dvmNavigationUpdatePopup(blockmap){
    var nav = this.interfaceGetNavigation();
    if(nav && nav.to.id || nav && nav.from.id){
        $(".navigation").removeClass("d-none");
        blockmap.getThumbnail(nav.to.id,true).then((imgUrl) => {
            $("#navigation-popup").attr("style","background-image: url('"+imgUrl.src+"')");
            $(".navigation-popup-title p").text(nav.to.title);
        }).catch(function(e){
            console.log(e);
        })
    }
}

export function showMinimap(minimap) {
    $("#minimap").removeClass("hidden");
    if(minimap) {
        minimap.updateSize();
    }
}

export function hideMinimap() {
    $("#minimap").addClass("hidden");
}

export function getQueryVariable(variable) {
    var query; var vars; var pair;
    query = window.location.search.substring(1);
    vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        pair = vars[i].split('=');
        if (pair[0] === variable) {
            return pair[1];
        }
    }
    return(false);
}

export function isMobile(){
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        return true;
    } else {
        return false;
    }
}

export function getMinZoom(mapcontainer_id){
    var container = $("#"+mapcontainer_id)
    var bg = container.find(".svg-img-background");
    var svg_ar;

    if(bg.length > 0){
        var width = parseFloat(bg[0].getAttribute("width"));
        var height = parseFloat(bg[0].getAttribute("height"));
        svg_ar = width / height;
    }else{
        return 1;
    }

    var con_ar = container.width() / container.height();

    return svg_ar / con_ar;
}

export function getSize(mapcontainer_id){
    var bg = $("#" + mapcontainer_id).find(".svg-img-background");
    var ar = 0;

    if(bg.length > 0) {
        var width = bg[0].width.baseVal.value;
        var height = bg[0].height.baseVal.value;
        ar = height / width;
    } else {
        ar = 9/16;
    }

    return ar;
}

export function selectedBlockWrite(mapObj){
    if(mapObj){
        $(".ticket-selected").removeClass("hidden");
        $(".rows").addClass("d-none");
        $(".seat").addClass("d-none");
        $(".section-lab").text("Section ");
        var section_raw = mapObj.split("_")[1];
        
        $(".section-val").text(section_raw);
    }
    return true;
}

export function selectedDataWrite(mapObj){
    $(".rows").removeClass("d-none");
    $(".seat").removeClass("d-none");
    $(".row-lab").text("Row ");
    $(".seat-lab").text("Seat ");
    $(".row-val").text("");
    $(".seat-val").text("");

    if(this.interfaces.filtering.ref && !isMobile()){
        $("#top-guide").addClass("filtered");
        $(".section-lab").html("Section");
    } else if($(".breadcrumbs-atitle").text() !== "") {
        $("#top-guide").addClass("filtered");
        $(".section-lab").html("Section");
    }
    
    if (mapObj){
        var data = mapObj.split("_")[1].split("-");
        if(data.length>1 && data[0].slice(-3) != "INT"){
            $(".section-lab").text("Section ");
            if(this.interfaces.filtering.ref && !isMobile()){
                $("#top-guide").addClass("filtered");
                $(".section-lab").html("Section");
            }
            $(".section-val").text(data[0]);
            $(".row-val").text(data[1]);
            $(".seat-val").text(data[2]);
        }
    }
}