import { Injectable, Injector } from '@angular/core';
import { MINISITE_CONFIGURATION } from '../minisite.configuration';
declare var $:any;

@Injectable({
    providedIn: 'root'
})
export class PopoverService {
    /*Popup*/
    constructor(private injector: Injector){
        document.documentElement.style.setProperty('--label-color', this.config.ticketLab);
    }

    config = this.injector.get(MINISITE_CONFIGURATION)

    popoverDestroyAll(){
        $(".fpopover").remove();
        return null;
    }

    /* CON JSON */

    //blocks

    popoverGetContentBlockJson(obj){
        var split = obj.id.split("-");
        var section = split[0].split("_")[1];
        var sectionTranslation = split[0];

        if(obj.type=='areaprice'){
            section = obj.id.split("_")[1];
        }

        var content;

        var textBody = "";
        var textFooter = "";
        var price = "";

        if(this.config.popupJson.sections[obj.id]) {
            textBody = this.config.popupJson.sections[obj.id].textBody;
            textFooter = this.config.popupJson.sections[obj.id].textFooter;
            price = this.config.popupJson.sections[obj.id].price;
        } else {
            textBody = "";
            textFooter = "";
            price = "";
        }

        if(this.config.translation) {
            if(this.config.translationOptionsJson[sectionTranslation]) {
                section = this.config.translationOptionsJson[sectionTranslation]
            } else {
                section = section;
            }
        }

        if(textBody && (!textFooter && !price)) {
            content = "<div class='regularJson'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<div class='popup-text alignCenter'>"+
                            textBody+
                        "</div>"+
                    "</div>";
        } else if(!textBody && (textFooter || price)) {
            if(textFooter && !price) {
                content = "<div class='regularJson'>"+
                "<ul class='list-unstyled ticket-selected'>"+
                    "<li style='border-left: none;'>"+
                        "<span class='ticket-lab'>Section </span> "+
                        "<span class='ticket-val'>"+section+"</span>"+
                    "</li>"+
                "</ul>"+
                "<div class='popup-price p10'>"+
                    "<span class='textFooter'>"+ textFooter +"</span>"+
                "</div>"+
            "</div>";
            } else if(price && !textFooter) {
                content = "<div class='regularJson'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<div class='popup-price p10' style='justify-content:flex-end'>"+
                            "<span class='price'>"+ price +"</span>"+
                        "</div>"+
                    "</div>";
            } else if(textFooter && price) {
                content = "<div class='regularJson'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<div class='popup-price p10'>"+
                            "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                        "</div>"+
                    "</div>";
            }
        } else if(!textBody && (!textFooter || !price)){
            content = "<div class='regularJson'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>"+
                    "</div>";
        } else {
            content = "<div class='regularJson'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<div class='popup-text alignStart'>"+
                            textBody+
                        "</div>"+
                        "<div class='popup-price p5'>"+
                            "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                        "</div>"+
                    "</div>";
        }
    
        return content;
    }

    popoverGetContentBlockImgJson(obj){
        var split = obj.id.split("-");
        var section = split[0].split("_")[1];
        var sectionTranslation = split[0];

        if(obj.type=='areaprice'){
            section = obj.id.split("_")[1];
        }

        var content;

        var textBody = "";
        var textFooter = "";
        var price = "";

        if(this.config.popupJson.sections[obj.id]) {
            textBody = this.config.popupJson.sections[obj.id].textBody;
            textFooter = this.config.popupJson.sections[obj.id].textFooter;
            price = this.config.popupJson.sections[obj.id].price;
        } else {
            textBody = "";
            textFooter = "";
            price = "";
        }

        if(this.config.translation) {
            if(this.config.translationOptionsJson[sectionTranslation]) {
                section = this.config.translationOptionsJson[sectionTranslation]
            } else {
                section = section;
            }
        }

        if(textBody && (!textFooter && !price)) {
            content = "<div class='regularJson'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<div class='ticket-selected-view'>" +
                            "<div class='ticket-selected-view-img'></div>"+
                        "</div>" +
                        "<div class='popup-text alignCenter'>"+
                            textBody+
                        "</div>"+
                    "</div>";
        } else if(!textBody && (textFooter || price)) {
            if(textFooter && !price) {
                content = "<div class='regularJson'>"+
                "<ul class='list-unstyled ticket-selected'>"+
                    "<li style='border-left: none;'>"+
                        "<span class='ticket-lab'>Section </span> "+
                        "<span class='ticket-val'>"+section+"</span>"+
                    "</li>"+
                "</ul>"+
                "<div class='ticket-selected-view'>" +
                            "<div class='ticket-selected-view-img'></div>"+
                        "</div>" +
                "<div class='popup-price p10'>"+
                    "<span class='textFooter'>"+ textFooter +"</span>"+
                "</div>"+
            "</div>";
            } else if(price && !textFooter) {
                content = "<div class='regularJson'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<div class='ticket-selected-view'>" +
                            "<div class='ticket-selected-view-img'></div>"+
                        "</div>" +
                        "<div class='popup-price p10' style='justify-content:flex-end'>"+
                            "<span class='price'>"+ price +"</span>"+
                        "</div>"+
                    "</div>";
            } else if(textFooter && price) {
                content = "<div class='regularJson'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<div class='ticket-selected-view'>" +
                            "<div class='ticket-selected-view-img'></div>"+
                        "</div>" +
                        "<div class='popup-price p10'>"+
                            "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                        "</div>"+
                    "</div>";
            }
        } else if(!textBody && (!textFooter || !price)){
            content = "<div class='regularJson'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<div class='ticket-selected-view'>" +
                            "<div class='ticket-selected-view-img'></div>"+
                        "</div>" +
                    "</div>";
        } else {
            content = "<div class='regularJson'>"+
                            "<ul class='list-unstyled ticket-selected'>"+
                                "<li style='border-left: none;'>"+
                                    "<span class='ticket-lab'>Section </span> "+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                            "</ul>"+
                            "<div class='ticket-selected-view'>" +
                                "<div class='ticket-selected-view-img'></div>"+
                            "</div>" +
                            "<div class='popup-text alignStart'>"+
                                textBody+
                            "</div>"+
                            "<div class='popup-price p5'>"+
                                "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                            "</div>"+
                        "</div>";
        }

        return content;
    }

    popoverGetContentBlockVIPImgJson(obj){
        var split = obj.id.split("-");
        var section = split[0].split("_")[1];
        var sectionTranslation = split[0];

        if(obj.type=='areaprice'){
            section = obj.id.split("_")[1];
        }

        var content;

        var vipname = "";
        var textBody = "";
        var textFooter = "";
        var price = "";

        if(this.config.popupJson.sections[obj.id]) {
            vipname = this.config.popupJson.sections[obj.id].vipTitle;
            textBody = this.config.popupJson.sections[obj.id].textBody;
            textFooter = this.config.popupJson.sections[obj.id].textFooter;
            price = this.config.popupJson.sections[obj.id].price;
        } else {
            vipname = ""
            textBody = "";
            textFooter = "";
            price = "";
        }

        if(this.config.translation) {
            if(this.config.translationOptionsJson[sectionTranslation]) {
                section = this.config.translationOptionsJson[sectionTranslation]
            } else {
                section = section;
            }
        }

        if(vipname != "") {
            if(textBody && (!textFooter && !price)) {
                content = "<div class='vipJson'>"+
                            "<div class='vipname ticket-selected-title gold'>" +
                                "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                            "</div>"+
                            "<ul class='list-unstyled ticket-selected'>"+
                                "<li style='border-left: none;'>"+
                                    "<span class='ticket-lab'>Section </span> "+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                            "</ul>"+
                            "<div class='ticket-selected-view'>" +
                                "<div class='ticket-selected-view-img'></div>"+
                            "</div>" +
                            "<div class='popup-text alignCenter'>"+
                                textBody+
                            "</div>"+
                        "</div>";
            } else if(!textBody && (textFooter || price)) {
                if(textFooter && !price) {
                    content = "<div class='vipJson'>"+
                            "<div class='vipname ticket-selected-title gold'>" +
                                "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                            "</div>"+
                            "<ul class='list-unstyled ticket-selected'>"+
                                "<li style='border-left: none;'>"+
                                    "<span class='ticket-lab'>Section </span> "+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                            "</ul>"+
                            "<div class='ticket-selected-view'>" +
                                "<div class='ticket-selected-view-img'></div>"+
                            "</div>" +
                            "<div class='popup-price p10'>"+
                                "<span class='textFooter'>"+ textFooter +"</span> "+
                            "</div>"+
                        "</div>";
                } else if(price && !textFooter) {
                    content = "<div class='vipJson'>"+
                            "<div class='vipname ticket-selected-title gold'>" +
                                "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                            "</div>"+
                            "<ul class='list-unstyled ticket-selected'>"+
                                "<li style='border-left: none;'>"+
                                    "<span class='ticket-lab'>Section </span> "+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                            "</ul>"+
                            "<div class='ticket-selected-view'>" +
                                "<div class='ticket-selected-view-img'></div>"+
                            "</div>" +
                            "<div class='popup-price p10' style='justify-content:flex-end'>"+
                                "<span class='price'>"+ price +"</span>"+
                            "</div>"+
                        "</div>";
                } else if(textFooter && price) {
                    content = "<div class='vipJson'>"+
                    "<div class='vipname ticket-selected-title gold'>" +
                        "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                    "</div>"+
                    "<ul class='list-unstyled ticket-selected'>"+
                        "<li style='border-left: none;'>"+
                            "<span class='ticket-lab'>Section </span> "+
                            "<span class='ticket-val'>"+section+"</span>"+
                        "</li>"+
                    "</ul>"+
                    "<div class='ticket-selected-view'>" +
                        "<div class='ticket-selected-view-img'></div>"+
                    "</div>" +
                    "<div class='popup-price p10'>"+
                        "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                    "</div>"+
                "</div>";
                }
                
            } else if(!textBody && (!textFooter || !price)){
                content = "<div class='vipJson'>"+
                            "<div class='vipname ticket-selected-title gold'>" +
                                "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                            "</div>"+
                            "<ul class='list-unstyled ticket-selected'>"+
                                "<li style='border-left: none;'>"+
                                    "<span class='ticket-lab'>Section </span> "+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                            "</ul>"+
                            "<div class='ticket-selected-view'>" +
                                "<div class='ticket-selected-view-img'></div>"+
                            "</div>" +
                        "</div>";
            } else {
                content = "<div class='vipJson'>"+
                                "<div class='vipname ticket-selected-title gold'>" +
                                    "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                                "</div>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                    "<li style='border-left: none;'>"+
                                        "<span class='ticket-lab'>Section </span> "+
                                        "<span class='ticket-val'>"+section+"</span>"+
                                    "</li>"+
                                "</ul>"+
                                "<div class='ticket-selected-view'>" +
                                    "<div class='ticket-selected-view-img'></div>"+
                                "</div>" +
                                "<div class='popup-text alignStart'>"+
                                    textBody+
                                "</div>"+
                                "<div class='popup-price p5'>"+
                                    "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                                "</div>"+
                            "</div>";
            }
        } else {
            if(textBody && (!textFooter && !price)) {
                content = "<div class='vipJson'>"+
                                "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                    "<ul class='list-unstyled ticket-selected'>"+
                                        "<li style='border-left: none;'>"+
                                            "<span class='ticket-lab'>Section </span> "+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                    "</ul>"+
                                    "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                                "</div>"+
                                "<div class='ticket-selected-view'>" +
                                    "<div class='ticket-selected-view-img'></div>"+
                                "</div>" +
                                "<div class='popup-text alignCenter'>"+
                                    textBody+
                                "</div>"+
                            "</div>";
            } else if(!textBody && (textFooter || price)) {
                if(textFooter && !price) {
                    content = "<div class='vipJson'>"+
                    "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                    "</div>"+
                    "<div class='ticket-selected-view'>" +
                        "<div class='ticket-selected-view-img'></div>"+
                    "</div>" +
                    "<div class='popup-price p10'>"+
                        "<span class='textFooter'>"+ textFooter +"</span> "+
                    "</div>"+
                "</div>";
                } else if(price && !textFooter) {
                    content = "<div class='vipJson'>"+
                    "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                    "</div>"+
                    "<div class='ticket-selected-view'>" +
                        "<div class='ticket-selected-view-img'></div>"+
                    "</div>" +
                    "<div class='popup-price p10' style='justify-content:flex-end'>"+
                        "<span class='price'>"+ price +"</span>"+
                    "</div>"+
                "</div>";
                } else if(textFooter && price) {
                    content = "<div class='vipJson'>"+
                    "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                    "</div>"+
                    "<div class='ticket-selected-view'>" +
                        "<div class='ticket-selected-view-img'></div>"+
                    "</div>" +
                    "<div class='popup-price p10'>"+
                        "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                    "</div>"+
                "</div>";
                }
                
            } else if(!textBody && (!textFooter || !price)){
                content = "<div class='vipJson'>"+
                                "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                    "<ul class='list-unstyled ticket-selected'>"+
                                        "<li style='border-left: none;'>"+
                                            "<span class='ticket-lab'>Section </span> "+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                    "</ul>"+
                                    "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                                "</div>"+
                                "<div class='ticket-selected-view'>" +
                                    "<div class='ticket-selected-view-img'></div>"+
                                "</div>" +
                            "</div>";            
            } else {
                content = "<div class='vipJson'>"+
                                "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                    "<ul class='list-unstyled ticket-selected'>"+
                                        "<li style='border-left: none;'>"+
                                            "<span class='ticket-lab'>Section </span> "+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                    "</ul>"+
                                    "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                                "</div>"+
                                "<div class='ticket-selected-view'>" +
                                    "<div class='ticket-selected-view-img'></div>"+
                                "</div>" +
                                "<div class='popup-text alignStart'>"+
                                    textBody+
                                "</div>"+
                                "<div class='popup-price p5'>"+
                                    "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                                "</div>"+
                            "</div>";
            }
        }
        
        return content;
    }

    popoverGetContentBlockVIPJson(obj){
        var split = obj.id.split("-");
        var section = split[0].split("_")[1];
        var sectionTranslation = split[0];

        if(obj.type=='areaprice'){
            section = obj.id.split("_")[1];
        }

        var content;

        var vipname = "";
        var textBody = "";
        var textFooter = "";
        var price = "";

        if(this.config.popupJson.sections[obj.id]) {
            vipname = this.config.popupJson.sections[obj.id].vipTitle;
            textBody = this.config.popupJson.sections[obj.id].textBody;
            textFooter = this.config.popupJson.sections[obj.id].textFooter;
            price = this.config.popupJson.sections[obj.id].price;
        } else {
            vipname = "";
            textBody = "";
            textFooter = "";
            price = "";
        }

        if(this.config.translation) {
            if(this.config.translationOptionsJson[sectionTranslation]) {
                section = this.config.translationOptionsJson[sectionTranslation]
            } else {
                section = section;
            }
        }

        if(vipname !== "") {
            if(textBody && (!textFooter && !price)) {
                content = "<div class='vipJson'>"+
                                "<div class='vipname ticket-selected-title gold'>" +
                                    "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                                "</div>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                    "<li style='border-left: none;'>"+
                                        "<span class='ticket-lab'>Section </span> "+
                                        "<span class='ticket-val'>"+section+"</span>"+
                                    "</li>"+
                                "</ul>"+
                                "<div class='popup-text alignCenter'>"+
                                    textBody
                                "</div>"+
                            "</div>";
            } else if(!textBody && (textFooter || price)) {
                if(textFooter && !price) {
                    content = "<div class='vipJson'>"+
                    "<div class='vipname ticket-selected-title gold'>" +
                        "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                    "</div>"+
                    "<ul class='list-unstyled ticket-selected'>"+
                        "<li style='border-left: none;'>"+
                            "<span class='ticket-lab'>Section </span> "+
                            "<span class='ticket-val'>"+section+"</span>"+
                        "</li>"+
                    "</ul>"+
                    "<div class='popup-price p10'>"+
                        "<span class='textFooter'>"+ textFooter +"</span> "+
                    "</div>"+
                "</div>"; 
                } else if(price && !textFooter) {
                    content = "<div class='vipJson'>"+
                    "<div class='vipname ticket-selected-title gold'>" +
                        "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                    "</div>"+
                    "<ul class='list-unstyled ticket-selected'>"+
                        "<li style='border-left: none;'>"+
                            "<span class='ticket-lab'>Section </span> "+
                            "<span class='ticket-val'>"+section+"</span>"+
                        "</li>"+
                    "</ul>"+
                    "<div class='popup-price p10' style='justify-content:flex-end'>"+
                        " <span class='price'>"+ price +"</span>"+
                    "</div>"+
                "</div>"; 
                } else if(textFooter && price) {
                    content = "<div class='vipJson'>"+
                    "<div class='vipname ticket-selected-title gold'>" +
                        "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                    "</div>"+
                    "<ul class='list-unstyled ticket-selected'>"+
                        "<li style='border-left: none;'>"+
                            "<span class='ticket-lab'>Section </span> "+
                            "<span class='ticket-val'>"+section+"</span>"+
                        "</li>"+
                    "</ul>"+
                    "<div class='popup-price p10'>"+
                        "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                    "</div>"+
                "</div>"; 
                }
                           
            } else if(!textBody && (!textFooter || !price)){
                content = "<div class='vipJson'>"+
                                "<div class='vipname ticket-selected-title gold'>" +
                                    "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                                "</div>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                    "<li style='border-left: none;'>"+
                                        "<span class='ticket-lab'>Section </span> "+
                                        "<span class='ticket-val'>"+section+"</span>"+
                                    "</li>"+
                                "</ul>"+
                            "</div>";            
            } else {
                content = "<div class='vipJson'>"+
                                "<div class='vipname ticket-selected-title gold'>" +
                                    "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                                "</div>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                    "<li style='border-left: none;'>"+
                                        "<span class='ticket-lab'>Section </span> "+
                                        "<span class='ticket-val'>"+section+"</span>"+
                                    "</li>"+
                                "</ul>"+
                                "<div class='popup-text alignStart'>"+
                                    textBody
                                "</div>"+
                                "<div class='popup-price p5'>"+
                                    "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                                "</div>"+
                            "</div>";
            }
        } else {
            if(textBody && (!textFooter && !price)) {
                content = "<div class='vipJson'>"+
                                "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                    "<ul class='list-unstyled ticket-selected'>"+
                                        "<li style='border-left: none;'>"+
                                            "<span class='ticket-lab'>Section </span> "+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                    "</ul>"+
                                    "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                                "</div>"+
                                "<div class='popup-text alignCenter'>"+
                                    textBody
                                "</div>"+
                            "</div>";
            } else if(!textBody && (textFooter || price)) {
                if(textFooter && !price) {
                    content = "<div class='vipJson'>"+
                    "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                    "</div>"+
                    "<div class='popup-price p10'>"+
                        "<span class='textFooter'>"+ textFooter +"</span> "+
                    "</div>"+
                "</div>";
                } else if(price && !textFooter) {
                    content = "<div class='vipJson'>"+
                    "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                    "</div>"+
                    "<div class='popup-price p10' style='justify-content:flex-end'>"+
                        " <span class='price'>"+ price +"</span>"+
                    "</div>"+
                "</div>";
                } else if(textFooter && price) {
                    content = "<div class='vipJson'>"+
                    "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<li style='border-left: none;'>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</ul>"+
                        "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                    "</div>"+
                    "<div class='popup-price p10'>"+
                        "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                    "</div>"+
                "</div>";
                }
                            
            } else if(!textBody && (!textFooter || !price)){
                content = "<div class='vipJson'>"+
                                "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                    "<ul class='list-unstyled ticket-selected'>"+
                                        "<li style='border-left: none;'>"+
                                            "<span class='ticket-lab'>Section </span> "+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                    "</ul>"+
                                    "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                                "</div>"+
                            "</div>";            
            } else {
                content = "<div class='vipJson'>"+
                                "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                    "<ul class='list-unstyled ticket-selected'>"+
                                        "<li style='border-left: none;'>"+
                                            "<span class='ticket-lab'>Section </span> "+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                    "</ul>"+
                                    "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                                "</div>"+
                                "<div class='popup-text alignStart'>"+
                                    textBody
                                "</div>"+
                                "<div class='popup-price p5'>"+
                                    "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                                "</div>"+
                            "</div>";
            }
        }

        return content;
    }

    //seats

    popoverGetContentSeatVIPImgJson(obj){
        var split = obj.id.split("-");
        var section = split[0].split("_")[1];
        var row = split[1];
        var seat = split[2];
        var sectionTranslation = split[0];

        var content;

        var vipname = "";
        var textBody = "";
        var textFooter = "";
        var price = "";

        if(this.config.popupJson.seats[split[0]+"-"+split[1]+"-"+split[2]]) {
            vipname = this.config.popupJson.seats[split[0]+"-"+split[1]+"-"+split[2]].vipTitle;
            textBody = this.config.popupJson.seats[split[0]+"-"+split[1]+"-"+split[2]].textBody;
            textFooter = this.config.popupJson.seats[split[0]+"-"+split[1]+"-"+split[2]].textFooter;
            price = this.config.popupJson.seats[split[0]+"-"+split[1]+"-"+split[2]].price;
        } else if(this.config.popupJson.rows[split[0]+"-"+split[1]]) {
            vipname = this.config.popupJson.rows[split[0]+"-"+split[1]].vipTitle;
            textBody = this.config.popupJson.rows[split[0]+"-"+split[1]].textBody;
            textFooter = this.config.popupJson.rows[split[0]+"-"+split[1]].textFooter;
            price = this.config.popupJson.rows[split[0]+"-"+split[1]].price;
        } else if(this.config.popupJson.sections[split[0]]) {
            vipname = this.config.popupJson.sections[split[0]].vipTitle;
            textBody = this.config.popupJson.sections[split[0]].textBody;
            textFooter = this.config.popupJson.sections[split[0]].textFooter;
            price = this.config.popupJson.sections[split[0]].price;
        }else {
            vipname = "";
            textBody = "";
            textFooter = "";
            price = "";
        }

        if(this.config.translation) {
            if(this.config.translationOptionsJson[sectionTranslation]) {
                section = this.config.translationOptionsJson[sectionTranslation]
            } else {
                section = section;
            }
        }

        if(vipname !== "") {
            if(textBody && (!textFooter && !price)) {
                content = "<div class='vipJson'>"+
                                "<div class='vipname ticket-selected-title gold'>" +
                                    "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                                "</div>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                    "<div class='section-div'>"+
                                        "<li>"+
                                            "<span class='ticket-lab'>Section </span>"+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                    "</div>"+
                                    "<div class='row-seat-div'>"+
                                        "<li>"+
                                            "<div class='separator'></div>"+
                                            "<span class='ticket-lab'>Row </span> "+
                                            "<span class='ticket-val'>"+row+"</span>"+
                                        "</li>"+
                                        "<li>"+
                                            "<div class='separator'></div>"+
                                            "<span class='ticket-lab'>Seat </span> "+
                                            "<span class='ticket-val'>"+seat+"</span>"+
                                        "</li>"+
                                    "</div>"+
                                "</ul>"+
                                "<div class='ticket-selected-view'>" +
                                    "<div class='ticket-selected-view-img'></div>"+
                                "</div>"+
                                "<div class='popup-text alignCenter'>"+
                                    textBody
                                "</div>"+
                            "</div>";
            } else if(!textBody && (textFooter || price)) {
                if(textFooter && !price) {
                    content = "<div class='vipJson'>"+
                    "<div class='vipname ticket-selected-title gold'>" +
                        "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                    "</div>"+
                    "<ul class='list-unstyled ticket-selected'>"+
                            "<div class='section-div'>"+
                                "<li>"+
                                    "<span class='ticket-lab'>Section </span>"+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                            "</div>"+
                            "<div class='row-seat-div'>"+
                                "<li>"+
                                    "<div class='separator'></div>"+
                                    "<span class='ticket-lab'>Row </span> "+
                                    "<span class='ticket-val'>"+row+"</span>"+
                                "</li>"+
                                "<li>"+
                                    "<div class='separator'></div>"+
                                    "<span class='ticket-lab'>Seat </span> "+
                                    "<span class='ticket-val'>"+seat+"</span>"+
                                "</li>"+
                            "</div>"+
                        "</ul>"+
                    "<div class='ticket-selected-view'>" +
                        "<div class='ticket-selected-view-img'></div>"+
                    "</div>"+
                    "<div class='popup-price p10'>"+
                        "<span class='textFooter'>"+ textFooter +"</span>"+
                    "</div>"+
                "</div>";
                } else if(price && !textFooter) {
                    content = "<div class='vipJson'>"+
                    "<div class='vipname ticket-selected-title gold'>" +
                        "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                    "</div>"+
                    "<ul class='list-unstyled ticket-selected'>"+
                            "<div class='section-div'>"+
                                "<li>"+
                                    "<span class='ticket-lab'>Section </span>"+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                            "</div>"+
                            "<div class='row-seat-div'>"+
                                "<li>"+
                                    "<div class='separator'></div>"+
                                    "<span class='ticket-lab'>Row </span> "+
                                    "<span class='ticket-val'>"+row+"</span>"+
                                "</li>"+
                                "<li>"+
                                    "<div class='separator'></div>"+
                                    "<span class='ticket-lab'>Seat </span> "+
                                    "<span class='ticket-val'>"+seat+"</span>"+
                                "</li>"+
                            "</div>"+
                        "</ul>"+
                    "<div class='ticket-selected-view'>" +
                        "<div class='ticket-selected-view-img'></div>"+
                    "</div>"+
                    "<div class='popup-price p10' style='justify-content:flex-end'>"+
                        "<span class='price'>"+ price +"</span>"+
                    "</div>"+
                "</div>";
                } else if(textFooter && price) {
                    content = "<div class='vipJson'>"+
                    "<div class='vipname ticket-selected-title gold'>" +
                        "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                    "</div>"+
                    "<ul class='list-unstyled ticket-selected'>"+
                            "<div class='section-div'>"+
                                "<li>"+
                                    "<span class='ticket-lab'>Section </span>"+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                            "</div>"+
                            "<div class='row-seat-div'>"+
                                "<li>"+
                                    "<div class='separator'></div>"+
                                    "<span class='ticket-lab'>Row </span> "+
                                    "<span class='ticket-val'>"+row+"</span>"+
                                "</li>"+
                                "<li>"+
                                    "<div class='separator'></div>"+
                                    "<span class='ticket-lab'>Seat </span> "+
                                    "<span class='ticket-val'>"+seat+"</span>"+
                                "</li>"+
                            "</div>"+
                        "</ul>"+
                    "<div class='ticket-selected-view'>" +
                        "<div class='ticket-selected-view-img'></div>"+
                    "</div>"+
                    "<div class='popup-price p10'>"+
                        "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                    "</div>"+
                "</div>";
                }
                
            } else if(!textBody && (!textFooter || !price)){
                content = "<div class='vipJson'>"+
                                "<div class='vipname ticket-selected-title gold'>" +
                                    "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                                    "<ul class='list-unstyled ticket-selected'>"+
                                    "<div class='section-div'>"+
                                        "<li>"+
                                            "<span class='ticket-lab'>Section </span>"+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                    "</div>"+
                                    "<div class='row-seat-div'>"+
                                        "<li>"+
                                            "<div class='separator'></div>"+
                                            "<span class='ticket-lab'>Row </span> "+
                                            "<span class='ticket-val'>"+row+"</span>"+
                                        "</li>"+
                                        "<li>"+
                                            "<div class='separator'></div>"+
                                            "<span class='ticket-lab'>Seat </span> "+
                                            "<span class='ticket-val'>"+seat+"</span>"+
                                        "</li>"+
                                    "</div>"+
                                "</ul>"+
                                "<div class='ticket-selected-view'>" +
                                    "<div class='ticket-selected-view-img'></div>"+
                                "</div>"+
                              "</div>";            
            } else {
                content = "<div class='vipJson'>"+
                                "<div class='vipname ticket-selected-title gold'>" +
                                    "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                                "</div>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                    "<div class='section-div'>"+
                                        "<li>"+
                                            "<span class='ticket-lab'>Section </span>"+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                    "</div>"+
                                    "<div class='row-seat-div'>"+
                                        "<li>"+
                                        "<div class='separator'></div>"+
                                            "<span class='ticket-lab'>Row </span> "+
                                            "<span class='ticket-val'>"+row+"</span>"+
                                        "</li>"+
                                        "<li>"+
                                        "<div class='separator'></div>"+
                                            "<span class='ticket-lab'>Seat </span> "+
                                            "<span class='ticket-val'>"+seat+"</span>"+
                                        "</li>"+
                                    "</div>"+
                                "</ul>"+
                                "<div class='ticket-selected-view'>" +
                                    "<div class='ticket-selected-view-img'></div>"+
                                "</div>"+
                                "<div class='popup-text alignStart'>"+
                                    textBody
                                "</div>"+
                                "<div class='popup-price p5'>"+
                                    "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                                "</div>"+
                              "</div>";
            }
        } else {
            if(textBody && (!textFooter && !price)) {
                content = "<div class='vipJson'>"+
                                "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                "<div class='section-div'>"+
                                    "<li>"+
                                        "<span class='ticket-lab'>Section </span>"+
                                        "<span class='ticket-val'>"+section+"</span>"+
                                    "</li>"+
                                "</div>"+
                                "<div class='row-seat-div'>"+
                                    "<li>"+
                                    "<div class='separator'></div>"+
                                        "<span class='ticket-lab'>Row </span> "+
                                        "<span class='ticket-val'>"+row+"</span>"+
                                    "</li>"+
                                    "<li>"+
                                    "<div class='separator'></div>"+
                                        "<span class='ticket-lab'>Seat </span> "+
                                        "<span class='ticket-val'>"+seat+"</span>"+
                                    "</li>"+
                                "</div>"+
                            "</ul>"+
                                    "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                                "</div>"+
                                "<div class='ticket-selected-view'>" +
                                    "<div class='ticket-selected-view-img'></div>"+
                                "</div>"+
                                "<div class='popup-text alignCenter'>"+
                                    textBody
                                "</div>"+
                            "</div>";
            } else if(!textBody && (textFooter || price)) {
                if(textFooter && !price) {
                    content = "<div class='vipJson'>"+
                    "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                    "<ul class='list-unstyled ticket-selected'>"+
                    "<div class='section-div'>"+
                        "<li>"+
                            "<span class='ticket-lab'>Section </span>"+
                            "<span class='ticket-val'>"+section+"</span>"+
                        "</li>"+
                    "</div>"+
                    "<div class='row-seat-div'>"+
                        "<li>"+
                        "<div class='separator'></div>"+
                            "<span class='ticket-lab'>Row </span> "+
                            "<span class='ticket-val'>"+row+"</span>"+
                        "</li>"+
                        "<li>"+
                        "<div class='separator'></div>"+
                            "<span class='ticket-lab'>Seat </span> "+
                            "<span class='ticket-val'>"+seat+"</span>"+
                        "</li>"+
                    "</div>"+
                "</ul>"+
                        "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                    "</div>"+
                    "<div class='ticket-selected-view'>" +
                        "<div class='ticket-selected-view-img'></div>"+
                    "</div>"+
                    "<div class='popup-price p10'>"+
                        "<span class='textFooter'>"+ textFooter +"</span> "+
                    "</div>"+
                "</div>";
                } else if(price && !textFooter) {
                    content = "<div class='vipJson'>"+
                    "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                    "<ul class='list-unstyled ticket-selected'>"+
                    "<div class='section-div'>"+
                        "<li>"+
                            "<span class='ticket-lab'>Section </span>"+
                            "<span class='ticket-val'>"+section+"</span>"+
                        "</li>"+
                    "</div>"+
                    "<div class='row-seat-div'>"+
                        "<li>"+
                        "<div class='separator'></div>"+
                            "<span class='ticket-lab'>Row </span> "+
                            "<span class='ticket-val'>"+row+"</span>"+
                        "</li>"+
                        "<li>"+
                        "<div class='separator'></div>"+
                            "<span class='ticket-lab'>Seat </span> "+
                            "<span class='ticket-val'>"+seat+"</span>"+
                        "</li>"+
                    "</div>"+
                "</ul>"+
                        "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                    "</div>"+
                    "<div class='ticket-selected-view'>" +
                        "<div class='ticket-selected-view-img'></div>"+
                    "</div>"+
                    "<div class='popup-price p10' style='justify-content:flex-end'>"+
                        "<span class='price'>"+ price +"</span>"+
                    "</div>"+
                "</div>";
                } else if(textFooter && price) {
                    content = "<div class='vipJson'>"+
                    "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                    "<ul class='list-unstyled ticket-selected'>"+
                    "<div class='section-div'>"+
                        "<li>"+
                            "<span class='ticket-lab'>Section </span>"+
                            "<span class='ticket-val'>"+section+"</span>"+
                        "</li>"+
                    "</div>"+
                    "<div class='row-seat-div'>"+
                        "<li>"+
                        "<div class='separator'></div>"+
                            "<span class='ticket-lab'>Row </span> "+
                            "<span class='ticket-val'>"+row+"</span>"+
                        "</li>"+
                        "<li>"+
                        "<div class='separator'></div>"+
                            "<span class='ticket-lab'>Seat </span> "+
                            "<span class='ticket-val'>"+seat+"</span>"+
                        "</li>"+
                    "</div>"+
                "</ul>"+
                        "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                    "</div>"+
                    "<div class='ticket-selected-view'>" +
                        "<div class='ticket-selected-view-img'></div>"+
                    "</div>"+
                    "<div class='popup-price p10'>"+
                        "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                    "</div>"+
                "</div>";
                }
                
            } else if(!textBody && (!textFooter || !price)){
                content = "<div class='vipJson'>"+
                    "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                    "<ul class='list-unstyled ticket-selected'>"+
                    "<div class='section-div'>"+
                        "<li>"+
                            "<span class='ticket-lab'>Section </span>"+
                            "<span class='ticket-val'>"+section+"</span>"+
                        "</li>"+
                    "</div>"+
                    "<div class='row-seat-div'>"+
                        "<li>"+
                        "<div class='separator'></div>"+
                            "<span class='ticket-lab'>Row </span> "+
                            "<span class='ticket-val'>"+row+"</span>"+
                        "</li>"+
                        "<li>"+
                        "<div class='separator'></div>"+
                            "<span class='ticket-lab'>Seat </span> "+
                            "<span class='ticket-val'>"+seat+"</span>"+
                        "</li>"+
                    "</div>"+
                "</ul>"+
                        "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                    "</div>"+
                    "<div class='ticket-selected-view'>" +
                        "<div class='ticket-selected-view-img'></div>"+
                    "</div>"+
                "</div>";
            } else {
                content = "<div class='vipJson'>"+
                                "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                "<div class='section-div'>"+
                                    "<li>"+
                                        "<span class='ticket-lab'>Section </span>"+
                                        "<span class='ticket-val'>"+section+"</span>"+
                                    "</li>"+
                                "</div>"+
                                "<div class='row-seat-div'>"+
                                    "<li>"+
                                    "<div class='separator'></div>"+
                                        "<span class='ticket-lab'>Row </span> "+
                                        "<span class='ticket-val'>"+row+"</span>"+
                                    "</li>"+
                                    "<li>"+
                                    "<div class='separator'></div>"+
                                        "<span class='ticket-lab'>Seat </span> "+
                                        "<span class='ticket-val'>"+seat+"</span>"+
                                    "</li>"+
                                "</div>"+
                            "</ul>"+
                                    "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                                "</div>"+
                                "<div class='ticket-selected-view'>" +
                                    "<div class='ticket-selected-view-img'></div>"+
                                "</div>"+
                                "<div class='popup-text alignStart'>"+
                                    textBody
                                "</div>"+
                                "<div class='popup-price p5'>"+
                                    "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                                "</div>"+
                            "</div>";
            }
        }

        return content;
    }

    popoverGetContentSeatVIPJson(obj){
        var split = obj.id.split("-");
        var section = split[0].split("_")[1];
        var row = split[1];
        var seat = split[2];
        var sectionTranslation = split[0];

        var content;

        var vipname = "";
        var textBody = "";
        var textFooter = "";
        var price = "";

        if(this.config.popupJson.seats[split[0]+"-"+split[1]+"-"+split[2]]) {
            vipname = this.config.popupJson.seats[split[0]+"-"+split[1]+"-"+split[2]].vipTitle;
            textBody = this.config.popupJson.seats[split[0]+"-"+split[1]+"-"+split[2]].textBody;
            textFooter = this.config.popupJson.seats[split[0]+"-"+split[1]+"-"+split[2]].textFooter;
            price = this.config.popupJson.seats[split[0]+"-"+split[1]+"-"+split[2]].price;
        } else if(this.config.popupJson.rows[split[0]+"-"+split[1]]) {
            vipname = this.config.popupJson.rows[split[0]+"-"+split[1]].vipTitle;
            textBody = this.config.popupJson.rows[split[0]+"-"+split[1]].textBody;
            textFooter = this.config.popupJson.rows[split[0]+"-"+split[1]].textFooter;
            price = this.config.popupJson.rows[split[0]+"-"+split[1]].price;
        } else if(this.config.popupJson.sections[split[0]]) {
            vipname = this.config.popupJson.sections[split[0]].vipTitle;
            textBody = this.config.popupJson.sections[split[0]].textBody;
            textFooter = this.config.popupJson.sections[split[0]].textFooter;
            price = this.config.popupJson.sections[split[0]].price;
        }else {
            vipname = "";
            textBody = "";
            textFooter = "";
            price = "";
        }

        if(this.config.translation) {
            if(this.config.translationOptionsJson[sectionTranslation]) {
                section = this.config.translationOptionsJson[sectionTranslation]
            } else {
                section = section;
            }
        }

        if(vipname !== "") {
            if(textBody && (!textFooter && !price)) {
                content = "<div class='vipJson'>"+
                                "<div class='vipname ticket-selected-title gold'>" +
                                    "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                                "</div>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                    "<div class='section-div'>"+
                                        "<li>"+
                                            "<span class='ticket-lab'>Section </span>"+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                    "</div>"+
                                    "<div class='row-seat-div'>"+
                                        "<li>"+
                                        "<div class='separator'></div>"+
                                            "<span class='ticket-lab'>Row </span> "+
                                            "<span class='ticket-val'>"+row+"</span>"+
                                        "</li>"+
                                        "<li>"+
                                        "<div class='separator'></div>"+
                                            "<span class='ticket-lab'>Seat </span> "+
                                            "<span class='ticket-val'>"+seat+"</span>"+
                                        "</li>"+
                                    "</div>"+
                                "</ul>"+
                                "<div class='popup-text alignCenter'>"+
                                    textBody
                                "</div>"+
                            "</div>";
            } else if(!textBody && (textFooter || price)) {
                if(textFooter && !price) {
                    content = "<div class='vipJson'>"+
                    "<div class='vipname ticket-selected-title gold'>" +
                        "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                    "</div>"+
                    "<ul class='list-unstyled ticket-selected'>"+
                        "<div class='section-div'>"+
                            "<li>"+
                                "<span class='ticket-lab'>Section </span>"+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</div>"+
                        "<div class='row-seat-div'>"+
                            "<li>"+
                            "<div class='separator'></div>"+
                                "<span class='ticket-lab'>Row </span> "+
                                "<span class='ticket-val'>"+row+"</span>"+
                            "</li>"+
                            "<li>"+
                            "<div class='separator'></div>"+
                                "<span class='ticket-lab'>Seat </span> "+
                                "<span class='ticket-val'>"+seat+"</span>"+
                            "</li>"+
                        "</div>"+
                    "</ul>"+
                    "<div class='popup-price p10'>"+
                        "<span class='textFooter'>"+ textFooter +"</span> "+
                    "</div>"+
                "</div>";
                } else if(price && !textFooter) {
                    content = "<div class='vipJson'>"+
                    "<div class='vipname ticket-selected-title gold'>" +
                        "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                    "</div>"+
                    "<ul class='list-unstyled ticket-selected'>"+
                        "<div class='section-div'>"+
                            "<li>"+
                                "<span class='ticket-lab'>Section </span>"+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</div>"+
                        "<div class='row-seat-div'>"+
                            "<li>"+
                            "<div class='separator'></div>"+
                                "<span class='ticket-lab'>Row </span> "+
                                "<span class='ticket-val'>"+row+"</span>"+
                            "</li>"+
                            "<li>"+
                            "<div class='separator'></div>"+
                                "<span class='ticket-lab'>Seat </span> "+
                                "<span class='ticket-val'>"+seat+"</span>"+
                            "</li>"+
                        "</div>"+
                    "</ul>"+
                    "<div class='popup-price p10' style='justify-content:flex-end'>"+
                        "<span class='price'>"+ price +"</span>"+
                    "</div>"+
                "</div>";
                } else if(textFooter && price) {
                    content = "<div class='vipJson'>"+
                    "<div class='vipname ticket-selected-title gold'>" +
                        "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                    "</div>"+
                    "<ul class='list-unstyled ticket-selected'>"+
                        "<div class='section-div'>"+
                            "<li>"+
                                "<span class='ticket-lab'>Section </span>"+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</div>"+
                        "<div class='row-seat-div'>"+
                            "<li>"+
                            "<div class='separator'></div>"+
                                "<span class='ticket-lab'>Row </span> "+
                                "<span class='ticket-val'>"+row+"</span>"+
                            "</li>"+
                            "<li>"+
                            "<div class='separator'></div>"+
                                "<span class='ticket-lab'>Seat </span> "+
                                "<span class='ticket-val'>"+seat+"</span>"+
                            "</li>"+
                        "</div>"+
                    "</ul>"+
                    "<div class='popup-price p10'>"+
                        "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                    "</div>"+
                "</div>";
                }
                
            } else if(!textBody && (!textFooter || !price)){
                content = "<div class='vipJson'>"+
                "<div class='vipname ticket-selected-title gold'>" +
                    "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                "</div>"+
                "<ul class='list-unstyled ticket-selected'>"+
                    "<div class='section-div'>"+
                        "<li>"+
                            "<span class='ticket-lab'>Section </span>"+
                            "<span class='ticket-val'>"+section+"</span>"+
                        "</li>"+
                    "</div>"+
                    "<div class='row-seat-div'>"+
                        "<li>"+
                        "<div class='separator'></div>"+
                            "<span class='ticket-lab'>Row </span> "+
                            "<span class='ticket-val'>"+row+"</span>"+
                        "</li>"+
                        "<li>"+
                        "<div class='separator'></div>"+
                            "<span class='ticket-lab'>Seat </span> "+
                            "<span class='ticket-val'>"+seat+"</span>"+
                        "</li>"+
                    "</div>"+
                "</ul>"+
                "</div>";
            } else {
                content = "<div class='vipJson'>"+
                                "<div class='vipname ticket-selected-title gold'>" +
                                    "<span>"+ vipname +"</span> <i class='icon-star'>VIP</i> "+
                                "</div>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                    "<div class='section-div'>"+
                                        "<li>"+
                                            "<span class='ticket-lab'>Section </span>"+
                                            "<span class='ticket-val'>"+section+"</span>"+
                                        "</li>"+
                                    "</div>"+
                                    "<div class='row-seat-div'>"+
                                        "<li>"+
                                        "<div class='separator'></div>"+
                                            "<span class='ticket-lab'>Row </span> "+
                                            "<span class='ticket-val'>"+row+"</span>"+
                                        "</li>"+
                                        "<li>"+
                                        "<div class='separator'></div>"+
                                            "<span class='ticket-lab'>Seat </span> "+
                                            "<span class='ticket-val'>"+seat+"</span>"+
                                        "</li>"+
                                    "</div>"+
                                "</ul>"+
                                "<div class='popup-text alignStart'>"+
                                    textBody
                                "</div>"+
                                "<div class='popup-price p5'>"+
                                    "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                                "</div>"+
                            "</div>";
            }
        } else {
            if(textBody && (!textFooter && !price)) {
                content = "<div class='vipJson'>"+
                                "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                "<div class='section-div'>"+
                                    "<li>"+
                                        "<span class='ticket-lab'>Section </span>"+
                                        "<span class='ticket-val'>"+section+"</span>"+
                                    "</li>"+
                                "</div>"+
                                "<div class='row-seat-div'>"+
                                    "<li>"+
                                    "<div class='separator'></div>"+
                                        "<span class='ticket-lab'>Row </span> "+
                                        "<span class='ticket-val'>"+row+"</span>"+
                                    "</li>"+
                                    "<li>"+
                                    "<div class='separator'></div>"+
                                        "<span class='ticket-lab'>Seat </span> "+
                                        "<span class='ticket-val'>"+seat+"</span>"+
                                    "</li>"+
                                "</div>"+
                            "</ul>"+
                                    "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                                "</div>"+
                                "<div class='popup-text alignCenter'>"+
                                    textBody
                                "</div>"+
                            "</div>";
            } else if(!textBody && (textFooter || price)) {
                if(textFooter && !price) {
                    content = "<div class='vipJson'>"+
                    "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                    "<ul class='list-unstyled ticket-selected'>"+
                    "<div class='section-div'>"+
                        "<li>"+
                            "<span class='ticket-lab'>Section </span>"+
                            "<span class='ticket-val'>"+section+"</span>"+
                        "</li>"+
                    "</div>"+
                    "<div class='row-seat-div'>"+
                        "<li>"+
                        "<div class='separator'></div>"+
                            "<span class='ticket-lab'>Row </span> "+
                            "<span class='ticket-val'>"+row+"</span>"+
                        "</li>"+
                        "<li>"+
                        "<div class='separator'></div>"+
                            "<span class='ticket-lab'>Seat </span> "+
                            "<span class='ticket-val'>"+seat+"</span>"+
                        "</li>"+
                    "</div>"+
                "</ul>"+
                        "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                    "</div>"+
                    "<div class='popup-price p10'>"+
                        "<span class='textFooter'>"+ textFooter +"</span> "+
                    "</div>"+
                "</div>";
                } else if(price && !textFooter) {
                    content = "<div class='vipJson'>"+
                    "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                    "<ul class='list-unstyled ticket-selected'>"+
                    "<div class='section-div'>"+
                        "<li>"+
                            "<span class='ticket-lab'>Section </span>"+
                            "<span class='ticket-val'>"+section+"</span>"+
                        "</li>"+
                    "</div>"+
                    "<div class='row-seat-div'>"+
                        "<li>"+
                        "<div class='separator'></div>"+
                            "<span class='ticket-lab'>Row </span> "+
                            "<span class='ticket-val'>"+row+"</span>"+
                        "</li>"+
                        "<li>"+
                        "<div class='separator'></div>"+
                            "<span class='ticket-lab'>Seat </span> "+
                            "<span class='ticket-val'>"+seat+"</span>"+
                        "</li>"+
                    "</div>"+
                "</ul>"+
                        "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                    "</div>"+
                    "<div class='popup-price p10' style='justify-content:flex-end'>"+
                        " <span class='price'>"+ price +"</span>"+
                    "</div>"+
                "</div>";
                } else if(textFooter && price) {
                    content = "<div class='vipJson'>"+
                    "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                    "<ul class='list-unstyled ticket-selected'>"+
                    "<div class='section-div'>"+
                        "<li>"+
                            "<span class='ticket-lab'>Section </span>"+
                            "<span class='ticket-val'>"+section+"</span>"+
                        "</li>"+
                    "</div>"+
                    "<div class='row-seat-div'>"+
                        "<li>"+
                        "<div class='separator'></div>"+
                            "<span class='ticket-lab'>Row </span> "+
                            "<span class='ticket-val'>"+row+"</span>"+
                        "</li>"+
                        "<li>"+
                        "<div class='separator'></div>"+
                            "<span class='ticket-lab'>Seat </span> "+
                            "<span class='ticket-val'>"+seat+"</span>"+
                        "</li>"+
                    "</div>"+
                "</ul>"+
                        "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                    "</div>"+
                    "<div class='popup-price p10'>"+
                        "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                    "</div>"+
                "</div>";
                }
                
            } else if(!textBody && (!textFooter || !price)){
                content = "<div class='vipJson'>"+
                            "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                            "<ul class='list-unstyled ticket-selected'>"+
                            "<div class='section-div'>"+
                                "<li>"+
                                    "<span class='ticket-lab'>Section </span>"+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                            "</div>"+
                            "<div class='row-seat-div'>"+
                                "<li>"+
                                "<div class='separator'></div>"+
                                    "<span class='ticket-lab'>Row </span> "+
                                    "<span class='ticket-val'>"+row+"</span>"+
                                "</li>"+
                                "<li>"+
                                "<div class='separator'></div>"+
                                    "<span class='ticket-lab'>Seat </span> "+
                                    "<span class='ticket-val'>"+seat+"</span>"+
                                "</li>"+
                            "</div>"+
                        "</ul>"+
                                "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                            "</div>"+
                        "</div>";      
            } else {
                content = "<div class='vipJson'>"+
                                "<div style='display:flex; justify-content: space-between; align-items:center;'>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                "<div class='section-div'>"+
                                    "<li>"+
                                        "<span class='ticket-lab'>Section </span>"+
                                        "<span class='ticket-val'>"+section+"</span>"+
                                    "</li>"+
                                "</div>"+
                                "<div class='row-seat-div'>"+
                                    "<li>"+
                                    "<div class='separator'></div>"+
                                        "<span class='ticket-lab'>Row </span> "+
                                        "<span class='ticket-val'>"+row+"</span>"+
                                    "</li>"+
                                    "<li>"+
                                    "<div class='separator'></div>"+
                                        "<span class='ticket-lab'>Seat </span> "+
                                        "<span class='ticket-val'>"+seat+"</span>"+
                                    "</li>"+
                                "</div>"+
                            "</ul>"+
                                    "<i style='color: #FFDD00; font-family: Poppins-Bold; padding: 5px 10px 10px 10px;' class='icon-star'>VIP</i>"+
                                "</div>"+
                                "<div class='popup-text alignStart'>"+
                                    textBody
                                "</div>"+
                                "<div class='popup-price p5'>"+
                                    "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                                "</div>"+
                            "</div>";
                
            }
        }

        return content;
    }

    popoverGetContentSeatImgJson(obj){
        var split = obj.id.split("-");
        var section = split[0].split("_")[1];
        var row = split[1];
        var seat = split[2];
        var sectionTranslation = split[0];

        var content;

        var textBody = "";
        var textFooter = "";
        var price = "";

        if(this.config.popupJson.seats[split[0]+"-"+split[1]+"-"+split[2]]) {
            textBody = this.config.popupJson.seats[split[0]+"-"+split[1]+"-"+split[2]].textBody;
            textFooter = this.config.popupJson.seats[split[0]+"-"+split[1]+"-"+split[2]].textFooter;
            price = this.config.popupJson.seats[split[0]+"-"+split[1]+"-"+split[2]].price;
        } else if(this.config.popupJson.rows[split[0]+"-"+split[1]]) {
            textBody = this.config.popupJson.rows[split[0]+"-"+split[1]].textBody;
            textFooter = this.config.popupJson.rows[split[0]+"-"+split[1]].textFooter;
            price = this.config.popupJson.rows[split[0]+"-"+split[1]].price;
        } else if(this.config.popupJson.sections[split[0]]) {
            textBody = this.config.popupJson.sections[split[0]].textBody;
            textFooter = this.config.popupJson.sections[split[0]].textFooter;
            price = this.config.popupJson.sections[split[0]].price;
        }else {
            textBody = "";
            textFooter = "";
            price = "";
        }

        if(this.config.translation) {
            if(this.config.translationOptionsJson[sectionTranslation]) {
                section = this.config.translationOptionsJson[sectionTranslation]
            } else {
                section = section;
            }
        }

        if(textBody && (!textFooter && !price)) {
            content = "<div class='regularJson'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                            "<div class='section-div'>"+
                                "<li>"+
                                    "<span class='ticket-lab'>Section </span>"+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                            "</div>"+
                            "<div class='row-seat-div'>"+
                                "<li>"+
                                "<div class='separator'></div>"+
                                    "<span class='ticket-lab'>Row </span> "+
                                    "<span class='ticket-val'>"+row+"</span>"+
                                "</li>"+
                                "<li>"+
                                "<div class='separator'></div>"+
                                    "<span class='ticket-lab'>Seat </span> "+
                                    "<span class='ticket-val'>"+seat+"</span>"+
                                "</li>"+
                            "</div>"+
                        "</ul>"+
                        "<div class='ticket-selected-view'>" +
                            "<div class='ticket-selected-view-img'></div>"+
                        "</div>" +
                        "<div class='popup-text alignCenter'>"+
                            textBody+
                        "</div>"+
                    "</div>";
        } else if(!textBody && (textFooter || price)) {
            if(textFooter && !price) {
                content = "<div class='regularJson'>"+
                "<ul class='list-unstyled ticket-selected'>"+
                "<div class='section-div'>"+
                    "<li>"+
                        "<span class='ticket-lab'>Section </span>"+
                        "<span class='ticket-val'>"+section+"</span>"+
                    "</li>"+
                "</div>"+
                "<div class='row-seat-div'>"+
                    "<li>"+
                    "<div class='separator'></div>"+
                        "<span class='ticket-lab'>Row </span> "+
                        "<span class='ticket-val'>"+row+"</span>"+
                    "</li>"+
                    "<li>"+
                    "<div class='separator'></div>"+
                        "<span class='ticket-lab'>Seat </span> "+
                        "<span class='ticket-val'>"+seat+"</span>"+
                    "</li>"+
                "</div>"+
            "</ul>"+
                    "<div class='ticket-selected-view'>" +
                        "<div class='ticket-selected-view-img'></div>"+
                    "</div>" +
                    "<div class='popup-price p10'>"+
                        "<span class='textFooter'>"+ textFooter +"</span> "+
                    "</div>"+
                "</div>";
            } else if(price && !textFooter) {
                content = "<div class='regularJson'>"+
                "<ul class='list-unstyled ticket-selected'>"+
                "<div class='section-div'>"+
                    "<li>"+
                        "<span class='ticket-lab'>Section </span>"+
                        "<span class='ticket-val'>"+section+"</span>"+
                    "</li>"+
                "</div>"+
                "<div class='row-seat-div'>"+
                    "<li>"+
                    "<div class='separator'></div>"+
                        "<span class='ticket-lab'>Row </span> "+
                        "<span class='ticket-val'>"+row+"</span>"+
                    "</li>"+
                    "<li>"+
                    "<div class='separator'></div>"+
                        "<span class='ticket-lab'>Seat </span> "+
                        "<span class='ticket-val'>"+seat+"</span>"+
                    "</li>"+
                "</div>"+
            "</ul>"+
                    "<div class='ticket-selected-view'>" +
                        "<div class='ticket-selected-view-img'></div>"+
                    "</div>" +
                    "<div class='popup-price p10' style='justify-content:flex-end'>"+
                        "<span class='price'>"+ price +"</span>"+
                    "</div>"+
                "</div>";
            } else if(textFooter && price) {
                content = "<div class='regularJson'>"+
                "<ul class='list-unstyled ticket-selected'>"+
                "<div class='section-div'>"+
                    "<li>"+
                        "<span class='ticket-lab'>Section </span>"+
                        "<span class='ticket-val'>"+section+"</span>"+
                    "</li>"+
                "</div>"+
                "<div class='row-seat-div'>"+
                    "<li>"+
                    "<div class='separator'></div>"+
                        "<span class='ticket-lab'>Row </span> "+
                        "<span class='ticket-val'>"+row+"</span>"+
                    "</li>"+
                    "<li>"+
                    "<div class='separator'></div>"+
                        "<span class='ticket-lab'>Seat </span> "+
                        "<span class='ticket-val'>"+seat+"</span>"+
                    "</li>"+
                "</div>"+
            "</ul>"+
                    "<div class='ticket-selected-view'>" +
                        "<div class='ticket-selected-view-img'></div>"+
                    "</div>" +
                    "<div class='popup-price p10'>"+
                        "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                    "</div>"+
                "</div>";
            }
            
        } else if(!textBody && (!textFooter || !price)){
            content = "<div class='regularJson'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                        "<div class='section-div'>"+
                            "<li>"+
                                "<span class='ticket-lab'>Section </span>"+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</div>"+
                        "<div class='row-seat-div'>"+
                            "<li>"+
                            "<div class='separator'></div>"+
                                "<span class='ticket-lab'>Row </span> "+
                                "<span class='ticket-val'>"+row+"</span>"+
                            "</li>"+
                            "<li>"+
                            "<div class='separator'></div>"+
                                "<span class='ticket-lab'>Seat </span> "+
                                "<span class='ticket-val'>"+seat+"</span>"+
                            "</li>"+
                        "</div>"+
                    "</ul>"+
                        "<div class='ticket-selected-view'>" +
                            "<div class='ticket-selected-view-img'></div>"+
                        "</div>" +
                    "</div>";
        } else {
            content = "<div class='regularJson'>"+
                            "<ul class='list-unstyled ticket-selected'>"+
                            "<div class='section-div'>"+
                                "<li>"+
                                    "<span class='ticket-lab'>Section </span>"+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                            "</div>"+
                            "<div class='row-seat-div'>"+
                                "<li>"+
                                "<div class='separator'></div>"+
                                    "<span class='ticket-lab'>Row </span> "+
                                    "<span class='ticket-val'>"+row+"</span>"+
                                "</li>"+
                                "<li>"+
                                "<div class='separator'></div>"+
                                    "<span class='ticket-lab'>Seat </span> "+
                                    "<span class='ticket-val'>"+seat+"</span>"+
                                "</li>"+
                            "</div>"+
                        "</ul>"+
                            "<div class='ticket-selected-view'>" +
                                "<div class='ticket-selected-view-img'></div>"+
                            "</div>" +
                            "<div class='popup-text alignStart'>"+
                                textBody+
                            "</div>"+
                            "<div class='popup-price p5'>"+
                                "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                            "</div>"+
                        "</div>";
        }


        return content;
    }

    popoverGetContentSeatJson(obj){
        var split = obj.id.split("-");
        var section = split[0].split("_")[1];
        var row = split[1];
        var seat = split[2];
        var sectionTranslation = split[0];

        var content;

        var textBody = "";
        var textFooter = "";
        var price = "";

        if(this.config.popupJson.seats[split[0]+"-"+split[1]+"-"+split[2]]) {
            textBody = this.config.popupJson.seats[split[0]+"-"+split[1]+"-"+split[2]].textBody;
            textFooter = this.config.popupJson.seats[split[0]+"-"+split[1]+"-"+split[2]].textFooter;
            price = this.config.popupJson.seats[split[0]+"-"+split[1]+"-"+split[2]].price;
        } else if(this.config.popupJson.rows[split[0]+"-"+split[1]]) {
            textBody = this.config.popupJson.rows[split[0]+"-"+split[1]].textBody;
            textFooter = this.config.popupJson.rows[split[0]+"-"+split[1]].textFooter;
            price = this.config.popupJson.rows[split[0]+"-"+split[1]].price;
        } else if(this.config.popupJson.sections[split[0]]) {
            textBody = this.config.popupJson.sections[split[0]].textBody;
            textFooter = this.config.popupJson.sections[split[0]].textFooter;
            price = this.config.popupJson.sections[split[0]].price;
        }else {
            textBody = "";
            textFooter = "";
            price = "";
        }

        if(this.config.translation) {
            if(this.config.translationOptionsJson[sectionTranslation]) {
                section = this.config.translationOptionsJson[sectionTranslation]
            } else {
                section = section;
            }
        }

        if(textBody && (!textFooter && !price)) {
            content = "<div class='regularJson'>"+
                        "<ul class='list-unstyled ticket-selected'>"+
                        "<div class='section-div'>"+
                            "<li>"+
                                "<span class='ticket-lab'>Section </span>"+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</div>"+
                        "<div class='row-seat-div'>"+
                            "<li>"+
                            "<div class='separator'></div>"+
                                "<span class='ticket-lab'>Row </span> "+
                                "<span class='ticket-val'>"+row+"</span>"+
                            "</li>"+
                            "<li>"+
                            "<div class='separator'></div>"+
                                "<span class='ticket-lab'>Seat </span> "+
                                "<span class='ticket-val'>"+seat+"</span>"+
                            "</li>"+
                        "</div>"+
                    "</ul>"+
                            "<div class='popup-text alignCenter'>"+
                                textBody+
                            "</div>"+
                        "</div>";
        } else if(!textBody && (textFooter || price)) {
            if(textFooter && !price) {
                content = "<div class='regularJson'>"+
                "<ul class='list-unstyled ticket-selected'>"+
                "<div class='section-div'>"+
                    "<li>"+
                        "<span class='ticket-lab'>Section </span>"+
                        "<span class='ticket-val'>"+section+"</span>"+
                    "</li>"+
                "</div>"+
                "<div class='row-seat-div'>"+
                    "<li>"+
                    "<div class='separator'></div>"+
                        "<span class='ticket-lab'>Row </span> "+
                        "<span class='ticket-val'>"+row+"</span>"+
                    "</li>"+
                    "<li>"+
                    "<div class='separator'></div>"+
                        "<span class='ticket-lab'>Seat </span> "+
                        "<span class='ticket-val'>"+seat+"</span>"+
                    "</li>"+
                "</div>"+
            "</ul>"+
                "<div class='popup-price p10'>"+
                    "<span class='textFooter'>"+ textFooter +"</span> "+
                "</div>"+
            "</div>";
            } else if(price && !textFooter) {
                content = "<div class='regularJson'>"+
                "<ul class='list-unstyled ticket-selected'>"+
                "<div class='section-div'>"+
                    "<li>"+
                        "<span class='ticket-lab'>Section </span>"+
                        "<span class='ticket-val'>"+section+"</span>"+
                    "</li>"+
                "</div>"+
                "<div class='row-seat-div'>"+
                    "<li>"+
                    "<div class='separator'></div>"+
                        "<span class='ticket-lab'>Row </span> "+
                        "<span class='ticket-val'>"+row+"</span>"+
                    "</li>"+
                    "<li>"+
                    "<div class='separator'></div>"+
                        "<span class='ticket-lab'>Seat </span> "+
                        "<span class='ticket-val'>"+seat+"</span>"+
                    "</li>"+
                "</div>"+
            "</ul>"+
                "<div class='popup-price p10' style='justify-content:flex-end'>"+
                    " <span class='price'>"+ price +"</span>"+
                "</div>"+
            "</div>";
            } else if(textFooter && price) {
                content = "<div class='regularJson'>"+
                "<ul class='list-unstyled ticket-selected'>"+
                "<div class='section-div'>"+
                    "<li>"+
                        "<span class='ticket-lab'>Section </span>"+
                        "<span class='ticket-val'>"+section+"</span>"+
                    "</li>"+
                "</div>"+
                "<div class='row-seat-div'>"+
                    "<li>"+
                    "<div class='separator'></div>"+
                        "<span class='ticket-lab'>Row </span> "+
                        "<span class='ticket-val'>"+row+"</span>"+
                    "</li>"+
                    "<li>"+
                    "<div class='separator'></div>"+
                        "<span class='ticket-lab'>Seat </span> "+
                        "<span class='ticket-val'>"+seat+"</span>"+
                    "</li>"+
                "</div>"+
            "</ul>"+
                "<div class='popup-price p10'>"+
                    "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                "</div>"+
            "</div>";
            }
            
        } else if(!textBody && (!textFooter || !price)){
            content = "<div class='regularJson'>"+
                                "<ul class='list-unstyled ticket-selected'>"+
                                "<div class='section-div'>"+
                                    "<li>"+
                                        "<span class='ticket-lab'>Section </span>"+
                                        "<span class='ticket-val'>"+section+"</span>"+
                                    "</li>"+
                                "</div>"+
                                "<div class='row-seat-div'>"+
                                    "<li>"+
                                    "<div class='separator'></div>"+
                                        "<span class='ticket-lab'>Row </span> "+
                                        "<span class='ticket-val'>"+row+"</span>"+
                                    "</li>"+
                                    "<li>"+
                                    "<div class='separator'></div>"+
                                        "<span class='ticket-lab'>Seat </span> "+
                                        "<span class='ticket-val'>"+seat+"</span>"+
                                    "</li>"+
                                "</div>"+
                            "</ul>"+
                        "</div>";        
        } else {
            content = "<div class='regularJson'>"+
                            "<ul class='list-unstyled ticket-selected'>"+
                            "<div class='section-div'>"+
                                "<li>"+
                                    "<span class='ticket-lab'>Section </span>"+
                                    "<span class='ticket-val'>"+section+"</span>"+
                                "</li>"+
                            "</div>"+
                            "<div class='row-seat-div'>"+
                                "<li>"+
                                "<div class='separator'></div>"+
                                    "<span class='ticket-lab'>Row </span> "+
                                    "<span class='ticket-val'>"+row+"</span>"+
                                "</li>"+
                                "<li>"+
                                "<div class='separator'></div>"+
                                    "<span class='ticket-lab'>Seat </span> "+
                                    "<span class='ticket-val'>"+seat+"</span>"+
                                "</li>"+
                            "</div>"+
                        "</ul>"+
                            "<div class='popup-text alignStart'>"+
                                textBody+
                            "</div>"+
                            "<div class='popup-price p5'>"+
                                "<span class='textFooter'>"+ textFooter +"</span> <span class='price'>"+ price +"</span>"+
                            "</div>"+
                        "</div>";
        }

        return content;
    }

    /* SIN JSON */

    //blocks

    popoverGetContentBlockImg(obj){
        var split = obj.id.split("-");
        var section = split[0].split("_")[1];
        var sectionTranslation = split[0];

        if(obj.type=='areaprice'){
            section = obj.id.split("_")[1];
        }

        if(obj.id.indexOf("G_") !== -1) {
            var sectionLab = "Group"
        } else if (obj.id.indexOf("S_") !== -1) {
            var sectionLab = "Section"
        } else if (obj.id.indexOf("V_") !== -1) {
            var sectionLab = "Section"
        }

        if(this.config.translation) {
            if(this.config.translationOptionsJson[sectionTranslation]) {
                section = this.config.translationOptionsJson[sectionTranslation]
            } else {
                section = section;
            }
        }

        var content = "<div class='ticket-selected-view'>" +
                        "<div class='ticket-selected-view-img'></div>"+
                    "</div>" +
                    "<ul class='list-unstyled ticket-selected'>"+
                        "<li>"+
                            "<span class='ticket-lab'>"+ sectionLab + " </span> "+
                            "<span class='ticket-val'>"+section+"</span>"+
                        "</li>"+
                    "</ul>";
        return content;
    }

    popoverGetContentBlock(obj){
        var split = obj.id.split("-");
        var section = split[0].split("_")[1];
        var sectionTranslation = split[0];

        if(obj.type=='areaprice'){
            section = obj.id.split("_")[1];
        }

        if(obj.id.indexOf("G_") !== -1) {
            var sectionLab = "Group"
        } else if (obj.id.indexOf("S_") !== -1) {
            var sectionLab = "Section"
        } else if (obj.id.indexOf("V_") !== -1) {
            var sectionLab = "Section"
        }

        if(this.config.translation) {
            if(this.config.translationOptionsJson[sectionTranslation]) {
                section = this.config.translationOptionsJson[sectionTranslation]
            } else {
                section = section;
            }
        }
        
        var content = "<ul class='list-unstyled ticket-selected'>"+
                        "<li>"+
                            "<span class='ticket-lab'>"+ sectionLab + " </span> "+
                            "<span class='ticket-val'>"+section+"</span>"+
                        "</li>"+
                    "</ul>";
        return content;
    }

    popoverGetContentBlockVIPImg(obj){
        var split = obj.id.split("-");
        var section = split[0].split("_")[1];
        var sectionTranslation = split[0];

        if(obj.type=='areaprice'){
            section = obj.id.split("_")[1];
        }

        if(obj.id.indexOf("G_") !== -1) {
            var sectionLab = "Group"
        } else if (obj.id.indexOf("S_") !== -1) {
            var sectionLab = "Section"
        } else if (obj.id.indexOf("V_") !== -1) {
            var sectionLab = "Section"
        }

        if(this.config.translation) {
            if(this.config.translationOptionsJson[sectionTranslation]) {
                section = this.config.translationOptionsJson[sectionTranslation]
            } else {
                section = section;
            }
        }

        var content = "<div class='vip'><div class='ticket-selected-title gold'>" +
                        "<i class='icon-star'></i>VIP"+
                    "</div>"+
                    "<div class='ticket-selected-view'>" +
                        "<div class='ticket-selected-view-img'></div>"+
                    "</div>" +
                    "<ul class='list-unstyled ticket-selected'>"+
                        "<li style='border-left: none;'>"+
                            "<span class='ticket-lab'>"+sectionLab+" </span> "+
                            "<span class='ticket-val'>"+section+"</span>"+
                        "</li>"+
                    "</ul></div>";
        return content;
    }

    popoverGetContentBlockVIP(obj){
        var split = obj.id.split("-");
        var section = split[0].split("_")[1];
        var sectionTranslation = split[0];

        if(obj.type=='areaprice'){
            section = obj.id.split("_")[1];
        }

        if(obj.id.indexOf("G_") !== -1) {
            var sectionLab = "Group"
        } else if (obj.id.indexOf("S_") !== -1) {
            var sectionLab = "Section"
        } else if (obj.id.indexOf("V_") !== -1) {
            var sectionLab = "Section"
        }

        if(this.config.translation) {
            if(this.config.translationOptionsJson[sectionTranslation]) {
                section = this.config.translationOptionsJson[sectionTranslation]
            } else {
                section = section;
            }
        }

        var content = "<div class='vip'><div class='ticket-selected-title gold'>" +
                        "<i class='icon-star'></i>VIP"+
                    "</div>"+
                    "<ul class='list-unstyled ticket-selected'>"+
                        "<li style='border-left: none;'>"+
                            "<span class='ticket-lab'>"+sectionLab+" </span> "+
                            "<span class='ticket-val'>"+section+"</span>"+
                        "</li>"+
                    "</ul></div>";
        return content;
    }

    //seats 

    popoverGetContentSeatVIPImg(obj){
        var split = obj.id.split("-");
        var section = split[0].split("_")[1];
        var row = split[1];
        var seat = split[2];
        var sectionTranslation = split[0];

        if(this.config.translation) {
            if(this.config.translationOptionsJson[sectionTranslation]) {
                section = this.config.translationOptionsJson[sectionTranslation]
            } else {
                section = section;
            }
        }

        var content = "<div class='vip'><div class='ticket-selected-title gold'>" +
                        "<i class='icon-star'></i>VIP"+
                    "</div><div class='ticket-selected-view'>" +
                        "<div class='ticket-selected-view-img'></div>"+
                    "</div>" +
                    "<ul class='list-unstyled ticket-selected'>"+
                        "<div class='section-div'>"+
                            "<li>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</div>"+
                        "<div class='row-seat-div'>"+
                            "<li>"+
                            "<div class='separator'></div>"+
                                "<span class='ticket-lab'>Row </span> "+
                                "<span class='ticket-val'>"+row+"</span>"+
                            "</li>"+
                            "<li>"+
                            "<div class='separator'></div>"+
                                "<span class='ticket-lab'>Seat </span> "+
                                "<span class='ticket-val'>"+seat+"</span>"+
                            "</li>"+
                        "</div>"+
                    "</ul>";
        return content;
    }

    popoverGetContentSeatVIP(obj){
        var split = obj.id.split("-");
        var section = split[0].split("_")[1];
        var row = split[1];
        var seat = split[2];
        var sectionTranslation = split[0];

        if(this.config.translation) {
            if(this.config.translationOptionsJson[sectionTranslation]) {
                section = this.config.translationOptionsJson[sectionTranslation]
            } else {
                section = section;
            }
        }

        var content = "<div class='vip'><div class='ticket-selected-title gold'>" +
                            "<i class='icon-star'></i>VIP"+
                        "<ul class='list-unstyled ticket-selected'>"+
                        "<div class='section-div'>"+
                            "<li>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</div>"+
                        "<div class='row-seat-div'>"+
                            "<li>"+
                            "<div class='separator'></div>"+
                                "<span class='ticket-lab'>Row </span> "+
                                "<span class='ticket-val'>"+row+"</span>"+
                            "</li>"+
                            "<li>"+
                            "<div class='separator'></div>"+
                                "<span class='ticket-lab'>Seat </span> "+
                                "<span class='ticket-val'>"+seat+"</span>"+
                            "</li>"+
                        "</div>"+
                    "</ul>";
        return content;
    }

    popoverGetContentSeatImg(obj){
        var split = obj.id.split("-");
        var section = split[0].split("_")[1];
        var row = split[1];
        var seat = split[2];
        var sectionTranslation = split[0];

        if(this.config.translation) {
            if(this.config.translationOptionsJson[sectionTranslation]) {
                section = this.config.translationOptionsJson[sectionTranslation]
            } else {
                section = section;
            }
        }

        var content = "<div class='ticket-selected-view'>" +
                        "<div class='ticket-selected-view-img'></div>"+
                    "</div>" +
                    "<ul class='list-unstyled ticket-selected'>"+
                        "<div class='section-div'>"+
                            "<li>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</div>"+
                        "<div class='row-seat-div'>"+
                            "<li>"+
                            "<div class='separator'></div>"+
                                "<span class='ticket-lab'>Row </span> "+
                                "<span class='ticket-val'>"+row+"</span>"+
                            "</li>"+
                            "<li>"+
                            "<div class='separator'></div>"+
                                "<span class='ticket-lab'>Seat </span> "+
                                "<span class='ticket-val'>"+seat+"</span>"+
                            "</li>"+
                        "</div>"+
                    "</ul>";
        return content;
    }

    popoverGetContentSeat(obj){
        var split = obj.id.split("-");
        var section = split[0].split("_")[1];
        var row = split[1];
        var seat = split[2];
        var sectionTranslation = split[0];

        if(this.config.translation) {
            if(this.config.translationOptionsJson[sectionTranslation]) {
                section = this.config.translationOptionsJson[sectionTranslation]
            } else {
                section = section;
            }
        }

        var content = 
                    "<ul class='list-unstyled ticket-selected'>"+
                        "<div class='section-div'>"+
                            "<li>"+
                                "<span class='ticket-lab'>Section </span> "+
                                "<span class='ticket-val'>"+section+"</span>"+
                            "</li>"+
                        "</div>"+
                        "<div class='row-seat-div'>"+
                            "<li>"+
                            "<div class='separator'></div>"+
                                "<span class='ticket-lab'>Row </span> "+
                                "<span class='ticket-val'>"+row+"</span>"+
                            "</li>"+
                            "<li>"+
                            "<div class='separator'></div>"+
                                "<span class='ticket-lab'>Seat </span> "+
                                "<span class='ticket-val'>"+seat+"</span>"+
                            "</li>"+
                        "</div>"+
                    "</ul>";
        return content;
    }

    popoverMove(popover,obj){
        if(popover){
            var w = popover.width();
            var h = popover.height();
            var p = popover[0];
            var bb = p.getBoundingClientRect();
            h = bb.height;
            var top;
            var left;

            if($(".ticket-selected-view").length === 0) {
                top = obj.anchor.dom[1] - 20;
                left = obj.anchor.dom[0] - (w/2);
            } else {
                if(obj.anchor.dom[1] < bb.height){
                    top = obj.anchor.dom[1] + 17;
                }else{
                    top = obj.anchor.dom[1]-(h);
                }
    
                if(obj.anchor.dom[0] < bb.width){
                    left = obj.anchor.dom[0] + 17;
                }else{
                    left = obj.anchor.dom[0]-(w);
                }

            }

            popover.css("top",top);
            popover.css("left",left);
        }
        
        return null;
    }
}